// This file contains the action types for all the redux actions

// AUTH
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const RAS_REGISTER = "RAS_REGISTER";
export const UNAUTH_SUCCESS = "UNAUTH_SUCCESS";
export const SET_AUTH_FLAGS = "SET_AUTH_FLAGS";
export const SET_ACTIVITY = "SET_ACTIVITY";
export const SET_IGNORE_ACTIVITY = "SET_IGNORE_ACTIVITY";
export const RESET_TIMERS = "RESET_TIMERS";
export const SIGN_OUT = "SIGN_OUT";
export const START_TIMER = "START_TIMER";

// ADMIN
export const ADMIN_SET_FORM_SUPPORT = "ADMIN_SET_FORM_SUPPORT";
export const ADMIN_SET_INITIAL_DATA = "ADMIN_SET_INTIAL_DATA";
export const ADMIN_SET_ITEMS = "ADMIN_SET_ITEMS";
export const ADMIN_RESET_STATE = "ADMIN_RESET_STATE";
export const ADMIN_REQUEST_ACTION_TYPES = {
  APPROVE: "APPROVE",
  DENY: "DENY",
  REMOVE: "REMOVE",
  VIEW: "VIEW",
  UPLOAD: "UPLOAD",
  SHIP: "SHIP",
  TERMINATE: "TERMINATE",
  RENEWAL_APPROVE: "RENEWAL_APPROVE",
  RENEWAL_DENY: "RENEWAL_DENY",
  UPLOAD_RENEWAL_FORM: "UPLOAD_RENEWAL_FORM",
  AOR_MODIFICATION_APPROVE: "AOR_MODIFICATION_APPROVE",
  AOR_MODIFICATION_DENY: "AOR_MODIFICATION_DENY",
  UPLOAD_AOR_MODIFICATION_FORM: "UPLOAD_AOR_MODIFICATION_FORM",
  ANNUAL_REPORT_APPROVE: "ANNUAL_REPORT_APPROVE",
  ANNUAL_REPORT_EDIT: "ANNUAL_REPORT_EDIT",
  ANNUAL_REPORT_DENY: "ANNUAL_REPORT_DENY",
};

// CART
export const CART_SET_INITIAL_DATA = "CART_SET_INITIAL_DATA";
export const CART_DELETE_STUDY = "CART_DELETE_STUDY";
export const CART_SET_PAGINATION = "CART_SET_PAGINATION";
export const CART_DELETE_ITEMS = "CART_DELETE_ITEMS";
export const CART_SET_COLLAPSE_OPEN = "CART_SET_COLLAPSE_OPEN";
export const CART_SET_SELECTED_REQUEST_TYPE = "CART_SET_SELECTED_REQUEST_TYPE";
export const CART_RESET_STATE = "CART_RESET_STATE";

// CURATOR
export const CURATOR_SET_SUBMISSIONS = "CURATOR_SET_SUBMISSIONS";
export const CURATOR_SET_SINGLE_SUBMISSION = "CURATOR_SET_SINGLE_SUBMISSION";
export const CURATOR_SET_SUBMISSION_STATUS = "CURATOR_SET_SUBMISSION_STATUS";
export const CURATOR_RESET_STATE = "CURATOR_RESET_STATE";
export const CURATOR_ACTIONS = {
  WEB_VERIFY: "WEB_VERIFY",
  WEB_DENY: "WEB_DENY",
  VERIFY_DATA: "VERIFY_DATA",
  DENY_DATA: "DENY_DATA",
  APPROVE_STUDY: "APPROVE_STUDY",
  DENY_STUDY: "DENY_STUDY",
  ALLOW_ITEM_ADDITIONS: "ALLOW_ITEM_ADDITIONS",
  REVOKE_ITEM_ADDITIONS: "REVOKE_ITEM_ADDITIONS",
  VERIFY_NEW_ITEMS: "VERIFY_NEW_ITEMS",
  APPROVE_NEW_ITEMS: "APPROVE_NEW_ITEMS",
  DENY_NEW_ITEMS: "DENY_NEW_ITEMS",
  ALLOW_METADATA_UPDATE: "ALLOW_METADATA_UPDATE",
  REVOKE_METADATA_UPDATE: "REVOKE_METADATA_UPDATE",
};

// WORKSPACE
export const WORKSPACE_SET_INITIAL_DATA = "WORKSPACE_SET_INITIAL_DATA";
export const WORKSPACE_SET_DATA = "WORKSPACE_SET_DATA";
export const WORKSPACE_SET_COUNTS = "WORKSPACE_SET_COUNTS";
export const SET_DUA_ITEMS = "SET_DUA_ITEMS";
export const GET_AFFILIATES = "GET_AFFILIATES";
export const GET_RESEARCH_TEAM = "GET_RESEARCH_TEAM";
export const DUA_SET_SELECTED_ITEMS = "DUA_SET_SELECTED_ITEMS";
export const DUA_SET_COLLAPSE_OPEN = "DUA_SET_COLLAPSE_OPEN";
export const UPDATE_COUNTS = "UPDATE_COUNTS";
export const WORKSPACE_CREATE_NEW_INSTITUTION =
  "WORKSPACE_CREATE_NEW_INSTITUTION";

// OUTCOMES
export const OUTCOME_SET_OUTCOME_TYPE = "OUTCOME_SET_OUTCOME_TYPE";
export const OUTCOME_TYPES = {
  PUBLICATION: "Publication",
  PRESENTATION: "Presentation",
  FINDING: "Significant Finding",
  OTHER: "Other",
  PATENT: "Patent",
};

// PROFILE
export const PROFILE_SET_FORM_SUPPORT = "PROFILE_SET_FORM_SUPPORT";
export const PROFILE_RESET = "PROFILE_RESET";

// FEEDBACK
export const FEEDBACK_SET_FORM_SUPPORT = "FEEDBACK_SET_FORM_SUPPORT";
export const FEEDBACK_RESET = "FEEDBACK_RESET";

// REQUEST
export const REQUEST_SET_STATUS = "REQUEST_SET_STATUS";
export const REQUEST_RESET_STATE = "REQUEST_RESET_STATE";
export const REQUEST_SET_STEP_DATA = "REQUEST_SET_STEP_DATA";
export const REQUEST_RESET_STEP_DATA = "REQUEST_RESET_STEP_DATA";
export const REQUEST_SET_NEXT_STEP = "REQUEST_SET_NEXT_STEP";
export const REQUEST_DISABLE_AUTH_REP_FIELDS =
  "REQUEST_DISABLE_AUTH_REP_FIELDS";
export const REQUEST_CREATED_NEW_INSTITUTION =
  "REQUEST_CREATED_NEW_INSTITUTION";
export const REQUEST_CREATED_NEW_DIVISION = "REQUEST_CREATED_NEW_DIVISION";
export const AOR_REQUEST_CREATED_NEW_DIVISION =
  "AOR_REQUEST_CREATED_NEW_DIVISION";
export const REQUEST_TYPES = {
  DATA: "DATA",
  BIOSPEC: "BIOSPEC",
};
export const BIOSPECIMEN_REPOSITORY_TYPES = {
  FISHER_GOVERNMENTAL: "FISHER_GOVERNMENTAL",
  FISHER_COMMERCIAL: "FISHER_COMMERCIAL",
};

// MODIFY AOR
export const MODIFY_AOR_RESET_STATE = "MODIFY_AOR_RESET_STATE";
export const MODIFY_AOR_SET_STATUS = "MODIFY_AOR_SET_STATUS";
export const MODIFY_AOR_SET_STEP_DATA = "MODIFY_AOR_SET_STATUS";
export const MODIFY_AOR_SET_NEXT_STEP = "MODIFY_AOR_SET_NEXT_STEP";
export const MODIFY_AOR_SET_FORM_SUPPORT = "MODIFY_AOR_SET_FORM_SUPPORT";
export const MODIFY_AOR_DISABLE_AUTH_REP_FIELDS =
  "MODIFY_AOR_DISABLE_AUTH_REP_FIELDS";

// RENEWAL
export const RENEWAL_RESET_STATE = "RENEWAL_RESET_STATE";
export const RENEWAL_SET_STATUS = "RENEWAL_SET_STATUS";
export const RENEWAL_SET_STEP_DATA = "RENEWAL_SET_STATUS";
export const RENEWAL_SET_NEXT_STEP = "RENEWAL_SET_NEXT_STEP";
export const RENEWAL_SET_FORM_SUPPORT = "RENEWAL_SET_FORM_SUPPORT";
export const RENEWAL_DISABLE_AUTH_REP_FIELDS =
  "RENEWAL_DISABLE_AUTH_REP_FIELDS";
export const RENEW_REQUEST_CREATED_NEW_DIVISION =
  "RENEW_REQUEST_CREATED_NEW_DIVISION";

// ANNUAL REPORT
export const ANNUAL_REPORT_RESET_STATE = "ANNUAL_REPORT_RESET_STATE";
export const ANNUAL_REPORT_SET_STATUS = "ANNUAL_REPORT_SET_STATUS";
export const ANNUAL_REPORT_SET_STEP_DATA = "ANNUAL_REPORT_SET_STATUS";
export const ANNUAL_REPORT_SET_NEXT_STEP = "ANNUAL_REPORT_SET_NEXT_STEP";
export const ANNUAL_REPORT_SET_FORM_SUPPORT = "ANNUAL_REPORT_SET_FORM_SUPPORT";

// SUBMISSION
export const SUBMISSION_SET_SUBMISSIONS = "SUBMISSION_SET_SUBMISSIONS";
export const SUBMISSION_SET_SUBMISSION_STATUS =
  "SUBMISSION_SET_SUBMISSION_STATUS";
export const SUBMISSION_SET_STEP_DATA = "SUBMISSION_SET_STEP_DATA";
export const SUBMISSION_RESET_STEP_DATA = "SUBMISSION_RESET_STEP_DATA";
export const SUBMISSION_SET_FORM_SUPPORT_DATA =
  "SUBMISSION_SET_FORM_SUPPORT_DATA";
export const SUBMISSION_SET_DIVISION_FORM_SUPPORT =
  "SUBMISSION_SET_DIVISION_FORM_SUPPORT";
export const SUBMISSION_SET_NEXT_STEP = "SUBMISSION_SET_NEXT_STEP";
export const SUBMISSION_CREATE_NEW_INSTITUTION =
  "SUBMISSION_CREATE_NEW_INSTITUTION";
export const SUBMISSION_CREATE_NEW_DIVISION = "SUBMISSION_CREATE_NEW_DIVISION";
export const SUBMISSION_RESET_STATE = "SUBMISSION_RESET_STATE";
export const SUBMISSION_SET_CURR_STEP_INDEX = "SUBMISSION_SET_CURR_STEP_INDEX";
export const SUBMISSION_RELATIONSHIP_TYPES = {
  INTERNAL: "INTERNAL",
  EXTERNAL: "EXTERNAL",
  RELEASE: "RELEASE",
};
export const SUBMISSION_TYPES = {
  INTEGRATED: "Integrated",
  FEDERATED: "Federated",
  COLLECTION: "Collection",
};

// REGISTRATION
export const REGISTRATION_SET_FORM_SUPPORT = "REGISTRATION_SET_FORM_SUPPORT";
export const REGISTRATION_RESET_STATE = "REGISTRATION_RESET_STATE";
export const REGISTRATION_SET_INACTIVE_USER_FORM =
  "REGISTRATION_SET_INACTIVE_USER_FORM";

// MODAL
export const MODAL_OPEN = "MODAL_OPEN";
export const MODAL_CLOSE = "MODAL_CLOSE";
export const MODAL_TYPES = {
  BASIC_MODAL: "BASIC_MODAL",
  MODIFICATION_WARNING_MODAL: "MODIFICATION_WARNING_MODAL",
  CONSTRAINTS_MODAL: "CONSTRAINTS_MODAL",
  CREATE_LOCATION_MODAL: "CREATE_LOCATION_MODAL",
  DOWNLOAD_DUA_MODAL: "DOWNLOAD_DUA_MODAL",
  MODIFY_RESEARCH_TEAM_MODAL: "MODIFY_RESEARCH_TEAM_MODAL:",
  REQUEST_GUIDELINES_MODAL: "REQUEST_GUIDELINES_MODAL",
  REUSE_REQUEST_MODAL: "REUSE_REQUEST_MODAL",
  ADMIN_VIEW_USER_MODAL: "ADMIN_VIEW_USER_MODAL",
  ADMIN_VIEW_DIVISION_MODAL: "ADMIN_VIEW_DIVISION_MODAL",
  ADMIN_VIEW_INSTITUTION_MODAL: "ADMIN_VIEW_INSTITUTION_MODAL",
  ADMIN_REQUEST_MODAL: "ADMIN_REQUEST_MODAL",
  ADMIN_ANNUAL_REPORT_MODAL: "ADMIN_ANNUAL_REPORT_MODAL",
  DATA_CHALLENGE_SELECTION_MODAL: "DATA_CHALLENGE_SELECTION_MODAL",
  CHALLENGE_STUDY_PROMPT_MODAL: "CHALLENGE_STUDY_PROMPT_MODAL",
  DELETE_OUTCOME_MODAL: "DELETE_OUTCOME_MODAL",
};
export const LOCATION_TYPES = {
  INSTITUTION: "INSTITUTION",
  DIVISION: "DIVISION",
};

export const REQUEST_VALIDATION_TYPES = {
  VALID: "VALID",
  INVALIDATED: "INVALIDATED",
  UPDATE_ACTIVE_REQUEST: "UPDATE_ACTIVE_REQUEST",
};

// HOME
export const HOME_RECENT_STUDIES = "HOME_RECENT_STUDIES";
export const HOME_SET_ANNOUNCEMENTS = "HOME_SET_ANNOUNCEMENTS";

// RESOURCE
export const RESOURCE_PUBLICATION_OUTCOMES = "RESOURCE_PUBLICATION_OUTCOMES";

// FOOTER
export const GET_VERSION_NUMBER = "GET_VERSION_NUMBER";

// STUDY OVERVIEW
export const GET_STUDY_OVERVIEW = "GET_STUDY_OVERVIEW";
export const CLEAR_STUDY_OVERVIEW = "CLEAR_STUDY_OVERVIEW";
export const ADD_TO_CART_STUDY_OVERVIEW = "ADD_TO_CART_STUDY_OVERVIEW";
export const UPDATE_ITEMS_IN_CART = "UPDATE_ITEMS_IN_CART";
export const STUDY_OVERVIEW_SET_SEARCH_RESULTS =
  "STUDY_OVERVIEW_SET_SEARCH_RESULTS";

// DATASET OVERVIEW
export const GET_DATASET_OVERVIEW = "GET_DATASET_OVERVIEW";
export const RESET_DATASET_OVERVIEW = "RESET_DATASET_OVERVIEW";
export const SET_VARIABLE_PAGINATION = "SET_VARIABLE_PAGINATION";
export const DATASET_OVERVIEW_RESET_STATE = "DATASET_OVERVIEW_RESET_STATE";
export const ADD_TO_CART_DATASET_OVERVIEW = "ADD_TO_CART_DATASET_OVERVIEW";
export const SET_TOTAL_VARIABLE_COUNTS = "SET_TOTAL_VARIABLE_COUNTS";

// Header
export const GET_ITEMS_IN_CART = "GET_ITEMS_IN_CART";

// SEARCH
export const SEARCH_SET_RESULTS = "SEARCH_SET_RESULTS";
export const SEARCH_SET_PROPERTIES = "SEARCH_SET_PROPERTIES";
export const SEARCH_SET_FACETS = "SEARCH_SET_FACETS";
export const SEARCH_SET_CAN_ADD_DATA = "SEARCH_SET_CAN_ADD_DATA";
export const SEARCH_SET_SELECTED_ITEMS = "SEARCH_SET_SELECTED_ITEMS";
export const SEARCH_ADD_ITEMS_TO_CART = "SEARCH_ADD_ITEMS_TO_CART";
export const SEARCH_RESET_RESULT = "SEARCH_RESET_RESULT";
export const SEARCH_RESET_STATE = "SEARCH_RESET_STATE";
export const SEARCH_TYPES = {
  STUDY_EXPLORER: "study",
  BIO_SPEC: "biospecimen",
  DATASET_EXPLORER: "dataset",
  DOCUMENT_EXPLORER: "document",
  EXTERNALRESOURCE_EXPLORER: "externalResource",
};
export const SEARCH_CAN_ADD = {
  WILL_INVALIDATE_PACKAGE: "WILL_INVALIDATE_PACKAGE",
  UPDATE_ACTIVE_REQUEST: "UPDATE_ACTIVE_REQUEST",
  VALID: "VALID",
};

// REPORTS
export const REPORT_SET_DATA = "REPORT_SET_DATA";
export const REPORT_SET_HISTOGRAM_DATA = "REPORT_SET_HISTOGRAM_DATA";
export const REPORT_SET_SUPPORT = "REPORT_SET_SUPPORT";
export const REPORT_SET_FORM_SUPPORT = "REPORT_SET_FORM_SUPPORT";
export const REPORT_RESET_DATA = "REPORT_RESET_DATA";
export const REPORT_RESET_STATE = "REPORT_RESET_STATE";
export const REPORT_ACTIVITY_SET_COUNTS = " REPORT_ACTIVITY_SET_COUNTS";
export const REPORT_ACTIVITY_RESET = "REPORT_ACTIVITY_RESET";
export const REPORT_ACTIVITY_SET_OVERVIEW = "REPORT_ACTIVITY_SET_OVERVIEW";

export const REPORT_ROLES = {
  ADMIN: "admin",
  OFFICER: "officer",
};
export const REPORT_TYPES = {
  REQUEST: "request",
  USER: "user",
  PORTFOLIO: "portfolio",
  OUTCOME: "outcome",
  ACTIVITY: "activity",
};
export const REPORT_SUB_TYPES = {
  EXPIRATION: "expiration",
  ANNUAL: "annual",
  STATISTICS: "aggregates",
  DETAILS: "details",
  OVERVIEW: "overview",
  STUDY: "study",
  DATA_REQUEST: "data-request",
  BIOSPECIMEN_REQUEST: "biospecimen-request",
  OUTCOME: "outcome",
  USER_DETAILS: "user-details",
};
export const HISTOGRAM_INTERVAL_TYPES = {
  YEAR: "year",
  MONTH: "month",
};

// LOADER
export const LOADER_SET_LOADING = "LOADER_SET_LOADING";

// ERROR
export const ERROR_TYPES = {
  404: "404",
  500: "500",
  MAINTENANCE: "MAINTENANCE",
};

// ALERT
export const ALERT_TYPES = {
  ERROR: "ERROR",
  WARNING: "WARNING",
  SUCCESS: "SUCCESS",
  INFO: "INFO",
};

export const FIELD_TYPES = {
  FIELD: "FIELD",
  FORM_SECTION: "FORM_SECTION",
  FIELD_ARRAY: "FIELD_ARRAY",
};

export const SORT_TYPES = {
  ASCENDING: "ASCENDING",
  DESCENDING: "DESCENDING",
};
