import { startsWith } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { KEY_CODE } from "../../utils";
import "./dropdown.scss";

// Renders a dropdown that opens a menu of items. This component follows the WAI specifications for
// actions menu button as described here https://www.w3.org/TR/wai-aria-practices/examples/menu-button/menu-button-actions.html
const Dropdown = (props) => {
  const {
    id,
    ariaLabel,
    items,
    className,
    dropdownName,
    defaultSelectedItemIndex,
    notifyDropdownOpen,
    itemStyle,
  } = props;

  const node = useRef();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // this ref keeps track of which menu item is focused
  const focusIndex = useRef(defaultSelectedItemIndex);

  // this ref keeps track of which menu item is selected
  const selectedIndex = useRef(defaultSelectedItemIndex);

  // Value we'll be displaying on dropdown frontend
  const [displayValue, setDisplayValue] = useState(
    dropdownName || items[selectedIndex.current]?.name,
  );

  // Dropdown value we are tracking for changes, aria label, etc
  const [dropdownValue, setDropdownValue] = useState(
    items[selectedIndex.current]?.name,
  );

  // find the index of the first object in items array with a name that begins with character char
  const getIndexOfFirstChar = (char, startIndex, endIndex) => {
    for (let index = startIndex; index < endIndex; index += 1) {
      if (startsWith(items[index].name.toLowerCase(), char)) {
        return index;
      }
    }
    return -1;
  };

  // move focus to the next menu item that starts with character char
  const setFocusToFirstCharacter = (char, nodeIndex) => {
    let index = -1;

    // check menu items from nodeIndex to end
    const startIndex = nodeIndex === items.length - 1 ? 0 : nodeIndex + 1;
    index = getIndexOfFirstChar(char, startIndex, items.length);

    // check menu items from beginning to nodeIndex
    if (index === -1) {
      index = getIndexOfFirstChar(char, 0, nodeIndex);
    }

    if (index > -1) {
      node.current.childNodes[1].childNodes[index].focus();
    }
    return index;
  };

  // handle keydown actions when a default item index is not provided
  const handleKeydown = (e) => {
    switch (e.keyCode) {
      case KEY_CODE.TAB:
        if (dropdownOpen) {
          // move focus to the first menu item
          e.preventDefault();
          node.current.lastChild.childNodes[0].focus();
        }
        break;
      case KEY_CODE.UP:
        // open the dropdown and move the focus to the last menu item
        e.preventDefault();
        setDropdownOpen(true);
        focusIndex.current = items.length - 1;
        break;
      case KEY_CODE.ENTER:
      case KEY_CODE.SPACE:
      case KEY_CODE.DOWN:
        // open the dropdown and move the focus to the first menu item
        e.preventDefault();
        setDropdownOpen(true);
        focusIndex.current = 0;
        break;
      default:
    }
  };

  // handle keydown actions when a default item index is provided
  const handleKeydownGivenDefaultIndex = (e) => {
    switch (e.keyCode) {
      case KEY_CODE.UP:
        // open the dropdown and move focus to the previous menu item
        e.preventDefault();
        setDropdownOpen(true);
        focusIndex.current =
          focusIndex.current === 0 ? items.length - 1 : focusIndex.current - 1;
        break;
      case KEY_CODE.ENTER:
      case KEY_CODE.SPACE:
        e.preventDefault();
        setDropdownOpen(true);
        break;
      case KEY_CODE.DOWN:
        // open the dropdown and move focus to the next menu item
        e.preventDefault();
        setDropdownOpen(true);
        focusIndex.current =
          focusIndex.current === items.length - 1 ? 0 : focusIndex.current + 1;
        break;
      default:
    }
  };

  const handleItemOnClick = (e, onClick) => {
    onClick(e.target.getAttribute("value"));
    if (defaultSelectedItemIndex > -1) {
      const nodeIndex = parseInt(e.target.getAttribute("index"), 10);
      focusIndex.current = nodeIndex;
      selectedIndex.current = nodeIndex;

      const selectedDropDownValue = items[selectedIndex.current].name;
      setDisplayValue(dropdownName || selectedDropDownValue);
      setDropdownValue(selectedDropDownValue);
    }
    setDropdownOpen(false);
  };

  const handleItemKeydown = (e, onClick) => {
    switch (e.keyCode) {
      case KEY_CODE.TAB:
        setDropdownOpen(false);
        break;
      case KEY_CODE.ENTER:
        handleItemOnClick(e, onClick);
        break;
      case KEY_CODE.ESCAPE:
        // close the dropdown menu and move the focus to the dropdown button
        setDropdownOpen(false);
        node.current.firstChild.focus();
        break;
      case KEY_CODE.HOME:
        // move the focus to the first menu item
        e.preventDefault();
        node.current.childNodes[1].firstChild.focus();
        break;
      case KEY_CODE.END:
        // move the focus to the last menu item
        e.preventDefault();
        node.current.childNodes[1].lastChild.focus();
        break;
      case KEY_CODE.UP: {
        // move the focus to the previous menu item; if focus is on the first menu item, then move focus to the last menu item
        e.preventDefault();
        const nodeIndex = parseInt(e.target.getAttribute("index"), 10);
        let nextIndex = nodeIndex === 0 ? items.length - 1 : nodeIndex - 1;
        nextIndex =
          node.current.childNodes[1].childNodes[nextIndex].getAttribute(
            "tabIndex",
          ) !== "0"
            ? nextIndex - 1
            : nextIndex;
        node.current.childNodes[1].childNodes[nextIndex].focus();
        break;
      }
      case KEY_CODE.DOWN: {
        // move the focus to the next menu item; if focus is on the last menu item, then move the focus to the first menu item
        e.preventDefault();
        const nodeIndex = parseInt(e.target.getAttribute("index"), 10);
        let nextIndex = nodeIndex === items.length - 1 ? 0 : nodeIndex + 1;
        nextIndex =
          node.current.childNodes[1].childNodes[nextIndex].getAttribute(
            "tabIndex",
          ) !== "0"
            ? nextIndex + 1
            : nextIndex;
        node.current.childNodes[1].childNodes[nextIndex].focus();
        break;
      }
      default:
        // if the user presses a-z key, move focus the next menu item that begins with the typed character
        if (e.key.match(/^[a-zA-Z]$/)) {
          const nodeIndex = parseInt(e.target.getAttribute("index"), 10);
          setFocusToFirstCharacter(e.key.toLowerCase(), nodeIndex);
        }
    }
  };

  // close the dropdown if the user clicks outside the dropdown
  const handleClickOutside = (e) => {
    if (!node.current.contains(e.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (notifyDropdownOpen) {
      notifyDropdownOpen(dropdownOpen);
    }
    if (dropdownOpen) {
      if (focusIndex.current > -1) {
        node.current.lastChild.childNodes[focusIndex.current].focus();
      }
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen, notifyDropdownOpen]);

  useEffect(() => {
    focusIndex.current = defaultSelectedItemIndex;
    selectedIndex.current = defaultSelectedItemIndex;
    setDisplayValue(dropdownName || items[selectedIndex.current].name);
  }, [defaultSelectedItemIndex]);

  let show = "";
  if (dropdownOpen) {
    show = "show";
  }

  const renderItems = (listItems) =>
    listItems.map(({ name, onClick, value, type }, index) => {
      if (type === "parent") {
        return (
          <li key={name} role='menuitem' index={index} className='parent-item'>
            {name}
          </li>
        );
      }
      if (type === "child") {
        return (
          <li
            key={name}
            role='menuitem'
            tabIndex='0'
            onClick={(e) => handleItemOnClick(e, onClick)}
            onKeyDown={(e) => handleItemKeydown(e, onClick)}
            value={value}
            index={index}
            className='child-item'
            style={itemStyle}
          >
            {name}
          </li>
        );
      }
      return (
        <li
          key={name}
          role='menuitem'
          tabIndex='0'
          onClick={(e) => handleItemOnClick(e, onClick)}
          onKeyDown={(e) => handleItemKeydown(e, onClick)}
          value={value}
          index={index}
          style={itemStyle}
        >
          {name}
        </li>
      );
    });

  // use useRef to set a unique id so that the id does not get reset when the component re-renders
  const buttonId = `dash-dropdown-button-${id}`;
  const dropdownMenuId = `dash-dropdown-menu-${id}`;
  return (
    <div
      id={id}
      ref={node}
      className={`dropdown dash-dropdown ${className} ${show}`}
    >
      <button
        id={buttonId}
        type='button'
        onClick={() => setDropdownOpen(!dropdownOpen)}
        onKeyDown={
          defaultSelectedItemIndex > -1
            ? handleKeydownGivenDefaultIndex
            : handleKeydown
        }
        value={dropdownValue}
        aria-haspopup='true'
        aria-controls={dropdownMenuId}
        aria-expanded={dropdownOpen}
        aria-label={`${ariaLabel}, ${dropdownValue} is currently selected`}
        aria-live='polite'
        aria-atomic='true'
      >
        {displayValue}
      </button>
      <ul
        id={dropdownMenuId}
        className={`dropdown-menu ${show}`}
        role='menu'
        aria-hidden={!dropdownOpen}
        aria-labelledby={buttonId}
      >
        {renderItems(items)}
      </ul>
    </div>
  );
};

Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired, // the menu item name, each menu item must have an unique name
      onClick: PropTypes.func, // the action to perform when the user clicks a menu item
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  className: PropTypes.string,
  dropdownName: PropTypes.node, // optinal name for the dropdown, if dropdownName is not provided then the selected menu item name will be displayed
  defaultSelectedItemIndex: PropTypes.number, // the default menu item that should be selected if buttonName is not provided
  notifyDropdownOpen: PropTypes.func,
  itemStyle: PropTypes.object,
};

Dropdown.defaultProps = {
  className: "",
  defaultSelectedItemIndex: -1,
};

export default Dropdown;
