import { GET_ITEMS_IN_CART, UPDATE_ITEMS_IN_CART } from "../types";

export const getItemsInCart = () => {
  const url = "/workbench-api/cart/count";
  return async (dispatch) => {
    try {
      const init = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        },
      };
      const response = await fetch(url, init);
      const data = await response.json();

      if (response.ok) {
        dispatch({
          type: GET_ITEMS_IN_CART,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_ITEMS_IN_CART,
          payload: { numCartItems: 0 },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateItemsInCart = (number) => (dispatch) => {
  dispatch({
    type: UPDATE_ITEMS_IN_CART,
    payload: number,
  });
};
