import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "./style.scss";

const Button = (props) => {
  const others = _.omit(props, [
    "ariaLabel",
    "label",
    "children",
    "className",
    "icon",
    "role",
    "newTab",
    "tooltipId",
  ]);
  if (props.href === null) {
    return (
      <button
        type='button'
        data-testid={props.testId}
        className={props.className}
        aria-label={props.ariaLabel}
        tabIndex={props.tabIndex}
        data-tip={props.tooltipId}
        data-for={props.tooltipId}
        {...others}
      >
        <span>
          {props.icon}
          {props.label}
        </span>
      </button>
    );
  } else if (props.newTab) {
    return (
      <a
        target='_blank'
        className={props.className}
        role={props.role}
        tabIndex={props.tabIndex}
        data-tip={props.tooltipId}
        data-for={props.tooltipId}
        {...others}
      >
        <span>
          {props.icon}
          {props.label}
        </span>
      </a>
    );
  }
  return (
    <a
      className={props.className}
      role={props.role}
      tabIndex={props.tabIndex}
      data-tip={props.tooltipId}
      data-for={props.tooltipId}
      aria-label={props.ariaLabel}
      {...others}
    >
      <span>
        {props.icon}
        {props.label}
      </span>
    </a>
  );
};

Button.propTypes = {
  label: PropTypes.node,
  testId: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  role: PropTypes.string,
  icon: PropTypes.node,
  href: PropTypes.string,
  newTab: PropTypes.bool,
  tabIndex: PropTypes.number,
  expanded: PropTypes.bool,
  tooltipId: PropTypes.string,
};

Button.defaultProps = {
  label: "",
  className: "btn",
  role: "button",
  icon: null,
  href: null,
};

export default Button;
