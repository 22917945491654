import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as NICHDLogo } from "../../../img/icons/gov/NICHDLogo.svg";
import { ReactComponent as DASHLogo } from "../../../img/icons/gov/DASHLogo.svg";
import AccountNavbar from "./AccountNavbar";

// renders the white navigation with nichd and dash logos
const UpperNavigation = (props) => {
  const { isLoggedIn, isMaintenance } = props;
  const expand = !isLoggedIn ? "navbar-expand" : "navbar-expand-sm";
  return (
    <div
      className='navbar-upper-bkgd'
      role='navigation'
      aria-label='Upper Navigation'
    >
      <div className='container-fluid padding-sides'>
        <nav
          aria-label='dash-upper-navbar'
          className={`navbar ${expand} upper-nav`}
        >
          <a href='https://www.nichd.nih.gov/' className='nav-icon-link'>
            <NICHDLogo viewBox='7 7 360 54' width='300px' />
          </a>
          <Link to='/' className='nav-icon-link'>
            <DASHLogo width='177px' />
          </Link>
          {isMaintenance ? null : !isLoggedIn ? (
            <Link to='/signin' className='upper-login-btn'>
              Sign Up/ In
            </Link>
          ) : (
            <AccountNavbar className='upper-account-navbar' />
          )}
        </nav>
      </div>
    </div>
  );
};

UpperNavigation.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default UpperNavigation;
