import React from "react";
import { startCase } from "lodash";
import {
  fieldIsUndefined,
  textValidationArray,
  validateForm,
  renderValidationErrorMessage,
} from "../../../utils";
import { addressErrors } from "../address/AddressValidation";
import { FIELD_TYPES } from "../../../types";

const institutionNameMaxLength = 256;

const institutionFields = {
  institutionType: [fieldIsUndefined("Institution Type")],
  name: textValidationArray("Institution name", institutionNameMaxLength),
  isForProfit: [
    fieldIsUndefined(null, "Specify whether the institution is for profit"),
  ],
  address: {
    fieldType: FIELD_TYPES.FORM_SECTION,
    shouldValidate: () => true,
    fields: addressErrors,
  },
};

export const validateInstitution = (values) =>
  validateForm(values, institutionFields);

export const parseInstitutionBackendErrors = (backendErrors) => {
  const errors = {
    _error: backendErrors.message,
  };
  if (backendErrors.validationErrors) {
    errors._error = "Please correct the errors with the below field(s)";
    backendErrors.validationErrors.forEach(({ field, error }) => {
      if (field.match(/address.(.+)/)) {
        const formField = field.match(/address.(.+)/)[1];
        const fieldError = { [formField]: `${error}` };
        errors.address = errors.address
          ? { ...errors.address, ...fieldError }
          : fieldError;
      } else if (field !== "address") {
        errors[field] = error;
      }
    });
  }
  return errors;
};

export const renderInstitutionBackendErrors = (backendErrors) => {
  const message = "Please correct the errors in the below field(s):";
  if (backendErrors.validationErrors) {
    const options = backendErrors.validationErrors.map((field, index) => ({
      name: startCase(field.field),
      index,
    }));
    return renderValidationErrorMessage(message, options);
  }
  if (backendErrors.message) {
    return renderValidationErrorMessage(backendErrors.message);
  }
  return <p> There was an unexpected error. Please try again. </p>;
};
