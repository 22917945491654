import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getDisplayDate } from "../../../../common/utils";

const AdminRequestView = (props) => {
  const { request, isRenewal, isAORModification } = props;
  const {
    name,
    id,
    type,
    items,
    study,
    requestor,
    principalInvestigator,
    authorizedRepresentative,
    hasExecutedAgreement,
    useAgreement,
    modificationId,
  } = request;

  let executedAgreementText = null;
  if (!useAgreement) {
    if (hasExecutedAgreement === true) {
      executedAgreementText = (
        <div>
          <b>
            Has Executed Agreement: <span style={{ color: "green" }}> Yes</span>
          </b>
        </div>
      );
    } else if (hasExecutedAgreement === false) {
      executedAgreementText = (
        <div>
          <b>
            Has Executed Agreement: <span style={{ color: "red" }}> No</span>
          </b>
        </div>
      );
    }
  }

  const renderPerson = (person) => (
    <div>
      <div>
        <b>Name: </b>
        {person.title} {person.firstName} {person.middleInitial}{" "}
        {person.lastName}{" "}
      </div>
      <div>
        <b>Email: </b>
        {person.emailAddress}
      </div>
      <div>
        <b>Position: </b>
        {person.occupation}
      </div>
    </div>
  );

  return (
    <div className='request-details'>
      <div className='details-block'>
        <b>Request:</b>
        <div>
          <b>Name: </b>
          {name}
        </div>
        <div>
          <b>ID: </b>
          {id || modificationId}
        </div>
        <div>
          <b>Request Type:</b> {type}{" "}
        </div>
        <div>
          <b>Requesting Institution:</b>{" "}
          {requestor.institutionDivision.institution.name}{" "}
        </div>
        <div>
          <b>Status: </b>
          {request.status}
        </div>
        {executedAgreementText}
      </div>
      {study && (
        <div className='details-block'>
          <b>Study:</b>
          <div>
            <b>Abbreviation: </b>
            {study.studyAbbreviation}
          </div>
          <div>
            <b>Name: </b>{" "}
            <Link to={`/study/${study.id}`}>{study.studyName}</Link>
          </div>
        </div>
      )}
      <div className='details-block'>
        <b>Requestor:</b> {renderPerson(requestor)}{" "}
      </div>
      {principalInvestigator && (
        <div className='details-block'>
          <b>Principal Investigator:</b> {renderPerson(principalInvestigator)}{" "}
        </div>
      )}
      {authorizedRepresentative && (
        <div className='details-block'>
          <b>Authorized Representative:</b>{" "}
          {renderPerson(authorizedRepresentative)}{" "}
        </div>
      )}
      {type === "Biospecimen" && (
        <div className='details-block'>
          <b>Biospecimen Items: </b>
          {Object.keys(items).map((item) => (
            <div>
              <b>{item}: </b>
              {items[item]}
            </div>
          ))}
        </div>
      )}
      <br />
      {useAgreement && <hr />}
      {useAgreement && (
        <>
          <h2>Approval:</h2>
          <div>
            <b>Approved By:</b> {useAgreement.createdBy}{" "}
          </div>
          <div>
            <b>Effective Date:</b> {getDisplayDate(useAgreement.effectiveDate)}{" "}
          </div>
          <div>
            <b>Expiration Date:</b>{" "}
            {getDisplayDate(useAgreement.expirationDate)}{" "}
          </div>
          {request.status === "Terminated" && (
            <div>
              <b>Termination Reason:</b> {useAgreement.status}
            </div>
          )}
        </>
      )}
      {isRenewal && (
        <>
          <hr />
          <h3>Request Renewal Information</h3>
          <div>
            <b>Renewal has new AOR: </b>{" "}
            {request.hasNewAOR === "true" ? "Yes" : "No"}{" "}
          </div>
          {request.hasNewAOR === "true" && (
            <div className='details-block'>
              <b>Authorized Representative:</b> {renderPerson(request.newAOR)}{" "}
            </div>
          )}
          <div>
            <b>Reason for Renewal: </b> {request.reasonForRenewal}{" "}
          </div>
        </>
      )}
      {isAORModification && (
        <>
          <hr />
          <h3>AOR Modification Information</h3>
          {request.hasNewAOR === "true" && (
            <div className='details-block'>
              <b>Authorized Representative:</b> {renderPerson(request.newAOR)}{" "}
            </div>
          )}
          <div>
            <b>Reason for AOR Modification: </b> {request.reasonForAORChange}{" "}
          </div>
        </>
      )}
    </div>
  );
};

AdminRequestView.propTypes = {
  request: PropTypes.object,
  isRenewal: PropTypes.bool,
  isAORModification: PropTypes.bool,
};

export default AdminRequestView;
