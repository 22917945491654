import React, { Component } from "react";
import PropTypes from "prop-types";
import USAGovLogo from "../../img/icons/gov/USAGovLogo.png";
import { ReactComponent as DHHSLogo } from "../../img/icons/gov/DHHSLogo.svg";
import { ReactComponent as NIHLogo } from "../../img/icons/gov/NIHLogoColor.svg";
import "./footer.scss";

class FooterContainer extends Component {
  componentDidMount() {
    this.props.getVersion();
  }

  render() {
    return (
      <footer>
        <div className='footer-top'>
          <div className='container-fluid padding-sides'>
            <div className='footer-top-container'>
              <a
                href='https://www.usa.gov/'
                className='footer-logo'
                aria-label='Go to USA.gov'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  height='50px'
                  width='50px'
                  src={USAGovLogo}
                  alt='USA.gov logo'
                />
              </a>
              <a
                href='https://www.hhs.gov/'
                className='footer-logo'
                aria-label='Go to HHS.gov'
                target='_blank'
                rel='noopener noreferrer'
              >
                <DHHSLogo height='50px' width='50px' />
              </a>
              <a
                href='https://www.nih.gov/'
                className='footer-logo'
                aria-label='Go to NIH.gov'
                target='_blank'
                rel='noopener noreferrer'
              >
                <NIHLogo height='50px' width='277px' />
              </a>
              {this.props.isMaintenance ? null : (
                <div className='footer-links'>
                  <span className='footer-version'>{this.props.version}</span>
                  <span> | </span>
                  <a
                    href='https://www.nichd.nih.gov/Pages/index.aspx'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    NICHD HOME
                  </a>
                  <span> | </span>
                  <a
                    href='https://www.nichd.nih.gov/disclaimer#disclaimers'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Disclaimer
                  </a>
                  <span> | </span>
                  <a
                    href='https://www.nichd.nih.gov/about/Pages/foia.aspx'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    FOIA
                  </a>
                  <span> | </span>
                  <a
                    href='https://www.nichd.nih.gov/privacypolicy'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Privacy Policy
                  </a>
                  <span> | </span>
                  <a
                    href='https://www.nichd.nih.gov/disclaimer#accessibility'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Accessibility
                  </a>
                  <span> | </span>
                  <a
                    href='https://www.hhs.gov/vulnerability-disclosure-policy/index.html'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    HHS Vulnerability Disclosure
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='container-fluid padding-sides'>
            NIH...Turning Discovery Into Health ®
          </div>
        </div>
      </footer>
    );
  }
}

FooterContainer.propTypes = {
  getVersion: PropTypes.func.isRequired,
  version: PropTypes.string,
};

FooterContainer.defaultProps = {
  version: "",
};

export default FooterContainer;
