import { submit, getFormValues, change, touch } from "redux-form";
import {
  SUBMISSION_SET_SUBMISSIONS,
  SUBMISSION_SET_SUBMISSION_STATUS,
  SUBMISSION_SET_STEP_DATA,
  SUBMISSION_SET_FORM_SUPPORT_DATA,
  SUBMISSION_RESET_STEP_DATA,
  SUBMISSION_SET_NEXT_STEP,
  LOCATION_TYPES,
  SUBMISSION_CREATE_NEW_INSTITUTION,
  SUBMISSION_CREATE_NEW_DIVISION,
  SUBMISSION_RESET_STATE,
  SUBMISSION_SET_CURR_STEP_INDEX,
  SUBMISSION_SET_DIVISION_FORM_SUPPORT,
} from "../../common/types";
import { formatSubmissionData } from "./utils/SubmissionFormatter";
import { getStepToIndexMap, getSubmissionSteps } from "./utils";

export const resetStepData = (step) => ({
  type: SUBMISSION_RESET_STEP_DATA,
  payload: step,
});

export const setUserFields = (values, fieldName) => (dispatch) => {
  const userFields = [
    "id",
    "title",
    "firstName",
    "lastName",
    "middleInitial",
    "occupation",
    "phone",
    "locked",
    "institutionDivisionId",
    "institutionId",
  ];
  userFields.forEach((field) => {
    dispatch(
      change(
        "investigator-information",
        `${fieldName}.user.${field}`,
        values[field],
      ),
    );
  });
};

// retrieves users submissions
export const getSubmissions = () => {
  const url = "/submission-api/submissions";
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async (dispatch) => {
    try {
      const response = await fetch(url, init);
      const json = await response.json();
      if (response.ok) {
        dispatch({
          type: SUBMISSION_SET_SUBMISSIONS,
          payload: {
            userSubmissions: json,
          },
        });
      } else {
        throw json;
      }
    } catch (error) {
      throw error;
    }
  };
};

// get parent studies from study-release-form-support endpoint //
/*
export const getNewReleaseFormSupport = (step) => {
  const url = `/submission-api/submissions/study-release-form-support`;
  console.log(url);
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('JWT')}`,
    },
  };
  return async (dispatch) => {
    try {
      const response = await fetch(url, init);
      if (response.ok) {
        const json = await response.json();
        dispatch({
          type: SUBMISSION_SET_FORM_SUPPORT_DATA,
          payload: {
            step,
            formSupportKey: 'newReleaseFormSupport',
            formSupportData: json,
          },
        });
      }
    } catch (error) {
      throw error;
    }
  };
}
*/

export const getRelatedStudiesFormSupport = (networkName, step) => {
  const url = `/submission-api/submissions/related-studies-form-support?network=${encodeURIComponent(
    networkName,
  )}`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async (dispatch) => {
    try {
      const response = await fetch(url, init);
      if (response.ok) {
        const json = await response.json();
        dispatch({
          type: SUBMISSION_SET_FORM_SUPPORT_DATA,
          payload: {
            step,
            formSupportKey: "relatedStudiesFormSupport",
            formSupportData: json,
          },
        });
      }
    } catch (error) {
      throw error;
    }
  };
};

export const getReverseLogicFormSupport = (logic, step) => {
  const url = `/submission-api/submissions/reverse-link-form-support/?linkType=${encodeURIComponent(
    logic,
  )}`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async (dispatch) => {
    try {
      const response = await fetch(url, init);
      if (response.ok) {
        const json = await response.json();
        dispatch({
          type: SUBMISSION_SET_FORM_SUPPORT_DATA,
          payload: {
            step,
            formSupportKey: "reverseLogicFormSupport",
            formSupportData: { [logic]: json.reverseLinks },
          },
        });
      }
    } catch (error) {
      throw error;
    }
  };
};

export const getFormSupport = (step) => {
  let url = `/submission-api/submissions/${step}-form-support`;
  if (step === "study-population" || step === "biospecimen-info") {
    url = `/submission-api/${step}-form-support`;
  }
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async (dispatch) => {
    try {
      const response = await fetch(url, init);
      const json = await response.json();
      if (response.ok) {
        dispatch({
          type: SUBMISSION_SET_FORM_SUPPORT_DATA,
          payload: {
            step,
            formSupportKey: "formSupport",
            formSupportData: json,
          },
        });
      } else {
        throw json;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const getLoginFormSupport = (step) => {
  const url = "/login/form-support";
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async (dispatch) => {
    try {
      const response = await fetch(url, init);
      const json = await response.json();
      if (response.ok) {
        const { institutions, divisions, titles } = json;
        const institutionId = localStorage.getItem("institution_id");
        dispatch({
          type: SUBMISSION_SET_FORM_SUPPORT_DATA,
          payload: {
            step,
            formSupportKey: "formSupport",
            formSupportData: {
              institutions,
              divisions: {
                [institutionId]: divisions,
              },
              titles,
            },
          },
        });
      } else {
        throw json;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const getInstitutionTypesFormSupport = (step) => {
  const url = "/login/user/attributes/institutionTypes";
  return async (dispatch) => {
    try {
      const response = await fetch(url);
      const json = await response.json();
      if (response.ok) {
        dispatch({
          type: SUBMISSION_SET_FORM_SUPPORT_DATA,
          payload: {
            step,
            formSupportKey: "formSupport",
            formSupportData: {
              institutionTypes: json,
            },
          },
        });
      } else {
        throw json;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const getDivisionsFormSupport = (institutionId, step) => {
  const url = `/login/divisions?institutionId=${institutionId}`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async (dispatch) => {
    try {
      const response = await fetch(url, init);
      const json = await response.json();
      if (response.ok) {
        dispatch({
          type: SUBMISSION_SET_DIVISION_FORM_SUPPORT,
          payload: {
            step,
            institutionId,
            divisions: json,
          },
        });
      } else {
        throw json;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const getSubmissionStatus = (id, pathname) => {
  const url = `/submission-api/submissions/${id}`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async (dispatch) => {
    try {
      const response = await fetch(url, init);
      const json = await response.json();
      if (response.ok) {
        const submissionType = json.workflow.type;
        const steps = getSubmissionSteps(submissionType, id);
        const stepToIndexMap = getStepToIndexMap(submissionType);
        const nextStepToCompleteIndex =
          stepToIndexMap[json.workflow.nextStepToComplete];
        let currStepIndex = steps.findIndex((step) => step.to === pathname);
        if (currStepIndex < 0 || currStepIndex > nextStepToCompleteIndex) {
          currStepIndex = nextStepToCompleteIndex;
        }
        dispatch({
          type: SUBMISSION_SET_SUBMISSION_STATUS,
          payload: {
            id: json.id,
            name: json.name?.value,
            abbrev: json.abbrev?.value,
            createdById: json.createdBy,
            submissionType,
            steps,
            stepToIndexMap,
            status: json.workflow.status,
            createdDate: json.createdDate,
            currStepIndex,
            nextStepToCompleteIndex,
          },
        });
      } else {
        throw Error(response.status);
      }
    } catch (err) {
      throw err;
    }
  };
};

export const resetSubmission = () => ({
  type: SUBMISSION_RESET_STATE,
});

export const getStepData = (id, step, currStepIndex) => {
  const url = `/submission-api/submissions/${id}/${step}`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async (dispatch) => {
    try {
      const response = await fetch(url, init);
      const json = await response.json();
      if (response.ok) {
        dispatch({
          type: SUBMISSION_SET_STEP_DATA,
          payload: {
            currStepIndex,
            step,
            data: json,
          },
        });
      } else {
        throw json;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const createSubmission = (submissionType) => async () => {
  try {
    const url = "/submission-api/submissions/create-submission";
    const init = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        "Content-Type": "application/json",
      },
      body: submissionType,
    };
    const response = await fetch(url, init);
    const data = await response.json();
    if (response.ok) {
      return data;
    }
    throw data;
  } catch (error) {
    throw error;
  }
};

export const saveSubmissionStep = (id, step, data) => {
  const url = `/submission-api/submissions/${id}/${step}`;
  const body = formatSubmissionData(step, data);
  let init = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  if (step === "upload-documents") {
    init = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      },
      body,
    };
  }
  return async (dispatch, getState) => {
    try {
      const response = await fetch(url, init);
      const json = await response.json();
      if (response.ok) {
        const nextStepToComplete = json.workflow.nextStepToComplete;
        const stepToIndexMap = getState().submission.stepToIndexMap;
        const nextStepToCompleteIndex = stepToIndexMap[nextStepToComplete];
        if (
          step === "study-registration" ||
          step === "study-info-part-one" ||
          step === "data-collection"
        ) {
          // set the study name and abbreviation when saving first step of the form
          dispatch({
            type: SUBMISSION_SET_NEXT_STEP,
            payload: {
              name: json.name.value,
              abbrev: json.abbrev.value,
              nextStepToCompleteIndex,
            },
          });
        } else {
          dispatch({
            type: SUBMISSION_SET_NEXT_STEP,
            payload: {
              nextStepToCompleteIndex,
            },
          });
        }
        return nextStepToCompleteIndex;
      }
      throw json;
    } catch (error) {
      throw error;
    }
  };
};

export const createLocation = (data, locationType, step) => {
  let url = "/login/divisions";
  if (locationType === LOCATION_TYPES.INSTITUTION) {
    url = "/login/institutions";
    data.isNIH = false; // backend needs this field for now
  }
  const init = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    try {
      const response = await fetch(url, init);
      const json = await response.json();
      if (response.ok) {
        let type = SUBMISSION_CREATE_NEW_INSTITUTION;
        let payload = {
          step,
          institution: {
            id: json.id,
            name: json.name,
          },
        };
        if (locationType === LOCATION_TYPES.DIVISION) {
          type = SUBMISSION_CREATE_NEW_DIVISION;
          payload = {
            step,
            institutionId: json.institution.id,
            newDivision: {
              id: json.id,
              name: json.name,
            },
          };
        }
        dispatch({ type, payload });
        return payload;
      }
      throw json;
    } catch (error) {
      throw error;
    }
  };
};

export const getInstitutionInfo = (institutionId) => {
  const url = `/login/institutions/${institutionId}/address`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async () => {
    try {
      const response = await fetch(url, init);
      const json = await response.json();
      if (response.ok) {
        return json;
      }
      throw json;
    } catch (error) {
      throw error;
    }
  };
};

export const submitFormAsync =
  (id, form, displayError) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const values = getFormValues(form)(getState());
      // POST call to save the user's data, if we get 200 then resolve the promise, otherwise reject
      // the promise and display any validation errors if needed
      return saveSubmissionStep(
        id,
        form,
        values,
      )(dispatch, getState)
        .then((nextStepToCompleteIndex) => {
          resolve(nextStepToCompleteIndex);
        })
        .catch(() => {
          if (displayError) {
            // this is the second call to the POST in order to display the errors in the page
            dispatch(submit(form));
          }
          reject();
        });
    });

export const submitFormSync = (form) => (dispatch) => dispatch(submit(form));

export const setCurrentStepIndex = (index) => ({
  type: SUBMISSION_SET_CURR_STEP_INDEX,
  payload: {
    currStepIndex: index,
  },
});

export const getFormSupportData = (step) => (dispatch, getState) =>
  getState().submission[step].formSupport;

export const getReduxFormValues = (formName) => (dispatch, getState) =>
  getFormValues(formName)(getState());

export const touchFormField = (formName, field) => (dispatch) =>
  dispatch(touch(formName, field));
