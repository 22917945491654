import {
  REGISTRATION_SET_FORM_SUPPORT,
  REGISTRATION_RESET_STATE,
  REGISTRATION_SET_INACTIVE_USER_FORM,
} from "../../common/types";

export const INITIAL_STATE = {
  formSupport: {},
  initialValues: {
    division: {
      useInstName: false,
      useInstAddress: false,
      createNew: false,
      institution: {
        createNew: false,
      },
    },
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTRATION_SET_FORM_SUPPORT:
      return {
        ...state,
        formSupport: {
          ...state.formSupport,
          [action.payload.formSupportType]: action.payload.data,
        },
      };
    case REGISTRATION_SET_INACTIVE_USER_FORM:
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          id: action.payload.id,
          emailAddress: action.payload.emailAddress,
          emailConfirm: action.payload.emailAddress,
          title: action.payload.title,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          middleInitial: action.payload.middleInitial,
          occupation: action.payload.occupation,
          phone: action.payload.phone,
          division: {
            id: action.payload.institutionDivision.id,
            institution: {
              id: action.payload.institutionDivision.institution.id,
            },
          },
          isNIH:
            action.payload.institutionDivision.institution.institutionType ===
            "Government Agency (NIH)"
              ? "true"
              : "false",
        },
      };
    case REGISTRATION_RESET_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
