import React from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import { Checkbox } from "../../../inputs";

const RolesForm = (props) => {
  const { roles } = props;

  const checkboxes = roles.map((role) => (
    <div key={role} style={{ padding: "5px 0" }} className='col-md-6'>
      <Field
        name={`roles.${role}`}
        id={role}
        component={Checkbox}
        label={role}
      />
    </div>
  ));

  return (
    <div style={{ marginTop: "30px" }} className='p-15'>
      <h2>User Roles</h2>
      <div className='row'>{checkboxes}</div>
    </div>
  );
};

RolesForm.propTypes = {
  roles: PropTypes.array,
};

export default RolesForm;
