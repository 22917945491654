import React from "react";
import { startCase } from "lodash";
import {
  validateForm,
  renderValidationErrorMessage,
  fieldIsUndefined,
} from "../../../../../common/utils/validation";
import {
  FIELD_TYPES,
  ADMIN_REQUEST_ACTION_TYPES,
} from "../../../../../common/types";

export const validateApproveRequestForm = (values, props) => {
  const approveRequestFields = {
    approvalDate: {
      fieldType: FIELD_TYPES.FORM_SECTION,
      shouldValidate: () => true,
      fields: [
        {
          error: () => !values.approvalDate,
          message: "Please select an Approval Date",
        },
      ],
    },
    irbApprovalDate: {
      fieldType: FIELD_TYPES.FORM_SECTION,
      shouldValidate: () => props.irbRequired,
      fields: [
        {
          error: () => !values.irbApprovalDate,
          message: "Please select an IRB Approval Date",
        },
      ],
    },
  };
  return validateForm(values, approveRequestFields);
};

export const validateTerminateRequestForm = (values) => {
  const terminateRequestFields = {
    terminationReason: {
      fieldType: FIELD_TYPES.FORM_SECTION,
      shouldValidate: () => true,
      fields: [fieldIsUndefined(null, "Termination Reason is required")],
    },
    terminationExplanation: {
      fieldType: FIELD_TYPES.FORM_SECTION,
      shouldValidate: () => true,
      fields: [fieldIsUndefined(null, "Termination Explanation is required")],
    },
  };
  return validateForm(values, terminateRequestFields);
};

export const validateUploadExecutedAgreementForm = (values) => {
  const errors = {};
  if (!values.uploadRequestFileForm) {
    errors.uploadRequestFileForm = "Please select the executed agreement file";
  }
  return errors;
};

export const validateUploadShipmentFulfillmentForm = (values) => {
  const errors = {};
  if (!values.uploadFulfillmentForm) {
    errors.uploadFulfillmentForm =
      "Please select the shipment fulfillment form";
  }
  return errors;
};

export const validateRemoveRequest = (values) => {
  const errors = {};
  if (!values.removalReason) {
    errors.removalReason = "Please provide a reason for removal";
  }
  return errors;
};

export const validateDenyRequest = (values) => {
  const errors = {};
  if (!values.denialReason) {
    errors.denialReason = "Please provide a reason for denial";
  }
  return errors;
};

export const parseBackendErrors = (backendErrors) => {
  const message = "Please correct the errors in the below field(s):";
  if (backendErrors.validationErrors) {
    const options = backendErrors.validationErrors.map((field, index) => ({
      name: startCase(field.field),
      index,
    }));
    return renderValidationErrorMessage(message, options);
  }
  if (backendErrors.message) {
    return renderValidationErrorMessage(backendErrors.message);
  }
  return <p> There was an unexpected error. Please try again. </p>;
};

export const getSuccessfulAlertMessage = (actionType) => {
  let message = "";
  switch (actionType) {
    case ADMIN_REQUEST_ACTION_TYPES.APPROVE:
      message = "Request has been successfully approved";
      break;
    case ADMIN_REQUEST_ACTION_TYPES.DENY:
      message = "Request has been successfully denied";
      break;
    case ADMIN_REQUEST_ACTION_TYPES.REMOVE:
      message = "Request has been successfully removed";
      break;
    case ADMIN_REQUEST_ACTION_TYPES.UPLOAD:
      message = "Executed agreement has been successfully uploaded";
      break;
    case ADMIN_REQUEST_ACTION_TYPES.SHIP:
      message = "Shipment Fulfillment Form has been successfully uploaded";
      break;
    case ADMIN_REQUEST_ACTION_TYPES.TERMINATE:
      message = "Request has been successfully terminated.";
      break;
    case ADMIN_REQUEST_ACTION_TYPES.UPLOAD_RENEWAL_FORM:
      message = "Request Renewal has been successfully uploaded.";
      break;
    case ADMIN_REQUEST_ACTION_TYPES.RENEWAL_APPROVE:
      message = "Request Renewal has been successfully approved.";
      break;
    case ADMIN_REQUEST_ACTION_TYPES.RENEWAL_DENY:
      message = "Request Renewal has been successfully denied.";
      break;
    case ADMIN_REQUEST_ACTION_TYPES.UPLOAD_AOR_MODIFICATION_FORM:
      message = "AOR Modification has been successfully uploaded.";
      break;
    case ADMIN_REQUEST_ACTION_TYPES.AOR_MODIFICATION_APPROVE:
      message = "AOR Modification has been successfully approved.";
      break;
    case ADMIN_REQUEST_ACTION_TYPES.AOR_MODIFICATION_DENY:
      message = "AOR Modification has been successfully denied.";
      break;
    default:
      break;
  }
  return message;
};
