export class LocalStorageMock {
  constructor() {
    this.store = {};
  }

  clear() {
    this.store = {};
  }

  getItem(key) {
    return this.store[key] || null;
  }

  setItem(key, value) {
    this.store[key] = value.toString();
  }

  removeItem(key) {
    delete this.store[key];
  }
}

// mock user data of mohit
export const mohitUser = {
  id: 500,
  emailAddress: "bisht_mohit@bah.com",
  firstName: "Mohit",
  middleInitial: null,
  lastName: "Bisht",
  occupation: "Emperor",
  title: null,
  phone: null,
  institutionDivision: {
    id: 201,
    name: "eeep",
    address: {
      country: "United States of America",
      address1: "jkl;",
      address2: "kl;",
      city: "mmm",
      state: "AZ",
      zip: "20770",
    },
    institution: {
      id: 27,
      name: "Booz Allen Hamilton",
      isForProfit: true,
      address: {
        country: "",
        address1: "One Preserve Pkwy",
        address2: "",
        city: "Rockville",
        state: "MD",
        zip: "20852",
      },
    },
  },
  locked: false,
};
