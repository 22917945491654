import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

// renders tabs to toggle between different sections of a page
const Tabs = ({ tabs, selectedTab, onClick, className }) => (
  <div className={`tab-container ${className}`}>
    {tabs.map(({ tab, label, ariaLabel }) => (
      <button
        key={tab}
        type='button'
        className={selectedTab === tab ? "tab active-tab" : "tab"}
        aria-label={ariaLabel}
        onClick={() => onClick(tab, selectedTab)}
      >
        {label}
      </button>
    ))}
  </div>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tab: PropTypes.string.isRequired, // each tab should be unique
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedTab: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired, // this function receives the new selected tab, and the previously selected tab
  className: PropTypes.string,
};

export default Tabs;
