import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import ModifyResearchTeamForm from "../components/forms/ModifyResearchTeamForm";
import * as workspaceActions from "../../../features/workspace/WorkspaceActions";
import { LOCATION_TYPES } from "../../types";

const ModifyResearchTeamModal = (props) => {
  const {
    duaId,
    isOpen,
    studyName,
    users,
    formSupport,
    submitFormSync,
    closeModal,
    createInstitution,
    ...rest
  } = props;

  const [onConfirmation, setOnConfirmation] = useState(false);

  const toggleConfirmationModal = () => {
    setOnConfirmation(!onConfirmation);
  };

  if (!formSupport) {
    return null;
  }

  const { institutions, titles, institutionTypes } = formSupport;

  const modalProps = {
    submitReduxForm: props.submitFormSync,
    createInstitution,
    locationType: LOCATION_TYPES.INSTITUTION,
    dataType: "duaData",
    institutionTypes,
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={closeModal} size='lg' backdrop='static'>
        <ModalHeader toggle={closeModal} tag='h2'>
          Modify Research Team for {studyName}
        </ModalHeader>
        <ModalBody>
          <p>
            All fields marked with an asterisk ( 
            <span className='requiredField'>*</span> ) are required.
          </p>
          <ModifyResearchTeamForm
            duaId={duaId}
            users={users}
            institutions={institutions}
            titles={titles}
            modalProps={modalProps}
            isOpen={onConfirmation}
            closeModifyResearchTeamModal={closeModal}
            closeConfirmationModal={toggleConfirmationModal}
            studyName={studyName}
            {...rest}
          />
        </ModalBody>
        <ModalFooter>
          <button
            type='button'
            className='btn modal-btn btn-seconday'
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn modal-btn modal-btn-blue'
            onClick={toggleConfirmationModal}
          >
            Submit
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

ModifyResearchTeamModal.propTypes = {
  duaId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  studyName: PropTypes.string,
  users: PropTypes.array.isRequired,
  submitFormSync: PropTypes.func.isRequired,
  createInstitution: PropTypes.func.isRequired,
  formSupport: PropTypes.shape({
    institutions: PropTypes.object,
    institutionTypes: PropTypes.any,
    titles: PropTypes.array,
  }),
};

const actions = {
  createInstitution: workspaceActions.createInstitution,
  changeResearchTeam: workspaceActions.changeResearchTeam,
};

const mapStateToProps = ({ workspace }, { item, dataType }) => ({
  initialAffiliates: workspace[dataType].pagination[item.id].affiliates,
  initialAssociates: workspace[dataType].pagination[item.id].associates,
  initialCollaborators: workspace[dataType].pagination[item.id].collaborators,
  users: workspace[dataType].pagination[item.id].users,
  formSupport: workspace[dataType]?.formSupport,
});

export default connect(mapStateToProps, actions)(ModifyResearchTeamModal);
