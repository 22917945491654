import _ from "lodash";
import {
  WORKSPACE_SET_INITIAL_DATA,
  WORKSPACE_SET_DATA,
  SET_DUA_ITEMS,
  GET_AFFILIATES,
  GET_RESEARCH_TEAM,
  DUA_SET_SELECTED_ITEMS,
  DUA_SET_COLLAPSE_OPEN,
  UPDATE_COUNTS,
  WORKSPACE_CREATE_NEW_INSTITUTION,
} from "../../common/types";
import { downloadFile } from "../../common/utils";

const baseUrl = "/workbench-api";

const getInitialData = (data) => {
  const pagination = {};
  const formSupport = {
    institutions: {},
    institutionTypes: {},
    titles: {},
  };
  let totalItems = 0;
  data.forEach((study) => {
    pagination[study.id] = {
      page: {
        number: 0, // current page
        size: 50, // maximum number of items per page
        totalElements: study.numItems,
        totalPages: Math.ceil(study.numItems / 50),
      },
      items: null,
      itemsCanBeModified: study.itemsCanBeModified,
      collapseOpen: false,
      selectedItems: {},
      users: [],
      affiliates: {},
    };
    totalItems += study.numItems;
  });
  return {
    data,
    pagination,
    totalItems,
    formSupport,
  };
};

const getInboxData = () => {
  const url = `${baseUrl}/inbox`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async (dispatch) => {
    try {
      const response = await fetch(url, init);
      const data = await response.json();

      if (response.ok) {
        dispatch({
          type: WORKSPACE_SET_DATA,
          payload: getInitialData(data),
          dataType: "inboxData",
        });
      }
    } catch (error) {
      throw error;
    }
  };
};

export const getInitialWorkspaceData = () => {
  const countUrl = `${baseUrl}/count`;
  const inboxUrl = `${baseUrl}/inbox`;
  const mtaUrl = `${baseUrl}/mta`;
  const duaUrl = `${baseUrl}/dua`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async (dispatch) => {
    try {
      const [count, inbox, mta, dua] = await Promise.all([
        fetch(countUrl, init),
        fetch(inboxUrl, init),
        fetch(mtaUrl, init),
        fetch(duaUrl, init),
      ]);
      if (count.ok && inbox.ok && mta.ok && dua.ok) {
        const [countData, inboxData, mtaData, duaData] = await Promise.all([
          count.json(),
          inbox.json(),
          mta.json(),
          dua.json(),
        ]);

        dispatch({
          type: WORKSPACE_SET_INITIAL_DATA,
          payload: {
            counts: {
              duaData: countData.numDataUseAgreements,
              inboxData: countData.numInboxItems,
              mtaData: countData.numMaterialTransferAgreements,
            },
            inboxData: getInitialData(inboxData),
            mtaData: getInitialData(mtaData),
            duaData: getInitialData(duaData),
          },
        });
      }
    } catch (error) {
      throw error;
    }
  };
};

const setPaginationData = (duaId, page, items, collapseOpen, dataType) => ({
  type: SET_DUA_ITEMS,
  payload: {
    duaId,
    page,
    items,
    collapseOpen,
    dataType,
  },
});

const getUrlWithSortAndFilter = (url, sort, filterCategory) => {
  if (sort && sort.direction !== "none" && sort.column !== "none") {
    url = `${url}&sort=${sort.column},${sort.direction}`;
  }
  if (filterCategory !== "All Items") {
    url = `${url}&filterEq=category::${filterCategory}`;
  }
  return url;
};

export const getItems = (
  duaId,
  currPage,
  collapseOpen,
  sort,
  filterCategory,
  dataType,
  size = 50,
) => {
  const wsType = dataType.split("Data")[0];
  const initialUrl = `${baseUrl}/${wsType}/${duaId}/items?page=${currPage}&size=${size}`;
  const url = getUrlWithSortAndFilter(initialUrl, sort, filterCategory);
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async (dispatch) => {
    try {
      const response = await fetch(url, init);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setPaginationData(
            duaId,
            data.page,
            data._embedded.items,
            collapseOpen,
            dataType,
          ),
        );
      } else {
        throw data;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const updateCollapse = (duaId, collapseOpen, dataType) => ({
  type: DUA_SET_COLLAPSE_OPEN,
  payload: {
    duaId,
    collapseOpen,
    dataType,
  },
});

export const setSelectedItems = (
  duaId,
  rowSelected,
  dataType,
  selectAllItemsOption,
) => ({
  type: DUA_SET_SELECTED_ITEMS,
  payload: {
    duaId,
    rowSelected,
    dataType,
    selectAllItemsOption,
  },
});

export const cancelSelection =
  (duaId, dataType, selectedItems) => (dispatch) => {
    _.forEach(selectedItems, (item) =>
      dispatch(setSelectedItems(duaId, item, dataType)),
    );
  };

export const removeItemsFromInbox = (
  duaId,
  currPage,
  collapseOpen,
  sort,
  filterCategory,
  dataType,
  selectedItems,
) => {
  const itemIds = selectedItems.map((item) => item.id);
  const initialUrl = `${baseUrl}/inbox/${duaId}/removeItems?page=${currPage}&size=10`;
  const url = getUrlWithSortAndFilter(initialUrl, sort, filterCategory);
  const deleteInit = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      duaItemIds: itemIds,
    }),
  };
  return async (dispatch) => {
    try {
      const response = await fetch(url, deleteInit);
      const data = await response.json();

      if (response.ok) {
        if (data.numInboxItemsRemaining === 0) {
          dispatch(getInboxData());
          dispatch({
            type: UPDATE_COUNTS,
            numInboxItems: data.numInboxItemsRemaining,
          });
        } else {
          const hasItems = _.has(data, "_embedded");
          if (!hasItems) {
            dispatch(getInboxData());
            dispatch({
              type: UPDATE_COUNTS,
              numInboxItems: data.numInboxItemsRemaining,
            });
          } else {
            dispatch(
              setPaginationData(
                duaId,
                data.page,
                data._embedded.items,
                collapseOpen,
                dataType,
              ),
            );
            // unselect items
            _.forEach(selectedItems, (item) => {
              dispatch(setSelectedItems(duaId, item, dataType));
            });
            dispatch({
              type: UPDATE_COUNTS,
              numInboxItems: data.numInboxItemsRemaining,
            });
          }
        }
      } else {
        throw data;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const addItemsToInbox = (
  dua,
  currPage,
  collapseOpen,
  sort,
  filterCategory,
  dataType,
  selectedItems,
) => {
  const itemIds = selectedItems.map((i) => i.id);
  const url = `${baseUrl}/inbox/addItems`;
  const postInit = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      duaItemIds: itemIds,
    }),
  };
  return async (dispatch) => {
    try {
      const response = await fetch(url, postInit);
      const data = await response.json();

      if (response.ok) {
        if (data.numInboxItemsAdded !== 0) {
          dispatch(getInboxData());
          _.forEach(selectedItems, (item) => {
            dispatch(setSelectedItems(dua.id, item, dataType));
          });

          dispatch(
            getItems(
              dua.id,
              currPage,
              collapseOpen,
              sort,
              filterCategory,
              "duaData",
            ),
          );
          dispatch(
            getItems(
              dua.studyArchiveEntityId,
              currPage,
              false,
              { column: "none", direction: "none" },
              "All Items",
              "inboxData",
            ),
          );
          dispatch({ type: UPDATE_COUNTS, numInboxItems: data.numInboxItems });
        }
      } else {
        throw data;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const selectAllItems = (duaId, size, dataType, filterCategory) => {
  const wsType = dataType.split("Data")[0];
  const initialUrl = `${baseUrl}/${wsType}/${duaId}/items?size=${size}`;
  const url = getUrlWithSortAndFilter(initialUrl, null, filterCategory);
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async (dispatch) => {
    try {
      const response = await fetch(url, init);
      const data = await response.json();

      if (response.ok) {
        const allItems = data._embedded.items.map((item) => ({
          id: item.id,
          archiveEntityId: item.archiveEntityId,
          unformattedFilesize: item.filesize,
          canAdd: !item.isInInbox,
        }));
        _.forEach(allItems, (item) => {
          if (item.canAdd || dataType === "inboxData") {
            dispatch(setSelectedItems(duaId, item, dataType, true));
          }
        });
      } else {
        throw data;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const getAffiliates = (duaId, dataType) => {
  const formSupportURL = `${baseUrl}/dua/${duaId}/research-team-form-support`;
  const affiliateURL = `${baseUrl}/dua/${duaId}/research-team/affiliates`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async (dispatch) => {
    try {
      const [formSupport, affiliate] = await Promise.all([
        fetch(formSupportURL, init),
        fetch(affiliateURL, init),
      ]);
      const [userData, affiliateData] = await Promise.all([
        formSupport.json(),
        affiliate.json(),
      ]);

      if (formSupport.ok && affiliate.ok) {
        dispatch({
          type: GET_AFFILIATES,
          payload: {
            affiliates: affiliateData.affiliates,
            userData: userData.users,
            id: duaId,
            dataType,
          },
        });
      } else {
        throw affiliateData;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const changeAffiliates = (duaId, users) => {
  const url = `${baseUrl}/dua/${duaId}/research-team/affiliates`;
  const postInit = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(users),
    method: "POST",
  };
  return async () => {
    try {
      const response = await fetch(url, postInit);
      const affiliateData = await response.json();

      if (!response.ok) {
        throw affiliateData;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const createInstitution = (data, dataType) => {
  data.isNIH = false; // backend needs this field for now

  const url = "/login/institutions";
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    method: "POST",
  };
  return async (dispatch) => {
    const response = await fetch(url, init);
    const json = await response.json();

    if (response.ok) {
      const type = WORKSPACE_CREATE_NEW_INSTITUTION;
      const payload = {
        dataType,
        institution: {
          id: json.id,
          name: json.name,
        },
      };
      dispatch({ type, payload });
    } else {
      throw json;
    }
  };
};

export const getResearchTeam = (duaId, dataType) => {
  const formSupportURL = `${baseUrl}/dua/${duaId}/research-team-form-support`;
  const researchTeamURL = `${baseUrl}/dua/${duaId}/research-team`;
  const institutionTypeUrl = "/login/user/attributes/institutionTypes";
  const getInit = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };

  return async (dispatch) => {
    try {
      const [formSupport, researchTeam, institutionTypesResponse] =
        await Promise.all([
          fetch(formSupportURL, getInit),
          fetch(researchTeamURL, getInit),
          fetch(institutionTypeUrl),
        ]);
      const [userData, researchTeamData, institutionTypes] = await Promise.all([
        formSupport.json(),
        researchTeam.json(),
        institutionTypesResponse.json(),
      ]);
      userData.users.forEach((user) => {
        user.label = user.name;
        user.value = user.name;
      });

      if (formSupport.ok && researchTeam.ok) {
        dispatch({
          type: GET_RESEARCH_TEAM,
          payload: {
            affiliates: researchTeamData.affiliates,
            associates: researchTeamData.associates,
            collaborators: researchTeamData.collaborators,
            institutions: userData.institutions,
            titles: userData.titles,
            userData: userData.users,
            institutionTypes,
            id: duaId,
            dataType,
          },
        });
      } else {
        throw researchTeamData;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const changeResearchTeam = (duaId, researchTeamData) => {
  const researchTeamURL = `${baseUrl}/dua/${duaId}/research-team`;
  const postInit = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(researchTeamData),
    method: "POST",
  };

  return async () => {
    try {
      const response = await fetch(researchTeamURL, postInit);
      const teamData = await response.json();

      if (!response.ok) {
        throw teamData;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const downloadExecutedDua = (duaId) => {
  const url = `/workbench-api/dua/${duaId}/download-documents`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return downloadFile(url, init);
};

export const downloadAnnualReport = (annualReportId) => {
  const url = `workbench-api/requests/${annualReportId}/annual-report/download-annual-report`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return downloadFile(url, init);
};
