import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const RadioButton = (props) => {
  const {
    input,
    className,
    inputClassName,
    labelClassName,
    label,
    ariaLabel,
    type,
    meta: { touched, error, warning },
    ...rest
  } = props;

  const checkboxClasses = `${className} radio-dash`;
  const inputClasses = `${inputClassName} form-radio-input`;
  const labelClasses = `${labelClassName} form-radio-label`;

  const inputStyle =
    touched && (error || warning)
      ? {
          border: "1px solid rgb(213, 0, 0)",
          boxShadow: "0 0 5px rgb(213, 0, 0)",
          marginBottom: "0px",
        }
      : {};

  return (
    <div className={checkboxClasses}>
      <input
        {...input}
        role='radio'
        id={`dash-radio-${input.name}-${input.value}`}
        className={inputClasses}
        type={type}
        label={label}
        aria-label={`${ariaLabel ? ariaLabel : ""} ${
          input.checked ? "checked" : "unchecked"
        }`}
        {...rest}
      />
      <label
        htmlFor={`dash-radio-${input.name}-${input.value}`}
        style={inputStyle}
      />
      <span className={labelClasses}>&nbsp;{label}</span>
    </div>
  );
};

RadioButton.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
};

RadioButton.defaultProps = {
  className: "",
  inputClassName: "",
  labelClassName: "",
};

export default RadioButton;
