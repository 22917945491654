import { PROFILE_SET_FORM_SUPPORT, PROFILE_RESET } from "../../common/types";

export const INITIAL_STATE = {
  institutions: [],
  divisions: [],
  titles: [],
  user: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROFILE_SET_FORM_SUPPORT:
      return {
        ...state,
        [action.payload.formSupportType]: action.payload.data,
      };
    case PROFILE_RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
