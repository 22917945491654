import _ from "lodash";
import {
  textValidationArray,
  fieldIsUndefined,
  validateForm,
  renderValidationErrorMessage,
} from "../../../../../common/utils";
import { ALERT_TYPES, FIELD_TYPES } from "../../../../../common/types";

export const nameMaxLength = 32;
export const maxLength = 256;

const maxCharLength = (fieldName, fieldMaxLength, fieldLabel) => [
  {
    // don't display error message if field is empty
    error: (values) => !values[fieldName],
    message: null,
  },
  {
    error: (values) =>
      values[fieldName] && values[fieldName].trim().length === 0,
    message: `${fieldLabel} may not be blank`,
  },
  {
    error: (values) =>
      values[fieldName] && values[fieldName].trim().length > fieldMaxLength,
    message: `${fieldLabel} may not exceed ${fieldMaxLength} characters`,
  },
];

const publicationFields = (outcome) => ({
  title: textValidationArray(`${outcome}'s title`, 300),
  publicationURL: textValidationArray(`${outcome}'s URL`, 256),
  journal: textValidationArray(`${outcome}'s journal`, 200),
  description: textValidationArray(`${outcome}'s citation`, 700),
  authors: textValidationArray(`${outcome}'s author(s)`, 500),
  date: [fieldIsUndefined(null, `${outcome}'s date`)],
});

const presentationFields = (outcome) => ({
  title: textValidationArray(`${outcome}'s title`, 300),
  presentedTo: textValidationArray(`${outcome}'s audience`, 300),
  description: textValidationArray(`${outcome}'s description`, 700),
  date: [fieldIsUndefined(null, `${outcome}'s date`)],
});

const significantFindingFields = (outcome) => ({
  title: textValidationArray(`${outcome}'s title`, 300),
  description: textValidationArray(`${outcome}'s description`, 700),
  date: [fieldIsUndefined(null, `${outcome}'s date`)],
});

const otherOutcomeFields = (outcome) => ({
  title: textValidationArray(`${outcome}'s title`, 300),
  description: textValidationArray(`${outcome}'s description`, 700),
  date: [fieldIsUndefined(null, `${outcome}'s date`)],
});

const patentFields = (outcome) => ({
  title: textValidationArray(`${outcome}'s title`, 300),
  patentNumber: textValidationArray(`${outcome}'s Number`, 300),
  applicationNumber: textValidationArray(
    `${outcome}'s application number`,
    300,
  ),
  description: textValidationArray(`${outcome}'s description`, 700),
  date: [fieldIsUndefined(null, `${outcome}'s date`)],
});

const researchTeamFields = {
  haspublications: [
    fieldIsUndefined(null, "Please select if there have been any publications"),
  ],
  haspresentations: [
    fieldIsUndefined(
      null,
      "Please select if there have been any presentations",
    ),
  ],
  haspatents: [
    fieldIsUndefined(null, "Please select if there have been any patents"),
  ],
  hassignificantfindings: [
    fieldIsUndefined(
      null,
      "Please select if there have been any significant findings",
    ),
  ],
  hasotheroutcomes: [
    fieldIsUndefined(
      null,
      "Please select if there have been any other outcomes",
    ),
  ],
  additionalInformation: maxCharLength(
    "additionalInformation",
    500,
    "Additional Information",
  ),

  publications: {
    fieldType: FIELD_TYPES.FIELD_ARRAY,
    shouldValidate: (values) => values.haspublications === "true",
    fields: {
      ...publicationFields("Publication"),
    },
  },
  presentations: {
    fieldType: FIELD_TYPES.FIELD_ARRAY,
    shouldValidate: (values) => values.haspresentations === "true",
    fields: {
      ...presentationFields("Presentation"),
    },
  },
  patents: {
    fieldType: FIELD_TYPES.FIELD_ARRAY,
    shouldValidate: (values) => values.haspatents === "true",
    fields: {
      ...patentFields("Patent"),
    },
  },
  significantfindings: {
    fieldType: FIELD_TYPES.FIELD_ARRAY,
    shouldValidate: (values) => values.hassignificantfindings === "true",
    fields: {
      ...significantFindingFields("Signifcant Finding"),
    },
  },
  otheroutcomes: {
    fieldType: FIELD_TYPES.FIELD_ARRAY,
    shouldValidate: (values) => values.hasotheroutcomes === "true",
    fields: {
      ...otherOutcomeFields("Other Outcome"),
    },
  },
};

export const validateAnnualReport = (values) => {
  return validateForm(values, researchTeamFields);
};

// receives user input for the research team form and reformats it to an object the backend will accept
export const parseResearchTeamData = (inputData) => {
  const returnData = { ...inputData };
  if (returnData.hasAffiliates === "false") {
    returnData.affiliates = [];
  }
  if (returnData.hasAssociates === "false") {
    returnData.associates = [];
  }
  if (returnData.hasCollaborators === "false") {
    returnData.collaborators = [];
  }
  return returnData;
};

/**
 * This object maps each field name to it's corresponding label. The label is what gets displayed
 * on the screen and the field name is used internally by redux-form to identify each field.
 * The index is used by the ErrorBar component to set the display order of the bulleted list of field labels.
 * The bulleted label list is ordered from lowest index to highest, so something like 'Affiliates'
 * with index 1 will be displayed before 'Collaborators' with index 3.
 */
const outcomeNameFieldMap = {
  publications: {
    label: "Publications",
    index: 1,
  },
  presentations: {
    label: "Presentations",
    index: 1,
  },
  patent: {
    label: "Patents",
    index: 1,
  },
  other: {
    label: "Other Outcomes",
    index: 1,
  },
  significantFindings: {
    label: "Significant Findings",
    index: 1,
  },
  hasPublications: {
    label: "Publications",
    index: 1,
  },
  hasPresentations: {
    label: "Presentations",
    index: 1,
  },
  hasPatents: {
    label: "Patents",
    index: 1,
  },
  hasOther: {
    label: "Other Outcomes",
    index: 1,
  },
  hasSignifcantFindings: {
    label: "Significant Findings",
    index: 1,
  },
};

const errorFieldsMap = {
  date: "Date",
  description: "Description",
  title: "Title",
  patentApplicationNumber: "Patent Application Number",
  patentNumber: "Patent Number",
  presentedTo: "Presented To",
  authors: "Authors",
  journal: "Journal",
  publicationURL: "Publication URL",
};

// adds a new field label to the options array
const setOptionsError = (field, errors) => {
  const option = {
    name: outcomeNameFieldMap[field].label,
    index: outcomeNameFieldMap[field].index,
  };
  if (_.findIndex(errors._error.options, option) < 0) {
    errors._error.options.push(option);
  }
};

// adds new FieldArray label to the options array
const setFieldArrayOption = (
  optionLabel,
  optionIndex,
  fieldArrayIndex,
  errors,
) => {
  const option = {
    name: `${optionLabel} `,
    index: optionIndex,
  };
  if (_.findIndex(errors._error.options, option) < 0) {
    errors._error.options.push(option);
  }
};

/**
 * Sets validation error for the fields in the individual fields in the annual report
 * @param {*} matchedArray regex array in which index 1 contains the FieldArray name, index 2 contains the FieldArray index, and index 3 contains the name of the field with the error
 * @param {*} error the error message to display
 * @param {*} errors the error object that gets returned to the AnnualReportContainer
 * @param {*} optionLabel the FieldArray label
 * @param {*} optionIndex position of the bulleted FieldArray label when displayed in the ErrorBar
 */
const setFieldArrayError = (
  matchedArray,
  error,
  errors,
  optionLabel,
  optionIndex,
) => {
  const fieldArrayName = matchedArray[1];
  const fieldArrayIndex = parseInt(matchedArray[2], 10);
  const fieldName = matchedArray[3];
  const fieldArrayError = {
    [fieldName]: `${
      error === "must not be null"
        ? `${errorFieldsMap[fieldName]} is required `
        : "no"
    }`,
  };

  // checks to see if error has already been made
  errors[fieldArrayName][fieldArrayIndex] = errors[fieldArrayName][
    fieldArrayIndex
  ]
    ? { ...errors[fieldArrayName][fieldArrayIndex], ...fieldArrayError }
    : fieldArrayError;
  setFieldArrayOption(optionLabel, optionIndex, fieldArrayIndex, errors);
};

// sets error for the hasAffiliates, affiliates, hasAssociates, and hasCollaborators fields
const setError = (field, error, errors) => {
  errors[field] = error;
  setOptionsError(field, errors);
};

/**
 * This function gets the backend error response from submitting the research team form
 * and returns a new object that is used to display the error.
 * @param {*} backendErrors error response from submitting the form
 */
export const parseBackendErrors = (backendErrors) => {
  const errors = {
    _error: {
      message: null,
      options: [],
    },
  };
  if (backendErrors.validationErrors) {
    errors._error.message =
      "Please correct the errors in the following field(s):";
    errors.publications = [];
    errors.presentations = [];
    errors.significantFindings = [];
    errors.other = [];
    errors.patents = [];

    backendErrors.validationErrors.forEach(({ field, error }) => {
      if (field.match(/(publications)\[(\d+)\].(.+)/)) {
        const matchedArray = field.match(/(publications)\[(\d+)\].(.+)/);
        setFieldArrayError(
          matchedArray,
          error,
          errors,
          `Publication ${parseInt(matchedArray[2], 10) + 1} ${_.startCase(
            matchedArray[3],
          )}`,
          1,
        );
      } else if (field.match(/(presentations)\[(\d+)\].(.+)/)) {
        const matchedArray = field.match(/(presentations)\[(\d+)\].(.+)/);
        setFieldArrayError(
          matchedArray,
          error,
          errors,
          `Presentation ${parseInt(matchedArray[2], 10) + 1} ${_.startCase(
            matchedArray[3],
          )}`,
          2,
        );
      } else if (field.match(/(patents)\[(\d+)\].(.+)/)) {
        const matchedArray = field.match(/(patents)\[(\d+)\].(.+)/);
        setFieldArrayError(
          matchedArray,
          error,
          errors,
          `Patent ${parseInt(matchedArray[2], 10) + 1} ${_.startCase(
            matchedArray[3],
          )}`,
          3,
        );
      } else if (field.match(/(significantFindings)\[(\d+)\].(.+)/)) {
        const matchedArray = field.match(/(significantFindings)\[(\d+)\].(.+)/);
        setFieldArrayError(
          matchedArray,
          error,
          errors,
          `Significant Finding ${
            parseInt(matchedArray[2], 10) + 1
          } ${_.startCase(matchedArray[3])}`,
          4,
        );
      } else if (field.match(/(other)\[(\d+)\].(.+)/)) {
        const matchedArray = field.match(/(other)\[(\d+)\].(.+)/);
        setFieldArrayError(
          matchedArray,
          error,
          errors,
          `Other Outcome ${parseInt(matchedArray[2], 10) + 1} ${_.startCase(
            matchedArray[3],
          )}`,
          5,
        );
      } else {
        setError(field, error, errors);
      }
    });
  } else {
    errors._error.message = backendErrors.message || "Error saving the request";
  }
  errors.otheroutcomes = errors.other;
  delete errors.other;
  errors.significantfindings = errors.significantFindings;
  delete errors.other;
  return {
    ...errors,
    _error: {
      alertType: ALERT_TYPES.ERROR,
      message: renderValidationErrorMessage(
        errors._error.message,
        errors._error.options,
      ),
    },
  };
};
