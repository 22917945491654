import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import DateSelectForm from "../DateSelectForm";
import { validateApproveRequestForm } from "./AdminRequestValidator";

export const ApproveRequestForm = (props) => (
  <form onSubmit={props.handleSubmit}>
    <div>
      Enter Data Access Committee Approval Date and if applicable IRB Approval
      Date. Please click &apos;Submit&apos; if you have received the
      committee&apos;s approval, or click &apos;Close&apos; to return to the
      request administration dashboard.
    </div>
    <div className='form-group p-15'>
      <DateSelectForm
        label='Approval Date'
        id='approvalDate'
        name='approvalDate'
        ariaLabel='Select Approval Date'
        isRequired
      />
    </div>
    {props.irbRequired && (
      <div className='form-group p-15'>
        <DateSelectForm
          label='IRB Approval Date'
          id='irbApprovalDate'
          name='irbApprovalDate'
          ariaLabel='Select IRB Approval Date'
          isRequired
        />
      </div>
    )}
  </form>
);

ApproveRequestForm.propTypes = {
  handleSubmit: PropTypes.func,
  irbRequired: PropTypes.bool,
};

export default reduxForm({
  form: "approveRequestForm",
  warn: validateApproveRequestForm,
})(ApproveRequestForm);
