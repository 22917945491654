import React from "react";
import _ from "lodash";
import { renderValidationErrorMessage } from "../../../../../common/utils/validation";
import { ALERT_TYPES, REQUEST_TYPES } from "../../../../../common/types";

export const getPackageName = (key) => {
  if (key === "proof-of-funding") {
    return "Proof of Funding";
  } else if (key === "irb-approval") {
    return "IRB Approval Letter";
  } else if (key === "irb-approval-for-biospecimens") {
    return "IRB Approval for Biospecimens";
  }
  return _.startCase(key);
};

export const validate = (values) => {
  const errors = {};
  Object.keys(values).forEach((key) => {
    if (!values[key]) {
      const file = getPackageName(key);
      errors[key] = `Please upload the ${file}`;
    }
  });
  return errors;
};

export const parseUploadPackageData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    const uploadField = data[key];
    if (uploadField && uploadField.fileKey && uploadField.files) {
      formData.append(
        uploadField.fileKey,
        uploadField.files[0],
        uploadField.files[0].name,
      );
    }
  });
  return formData;
};

export const parseBackendErrors = (backendErrors) => {
  const errors = {
    _error: {
      message: null,
      options: [],
    },
  };
  errors._error.message = backendErrors.message;
  return errors;
};

// returns alert message to display after saving the upload package step. Since the backend
// for the upload package step does not have any validation as of 05/2019, the frontend has
// compute the appropriate message based on the save response
export const getUploadPackageAlert = (resp, requestType) => {
  if (!resp.requiredDocuments) {
    return null;
  }
  const options = [];
  Object.keys(resp.requiredDocuments).forEach((file, index) => {
    if (!resp.requiredDocuments[file]) {
      const option = {
        name: getPackageName(file),
        index,
      };
      options.push(option);
    }
  });

  if (options.length > 0) {
    return {
      alertType: ALERT_TYPES.ERROR,
      alertMessage: renderValidationErrorMessage(
        "Please upload the following documents:",
        options,
      ),
    };
  }
  const message =
    requestType === REQUEST_TYPES.DATA
      ? "Your Data Request Package has been saved!"
      : "Your Biospecimen Request Package has been saved!";
  return {
    alertType: ALERT_TYPES.SUCCESS,
    alertMessage: <h3>{message}</h3>,
  };
};
