import React, { useState } from "react";
import _, { camelCase } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ADMIN_REQUEST_ACTION_TYPES, ALERT_TYPES } from "../../types";
import Alert from "../../alert";
import { scrollToTopOfDiv } from "../../utils/utils";
import PrepopulatedData from "../../../features/annualReport/components/forms/PrepopulatedData";
import AnnualReportForm from "../../../features/annualReport/components/forms/AnnualReport/AnnualReportForm";
import { saveAnnualReportStep } from "../../../features/annualReport/AnnualReportActions";
import { parseBackendErrors } from "../../../features/annualReport/components/forms/AnnualReport/AnnualReportValidation";
import {
  ApproveRequestForm,
  DenyRequestForm,
} from "../../../features/admin/request/components/forms";
import {
  approveAnnualReport,
  changeRequest,
} from "../../../features/admin/AdminActions";

const AdminAnnualReportModal = (props) => {
  const {
    isOpen,
    closeModal,
    type,
    submitFormSync,
    setLoading,
    request,
    data,
  } = props;
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  let title;
  let Form;
  let formType;
  let id;
  let modalDisplayOutcomes;

  const handleSubmit = (values) => {
    setLoading(true);
    const changeType = camelCase(type);
    id = props.data.annualReportWorkflow.id;
    if (type === "ANNUAL_REPORT_EDIT") {
      props
        .saveAnnualReportStep(
          id,
          "annualReport",
          values,
          data.requestId,
          data.reportYear,
        )
        .then(() => {
          setAlertType(ALERT_TYPES.SUCCESS);
          setAlertMessage("Saved your annual report!");
          props.setLoading(false);
          scrollToTopOfDiv("modal-header");
        })
        .catch((error) => {
          const submitError = parseBackendErrors(error);
          const { _error, ...validationErrors } = submitError;
          setAlertType(_error.alertType);
          setAlertMessage(_error.message);
          scrollToTopOfDiv("modal-header");
          props.setLoading(false);
        });
    } else if (type === "ANNUAL_REPORT_APPROVE") {
      props
        .approveAnnualReport(id)
        .then(() => {
          setAlertType(ALERT_TYPES.SUCCESS);
          setAlertMessage("Annual report approved!");
          props.setLoading(false);
          scrollToTopOfDiv("modal-header");
        })
        .catch((error) => {
          const submitError = parseBackendErrors(error);
          const { _error, ...validationErrors } = submitError;
          setAlertType(_error.alertType);
          setAlertMessage(_error.message);
          scrollToTopOfDiv("modal-header");
          props.setLoading(false);
        });
    } else {
      props
        .changeRequest(id, values, changeType, request.type)
        .then(() => {
          setAlertType(ALERT_TYPES.SUCCESS);
          setAlertMessage("Success");
          scrollToTopOfDiv("modal-header");
          setLoading(false);
        })
        .catch(() => {
          setAlertType(ALERT_TYPES.ERROR);
          setAlertMessage("Please correct the errors below");
          scrollToTopOfDiv("modal-header");
          setLoading(false);
        });
    }
  };

  switch (type) {
    case ADMIN_REQUEST_ACTION_TYPES.VIEW:
      title = "Annual Report Summary";
      modalDisplayOutcomes = true;
      break;
    case ADMIN_REQUEST_ACTION_TYPES.ANNUAL_REPORT_EDIT:
      title = "Annual Report Edit";
      modalDisplayOutcomes = false;
      Form = AnnualReportForm;
      formType = "annualReport";
      break;
    case ADMIN_REQUEST_ACTION_TYPES.ANNUAL_REPORT_APPROVE:
      title = "Annual Report Approve";
      modalDisplayOutcomes = true;
      break;
    case ADMIN_REQUEST_ACTION_TYPES.ANNUAL_REPORT_DENY:
      title = "Annual Report Deny";
      modalDisplayOutcomes = true;
      Form = DenyRequestForm;
      formType = "denyRequestForm";
      break;
    default:
      break;
  }

  const initialValues = {
    publications: [{}],
    presentations: [{}],
    patents: [{}],
    significantfindings: [{}],
    otheroutcomes: [{}],
    haspublications: "false",
    haspresentations: "false",
    haspatents: "false",
    hassignificantfindings: "false",
    hasotheroutcomes: "false",
    additionalInformation: null,
  };

  data.outcomes.forEach((outcome) => {
    // TODO IF NOT OUTCOMES DEFAULT TO NO
    let outcomeType = outcome.type.toLowerCase();
    if (outcomeType === "other") outcomeType = "otheroutcome";
    if (outcomeType === "other outcome") outcomeType = "otheroutcome";
    if (outcomeType === "patent ") outcomeType = "patent";
    if (outcomeType === "significant finding")
      outcomeType = "significantfinding";
    if (_.isEmpty(initialValues[`${outcomeType}s`][0]))
      initialValues[`${outcomeType}s`].pop();
    initialValues[`${outcomeType}s`].push(outcome);
    initialValues[`has${outcomeType}s`] = "true";
  });

  if (data.additionalInformation)
    initialValues.additionalInformation = data.additionalInformation;

  return (
    <div>
      <Modal isOpen={isOpen} toggle={closeModal} size='lg' backdrop='static'>
        <ModalHeader id='modal-header' toggle={closeModal} tag='h2'>
          {title}
        </ModalHeader>
        <ModalBody>
          <Alert
            alertType={alertType}
            message={alertMessage}
            allowClose={false}
          />
          {(type === "VIEW" ||
            type === "ANNUAL_REPORT_APPROVE" ||
            type === "ANNUAL_REPORT_DENY") && (
            <div>
              <PrepopulatedData
                data={data}
                displayOutcomes={modalDisplayOutcomes}
              />

              {data.additionalInformation && (
                <div>
                  <h3 className='form-heading-3'>Additional Information</h3>
                  <div>{data.additionalInformation}</div>
                  <hr />
                </div>
              )}
              <div>
                <h3 className='form-heading-3'>Signature</h3>
                <div>{data.signature}</div>
              </div>
              <hr />
            </div>
          )}

          {type !== "VIEW" && type !== "ANNUAL_REPORT_APPROVE" && (
            <Form
              initialValues={
                type === "ANNUAL_REPORT_EDIT" ? initialValues : null
              }
              alert={alert}
              onSubmit={handleSubmit}
              data={data}
              displayFooter={false}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <button
            type='submit'
            className='btn modal-btn modal-btn-blue'
            onClick={closeModal}
          >
            Close
          </button>
          {
            // hide submit button after successful submission
            type !== ADMIN_REQUEST_ACTION_TYPES.VIEW &&
              type !== ADMIN_REQUEST_ACTION_TYPES.ANNUAL_REPORT_APPROVE &&
              alertType !== ALERT_TYPES.SUCCESS && (
                <button
                  type='submit'
                  className='btn modal-btn modal-btn-blue'
                  onClick={() => submitFormSync(formType)}
                >
                  Save
                </button>
              )
          }
          {
            // hide approve button after successful submission
            type == ADMIN_REQUEST_ACTION_TYPES.ANNUAL_REPORT_APPROVE &&
              alertType !== ALERT_TYPES.SUCCESS && (
                <button
                  type='submit'
                  className='btn modal-btn modal-btn-blue'
                  onClick={handleSubmit}
                >
                  Approve
                </button>
              )
          }
        </ModalFooter>
      </Modal>
    </div>
  );
};

AdminAnnualReportModal.propTypes = {
  requestId: PropTypes.number,
  type: PropTypes.string,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  submitFormSync: PropTypes.func,
  setLoading: PropTypes.func,
  data: PropTypes.object.isRequired,
  saveAnnualReportStep: PropTypes.func.isRequired,
  changeRequest: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
};

const actions = { saveAnnualReportStep, approveAnnualReport, changeRequest };
export default connect(null, actions)(AdminAnnualReportModal);
