import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  PublicationForm,
  PresentationForm,
  PatentForm,
  SignificantFindingsForm,
  OtherOutcomesForm,
} from "./OutcomeForms";
import { YellowButton } from "../../../common/buttons";

const PublicationInfoHOC = (props) => {
  const { fields, type } = props;

  let Component = null;
  let label = type;

  if (type === "publication") {
    Component = PublicationForm;
  } else if (type === "presentation") {
    Component = PresentationForm;
  } else if (type === "patent") {
    Component = PatentForm;
  } else if (type === "significantfinding") {
    Component = SignificantFindingsForm;
    label = "Significant Finding";
  } else if (type === "otheroutcome") {
    Component = OtherOutcomesForm;
    label = "Outcome";
  }

  const firstLetter = label.charAt(0).toUpperCase();
  label = firstLetter + label.substring(1);

  // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
  // January - 0, February - 1, etc.
  // const initialDate = new Date(parts[0], parts[1] - 1, parts[2]);

  const maxDate = new Date();
  const minDate = new Date(props.effectiveDate);

  return (
    <div>
      {fields.map((member, index) => (
        <Component
          key={member}
          member={member}
          type={type}
          index={index}
          fields={fields}
          label={label}
          minDate={minDate}
          maxDate={maxDate}
        />
      ))}
      <YellowButton
        type='button'
        onClick={() => fields.push({})}
        label={`Add Another ${label}`}
        align='right'
        ariaLabel={`Add another ${label}. clicking this button will open another ${label} section`}
      />
    </div>
  );
};

PublicationInfoHOC.propTypes = {
  fields: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  effectiveDate: PropTypes.string.isRequired,
};

export default PublicationInfoHOC;
