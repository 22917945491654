import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { renderInstitutionBackendErrors } from "../../form/forms/institution/InstitutionValidation";
import { ALERT_TYPES } from "../../types";
import AdminDivisionForm from "../../form/forms/admin/AdminDivisionForm";
import {
  updateStatus,
  updateLocation,
} from "../../../features/admin/AdminActions";
import Alert from "../../alert";
import { scrollToTopOfDiv } from "../../utils/utils";

const AdminViewDivisionModal = (props) => {
  const {
    isOpen,
    closeModal,
    initialValues,
    statuses,
    institutionId,
    institutionName,
    history,
  } = props;

  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const updateDivisionSequentially = async (values) => {
    try {
      if (initialValues.status !== values.status) {
        await props.updateStatus(values.status, initialValues.id, "division");
      }
      await props.updateLocation(values, initialValues.id, "division");
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = (values) => {
    props.setLoading(true);
    updateDivisionSequentially(values)
      .then(() => {
        setAlertType(ALERT_TYPES.SUCCESS);
        setAlertMessage("Division has been sucessfully updated");
        scrollToTopOfDiv("modal-header");
        props.setLoading(false);
      })
      .catch((resp) => {
        setAlertType(ALERT_TYPES.ERROR);
        setAlertMessage(renderInstitutionBackendErrors(resp));
        scrollToTopOfDiv("modal-header");
        props.setLoading(false);
      });
  };

  const mergeDivision = () => {
    closeModal();
    history.push({
      pathname: `/admin/mergeDivisions/${initialValues.id}`,
      state: {
        institutionId,
        institutionName,
      },
    });
  };

  const handleClose = () => {
    if (alertType === ALERT_TYPES.SUCCESS) {
      props.updateInstitution(institutionId);
    }
    closeModal();
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={closeModal} size='lg' backdrop='static'>
        <ModalHeader id='modal-header' toggle={closeModal} tag='h2'>
          Manage Division
        </ModalHeader>
        <Alert
          alertType={alertType}
          message={alertMessage}
          allowClose={false}
        />
        <ModalBody>
          <AdminDivisionForm
            toggleInfo
            initialValues={initialValues}
            statuses={statuses}
            onSubmit={handleSubmit}
          />
        </ModalBody>
        <ModalFooter>
          <button
            type='button'
            className='btn modal-btn btn-secondary'
            onClick={handleClose}
          >
            Close
          </button>
          <button
            type='button'
            className='dash-btn yellow-btn fs-16'
            onClick={mergeDivision}
          >
            Merge This Division
          </button>
          {alertType !== ALERT_TYPES.SUCCESS && (
            <button
              type='submit'
              className='btn modal-btn modal-btn-blue'
              onClick={() => props.submitFormSync("adminDivisionForm")}
            >
              Submit
            </button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

AdminViewDivisionModal.propTypes = {
  initialValues: PropTypes.object,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  statuses: PropTypes.array,
  submitFormSync: PropTypes.func,
  setLoading: PropTypes.func,
  updateStatus: PropTypes.func,
  updateLocation: PropTypes.func,
  updateInstitution: PropTypes.func,
  institutionId: PropTypes.string,
  institutionName: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

const actions = { updateStatus, updateLocation };
export default connect(null, actions)(AdminViewDivisionModal);
