import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse } from "reactstrap";
import { ClearButton } from "../../buttons";
import { ReactComponent as DownArrowIcon } from "../../../img/icons/arrows/Down.svg";
import { ReactComponent as UpArrowIcon } from "../../../img/icons/arrows/Up.svg";
import "./style.scss";

class CollapseToggle extends Component {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleToggleKeyPress = this.handleToggleKeyPress.bind(this);
    this.state = {
      open: this.props.isOpen,
    };
  }

  handleToggle() {
    this.props.onClick();
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  handleToggleKeyPress(e) {
    if (e.keyCode === 13) {
      this.setState((prevState) => ({ open: !prevState.open }));
    }
  }

  render() {
    const { labelClose, labelOpen, iconStyle } = this.props;
    const width = iconStyle ? iconStyle.width : "16px";
    const height = iconStyle ? iconStyle.height : "16px";
    const collapseHeadClass = `dash-collapse ${this.props.collapseHeadClass}`;
    const Icon = this.state.open ? UpArrowIcon : DownArrowIcon;

    return (
      <div className={this.props.collapseGroupClass}>
        <ClearButton
          className={collapseHeadClass}
          onClick={this.handleToggle}
          expanded={this.state.open}
          onKeyPress={this.handleToggleKeyPress}
          icon={
            this.props.hasIcon ? (
              <Icon
                width={width}
                height={height}
                title={this.state.open ? labelOpen : labelClose}
              />
            ) : null
          }
          label={this.state.open ? labelOpen : labelClose}
          textColor={this.props.textColor}
          type={this.props.type}
          noPadding
        />
        <Collapse isOpen={this.state.open} className={this.props.levelClass}>
          <div>{this.props.children}</div>
        </Collapse>
      </div>
    );
  }
}

CollapseToggle.propTypes = {
  labelClose: PropTypes.string,
  labelOpen: PropTypes.string,
  hasIcon: PropTypes.bool,
  collapseGroupClass: PropTypes.string, // overall collapse component class styling
  collapseHeadClass: PropTypes.string, // styling for dropdown trigger DOM
  levelClass: PropTypes.string,
  children: PropTypes.node,
  textColor: PropTypes.string,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  iconStyle: PropTypes.object,
  type: PropTypes.string,
};

CollapseToggle.defaultProps = {
  labelOpen: "",
  labelClose: "",
  collapseHeadClass: null,
  collapseGroupClass: null,
  levelClass: null,
  hasIcon: true,
  textColor: "#000000",
  isOpen: false,
  type: "submit",
};

export default CollapseToggle;
