import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import "../Button/style.scss";
import { Button } from "..";

const BlueButton = (props) => {
  const combinedClasses = `btn btn-blue ${props.className}`;

  return (
    <Button
      id={props.id}
      data-testid={props.testId}
      className={combinedClasses}
      type={props.type}
      name={props.name}
      onClick={props.onClick}
      onKeyPress={props.onKeyPress}
      aria-label={props.ariaLabel}
      icon={props.icon}
      label={props.label}
      href={props.href}
      style={props.styles}
    />
  );
};

BlueButton.propTypes = {
  id: PropTypes.number,
  testId: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.node,
  name: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  styles: PropTypes.object,
  href: PropTypes.string,
};

BlueButton.defaultTypes = {
  className: "",
  iconPosition: "left",
  styles: null,
};

export default BlueButton;
