import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Modal } from "reactstrap";
import { ReactComponent as TrashIcon } from "../../../img/icons/TrashIcon.svg";
import { ClearButton, YellowButton } from "../../buttons";
import { DropdownSelect, InputBox } from "../../inputs";
import CreateLocationModal from "../modals/CreateLocationModal";
import { parseInstitutionBackendErrors } from "../../form/forms/institution/InstitutionValidation";
import * as ResearcherInfoWorkspaceValidator from "./forms/ResearcherInfoWorkspaceValidator";
import Alert from "../../alert";
import { scrollToTopOfDiv } from "../../utils/utils";
import { ALERT_TYPES } from "../../types";

// only allow user to enter one character for middle initial
export const parseMiddleInitial = (value) => {
  if (value.match(/^[a-zA-Z]{0,1}$/)) {
    return value;
  }
  return undefined;
};

// renders the input fields (first name, last name, etc) for one researcher
const ResearcherInfoWorkspace = (props) => {
  const {
    member,
    index,
    fields,
    researcher,
    institutions,
    titles,
    modalProps,
    removeForm,
    addNewMember,
    adjustedIndex,
    researchers,
  } = props;

  const [alert, setAlert] = useState({ alertType: null, alertMessage: null });

  const resetAlert = () => {
    if (alert.alertType || alert.alertMessage) {
      setAlert({
        alertType: null,
        alertMessage: null,
      });
    }
  };

  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);

  const { createInstitution, dataType } = modalProps;

  const sectionHeader = adjustedIndex
    ? `${researcher} ${adjustedIndex + 1}`
    : `${researcher} ${index + 1}`;

  const handleAdd = (values, allValues, props) => {
    const newMember = values[_.lowerCase(researcher)][props.index];

    if (
      !_.find(
        researchers,
        (user) => user.emailAddress === newMember.emailAddress,
      )
    ) {
      addNewMember(props.index, newMember);
    } else {
      setAlert({
        alertType: ALERT_TYPES.WARNING,
        alertMessage: `${researcher} has already been added to research team`,
      });
      scrollToTopOfDiv(props.member);
    }
  };

  const renderRemoveBtn = () => {
    if (fields.length > 1) {
      return (
        <ClearButton
          type='button'
          onClick={() => removeForm(index)}
          onKeyPress={() => removeForm(index)}
          label={`Remove ${researcher}`}
          align='right'
          icon={<TrashIcon />}
        />
      );
    }
    return null;
  };

  const toggleLocationModal = () => {
    setIsLocationModalOpen(!isLocationModalOpen);
  };

  const onModalLocationSubmit = (locationValues) =>
    createInstitution(locationValues, dataType)
      .then(() => setIsLocationModalOpen(!isLocationModalOpen))
      .catch((error) => {
        const submitError = parseInstitutionBackendErrors(error);
        throw new SubmissionError(submitError);
      });

  const renderInstitutions = () => {
    const institutionArr = [];

    _.map(institutions.NIH, ({ name }) => {
      institutionArr.push({ value: name, item: name });
    });

    _.map(institutions.Other, ({ name }) => {
      institutionArr.push({ value: name, item: name });
    });

    institutionArr.sort((a, b) => a.value.localeCompare(b.value));
    return institutionArr;
  };

  const renderTitles = () => {
    const titleArr = [];
    _.map(titles, (title) => {
      titleArr.push({ value: title, item: title });
    });
    return titleArr;
  };

  const renderProjectRole = () => {
    if (researcher === "Associate") {
      return (
        <div className='col-12'>
          <div className='form-group'>
            <Field
              id='projectRole'
              name={`${member}.projectRole`}
              component={InputBox}
              label='Project Role'
              placeholder="Please enter Associate's Project Role"
              validate={[
                ResearcherInfoWorkspaceValidator.requiredProjectRole,
                ResearcherInfoWorkspaceValidator.noEmptyStringProjectRole,
                ResearcherInfoWorkspaceValidator.projectRoleExceedsMaxLength,
              ]}
              required
            />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <hr />
      <div className='row'>
        <div className='col align-self-end'>{renderRemoveBtn()}</div>
      </div>
      <h3 id={member}>{sectionHeader}</h3>
      <Alert
        alertType={alert.alertType}
        message={alert.alertMessage}
        onClose={resetAlert}
      />

      <form onSubmit={props.handleSubmit(handleAdd)}>
        <div className='form-group'>
          <div className='row'>
            <div className='col-12 col-sm-12 col-md'>
              <Field
                component={DropdownSelect}
                name={`${member}.institutionName`}
                label={`${researcher}'s Institution`}
                placeholder={`Please select ${researcher}'s Institution`}
                options={renderInstitutions()}
                validate={ResearcherInfoWorkspaceValidator.requiredInstitution}
                required
              />
              <p>
                Unable to find the {researcher}&apos;s institution in the
                dropdown list?&nbsp;
                <button
                  type='button'
                  className='open-modal-btn'
                  onClick={toggleLocationModal}
                >
                  Click here to add institution
                </button>
              </p>
              <Modal isOpen={isLocationModalOpen} toggle={toggleLocationModal}>
                <CreateLocationModal
                  isOpen={isLocationModalOpen}
                  modalSubmit={onModalLocationSubmit}
                  closeModal={toggleLocationModal}
                  {...modalProps}
                />
              </Modal>
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='row'>
            <div className='col-12 col-sm-12 col-md'>
              <div className='form-group'>
                <Field
                  component={DropdownSelect}
                  label='Title'
                  placeholder='Title'
                  ariaLabel={`Select ${researcher}'s Title`}
                  name={`${member}.title`}
                  options={renderTitles()}
                />
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-4'>
              <div className='form-group'>
                <Field
                  name={`${member}.firstName`}
                  component={InputBox}
                  label='First Name'
                  placeholder='First Name'
                  ariaLabel={`Enter ${researcher}'s First Name`}
                  validate={[
                    ResearcherInfoWorkspaceValidator.firstNameExceedsMaxLength,
                    ResearcherInfoWorkspaceValidator.requiredFirstName,
                    ResearcherInfoWorkspaceValidator.noEmptyStringFirstName,
                  ]}
                  required
                />
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-4'>
              <div className='form-group'>
                <Field
                  name={`${member}.lastName`}
                  component={InputBox}
                  label='Last Name'
                  placeholder='Last Name'
                  ariaLabel={`Enter ${researcher}'s Last Name`}
                  validate={[
                    ResearcherInfoWorkspaceValidator.lastNameExceedsMaxLength,
                    ResearcherInfoWorkspaceValidator.requiredLastName,
                    ResearcherInfoWorkspaceValidator.noEmptyStringLastName,
                  ]}
                  required
                />
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md'>
              <div className='form-group'>
                <Field
                  name={`${member}.middleInitial`}
                  component={InputBox}
                  label='M.I.'
                  placeholder='M.I.'
                  ariaLabel={`Enter ${researcher}'s Middle Initial`}
                  parse={parseMiddleInitial}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='form-group'>
                <Field
                  name={`${member}.jobTitle`}
                  component={InputBox}
                  label='Job Title/Position'
                  placeholder={`Please enter ${researcher}'s Job Title/Position`}
                  validate={[
                    ResearcherInfoWorkspaceValidator.jobTitleExceedsMaxLength,
                    ResearcherInfoWorkspaceValidator.requiredJobTitle,
                    ResearcherInfoWorkspaceValidator.noEmptyStringJobTitle,
                  ]}
                  required
                />
              </div>
            </div>
            {renderProjectRole()}
            <div className='col-12'>
              <Field
                type='email'
                name={`${member}.emailAddress`}
                component={InputBox}
                label='Email'
                placeholder={`Please enter ${researcher}'s Email`}
                validate={[
                  ResearcherInfoWorkspaceValidator.emailExceedsMaxLength,
                  ResearcherInfoWorkspaceValidator.requiredEmail,
                  ResearcherInfoWorkspaceValidator.noEmptyStringEmailAddress,
                  ResearcherInfoWorkspaceValidator.validateEmailFormat,
                ]}
                required
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <YellowButton
                type='submit'
                className='float-md-right'
                // onClick={() => handleAdd(index, member)}
                label={`Add ${researcher} to Research Team`}
                align='right'
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

ResearcherInfoWorkspace.propTypes = {
  member: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  researcher: PropTypes.string.isRequired,
  institutions: PropTypes.shape({
    NIH: PropTypes.arrayOf(PropTypes.object),
    Other: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  modalProps: PropTypes.object,
  addNewMember: PropTypes.func.isRequired,
  removeForm: PropTypes.func,
  adjustedIndex: PropTypes.number,
  values: PropTypes.array,
  researchers: PropTypes.array,
  handleSubmit: PropTypes.func,
};

export default reduxForm({})(ResearcherInfoWorkspace);
