import React from "react";

const DataRequestGuidelines = () => (
  <>
    <h3>Getting Access To Study Items</h3>
    <p>
      You will have access to all items you requested once your request has been
      approved. Access is typically granted for three years.
    </p>
    <p>Request access in three quick steps:</p>
    <div className='col-sm-12'>
      <ol className='horizontal'>
        <li>Complete online data request form</li>
        <li>Download data request package(s) and obtain signatures.</li>
        <li>Upload and submit data request package(s).</li>
      </ol>
    </div>
  </>
);

export default DataRequestGuidelines;
