import _ from "lodash";
import React from "react";
import { reduxForm, Field } from "redux-form";
import PropTypes from "prop-types";
import { DropdownSelect, RadioButton } from "../../inputs";

const ReuseRequestForm = ({ usePreviousRequest, formSupport }) => {
  const renderPreviousRequests = (prevRequests) => {
    const prevRequestsArr = [];
    _.map(prevRequests, (prevRequest) => {
      prevRequestsArr.push({ value: prevRequest.id, item: prevRequest.name });
    });
    return prevRequestsArr;
  };

  return (
    <form>
      <section>
        <div
          className='form-group row align-items-center justify-content-center inline-checkbox-row'
          role='radiogroup'
        >
          <div className='col-sm-2'>
            <Field
              label='Yes'
              ariaLabel='Would you like to use the same information from your previous DASH request(s) for data and/or biospecimens? You may edit the fields once they are pre-populated. The following sections in the request process will be pre-populated: General, Study Information, Research Team (for data requests only) - Yes. Selection of yes will expose dropdown list of previous requests (if any).'
              value='true'
              type='radio'
              component={RadioButton}
              name='usePreviousRequest'
            />
          </div>
          <div className='col-sm-2'>
            <Field
              label='No'
              ariaLabel='Would you like to use the same information from your previous DASH request(s) for data and/or biospecimens? You may edit the fields once they are pre-populated. The following sections in the request process will be pre-populated: General, Study Information, Research Team (for data requests only) - No'
              value='false'
              type='radio'
              component={RadioButton}
              name='usePreviousRequest'
            />
          </div>
        </div>
      </section>
      <br />
      {usePreviousRequest === "true" && (
        <section>
          <div className='form-group'>
            <Field
              component={DropdownSelect}
              label='Please choose one from this list of your pending, approved, or denied previous requests.'
              id='selecting_a_request'
              placeholder='Select a Request...'
              ariaLabel='Please choose one from this list of your pending, approved, or denied previous requests.'
              name='selectedRequest'
              options={renderPreviousRequests(formSupport)}
              labelClasses='reuse-request-modal-dropdown'
            />
          </div>
        </section>
      )}
    </form>
  );
};

ReuseRequestForm.propTypes = {
  usePreviousRequest: PropTypes.string,
  formSupport: PropTypes.arrayOf(PropTypes.object),
};

export default reduxForm({
  form: "reuseRequestForm", // a unique name for the form
})(ReuseRequestForm);
