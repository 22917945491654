import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { Textarea, DropdownSelect } from "../../../../../common/inputs";
import RequestUsersSelect from "./RequestUsersSelect";
import { validateTerminateRequestForm } from "./AdminRequestValidator";

export const TerminateRequestForm = (props) => (
  <form onSubmit={props.handleSubmit}>
    <hr />
    <br />
    <div className='col-12'>
      Are you sure you want to terminate this request? If so, please select a
      reason for termination, recipients for the termination email, and enter an
      explanation of termination.
    </div>
    <div className='row p-15'>
      <div className='col-12 col-sm-12 col-md'>
        <Field
          component={DropdownSelect}
          id='terminationReason'
          label='Termination Reason'
          placeholder='Termination Reason'
          name='terminationReason'
          required
          options={props.reasons.map((reason) => ({
            value: reason,
            item: reason,
          }))}
        />
      </div>
    </div>
    <div className='col-12 col-sm-12 col-md'>
      <RequestUsersSelect users={props.requestUsers} />
    </div>
    <div className='col-12 col-sm-12 col-md'>
      <Field
        component={Textarea}
        id='terminationExplanation'
        name='terminationExplanation'
        placeholder='Please enter explanation for termination'
        label='Termination Explanation'
        required
      />
    </div>
  </form>
);

TerminateRequestForm.propTypes = {
  handleSubmit: PropTypes.func,
  requestUsers: PropTypes.array,
  reasons: PropTypes.array,
};

export default reduxForm({
  form: "terminateRequestForm",
  warn: validateTerminateRequestForm,
})(TerminateRequestForm);
