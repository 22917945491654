import { saveAs } from "file-saver";

export const scrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

export const scrollToTopOfDiv = (id) => {
  const element = document.getElementById(id);
  element.scrollIntoView(true);
};

/**
 * Returns true if a string ends with the given substring
 * @param {string} string the string to check
 * @param {string} substr the given sub string
 */
export const endsWith = (string, substr) =>
  string.indexOf(substr) > -1 &&
  string.indexOf(substr) === string.length - substr.length;

/**
 * Returns the first element in the array that satisfies the provided testing function
 * @param {*} array the array to serch
 * @param {*} callback the function to execute on each value in the array, this function takes three
 * arguments:
 *
 * 1) the current element being processed
 *
 * 2) the current index
 *
 * 3) the array itself
 */
export const find = (array, callback) => {
  for (let i = 0; i < array.length; i += 1) {
    if (callback(array[i], i, array)) {
      return array[i];
    }
  }
  return null;
};

// returns a new string which capitalizes the given string
export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

// number of milliseconds in a hour, day, week, month, and year
export const TIME = {
  DAY: 86400000, // (24 hours) * (60 minutes/hour) * (60 seconds/minute) * (1000 milliseconds/second)
  WEEK: 604800000, // (7 days) * (24 hours/day) * (60 minutes/hour) * (60 seconds/minute) * (1000 milliseconds/second)
  MONTH: 2592000000, // (30 days) * (24 hours/day) * (60 minutes/hour) * (60 seconds/minute) * (1000 milliseconds/second)
  YEAR: 31536000000, // (365 days) * (24 hours/day) * (60 minutes/hour) * (60 seconds/minute) * (1000 milliseconds/second)
};

// earliest occurance of any data in DASH
export const DASH_START = "09-01-2015";

export const KEY_CODE = {
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  SPACE: 32,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
};

export const DATE_FORMAT = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

// receives a date and applies the timezone difference between the date and the user's current timezone
export const getTimezoneOffsetDate = (date) => {
  const dateUTC = new Date(date);
  const timezoneOffset = dateUTC.getTimezoneOffset() * 60000; // the time zone difference, in milliseconds, from current locale to UTC
  const time = dateUTC.getTime() + timezoneOffset; // the time in milliseconds after applying the timezone offset
  return new Date(time);
};

/**
 * Receives a date in either a string format or number of milliseconds since January 1, 1970, and returns
 * the date in the format 'Month Day, Year'
 *
 * @param {*} date The date to display
 * @param {bool} offset True if you want to apply the timezone difference between the user's current timezone and UTC when displaying
 * the date. For example if the date is '2008-08-08' and the user is in EST, the display date will be 'August 7, 2008'. By applying the
 * offset, the display date will be 'August 8, 2008'.
 */
export const getDisplayDate = (date, offset, monthAndYearOnly) => {
  if (date === null || date === undefined || date === "" || date === "N/A") {
    return "N/A";
  }
  const dateObj = offset ? getTimezoneOffsetDate(date) : new Date(date);
  const dateFormat = monthAndYearOnly
    ? { year: "numeric", month: "long" }
    : DATE_FORMAT;
  return dateObj.toLocaleDateString("en-US", dateFormat);
};

export const getMonthDifference = (date) => {
  // Hardcoded to display expiration warning
  const currDate = new Date();
  return (
    date.getMonth() -
    currDate.getMonth() +
    12 * (date.getFullYear() - currDate.getFullYear())
  );
};

export const getNumericDateValues = (date, offset) => {
  if (date === null || date === undefined || date === "" || date === "N/A") {
    return "N/A";
  }
  const dateObj = offset ? getTimezoneOffsetDate(date) : new Date(date);

  return {
    year: dateObj.getFullYear(),
    month: dateObj.getMonth(),
    day: dateObj.getDay(),
  };
};

/**
 * Receive a Fetch Response and return JSON object containing the status and body of the Response
 * @param {object} resp Response object
 * @returns {object} JSON object containing the Response status and json body
 */
export const parseFetchResponse = (resp) =>
  resp.json().then((json) => ({
    status: resp.status,
    json,
  }));

/**
 * Receives javascript Date object and converts it to string in the format yyyy-mm-dd
 * @param {Date} date the date to convert
 */
export const dateToString = (date) =>
  date && date.toISOString().substring(0, 10);

const sortHelper = (a, b) => {
  if (!a && b) {
    return -1;
  } else if (a && !b) {
    return 1;
  }
  return 0;
};

export const sortAscNumerically = (a, b) => {
  if (a && b) {
    return a - b;
  }
  return sortHelper(a, b);
};

export const sortDescNumerically = (a, b) => {
  if (a && b) {
    return -(a - b);
  }
  return -sortHelper(a, b);
};

export const sortAlphabetically = (a, b) => {
  if (a && b) {
    a = String(a);
    b = String(b);
    return a.localeCompare(b);
  }
  return sortHelper(a, b);
};

/**
 * Makes api call to download a file and save it to the user's machine
 * @param {string} url the file url
 * @param {object} init the fetch parameters
 */
export const downloadFile = (url, init) => async () => {
  try {
    const response = await fetch(url, init);
    if (response.ok) {
      const file = await response.blob();
      const contentDisposition = response.headers.get("content-disposition");
      const fileName = contentDisposition.match(/filename=(.*)/)[1];
      saveAs(file, fileName);
    } else {
      const error = await response.json();
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

/**
 * Downloads file contents from URL and serves back as a blob
 * @param {string} url - the file url
 * @returns {Blob} file the file fetched
 */
export const getFileContents = async (url) => {
  try {
    const response = await fetch(url);
    if (response.ok) {
      const fileContent = await response.blob();
      const file = new Blob([fileContent], { type: "application/pdf" });
      return file;
    }
    const error = await response.json();
    throw error;
  } catch (error) {
    throw error;
  }
};

/**
 * Opens PDF file contents in new tab
 * @param {string} url - the file url
 */
export const openPdfFile = async (url) => {
  try {
    const response = await fetch(url);
    if (response.ok) {
      const fileContent = await response.blob();
      const file = new Blob([fileContent], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    } else {
      const error = await response.json();
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

/**
 * Grabs PDF file URL and returns it to user
 * @param {string} url - the file url
 */
export const getPdfUrl = async (url) => {
  try {
    const response = await fetch(url);
    if (response.ok) {
      const fileContent = await response.blob();
      const file = new Blob([fileContent], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      return fileURL;
    }
    const error = await response.json();
    throw error;
  } catch (error) {
    throw error;
  }
};

// Range of colors. Go to https://www.tutorialrepublic.com/html-reference/html-color-picker.php
// if you need to generate more colors.
export const colorGradient = [
  "#173662",
  "#035494",
  "#2c81bf",
  "#69add7",
  "#9ccae2",
  "#f0ad4e",
  "#feca1c",
  "#ffdd7d",
  "#4b783b",
  "#4d8a3f",
  "#4f9e45",
  "#7cbf6f",
  "#9ed18e",
  "#000000",
  "#3d3d3d",
  "#616161",
  "#848484",
  "#a7a7a7",
  "#330e46",
  "#4d266e",
  "#5d3888",
  "#6c489d",
  "#7f5ba6",
  "#9375b4",
  "#90223A",
  "#b52b4a",
  "#C3556D",
  "#DA95A4",
  "#E8BFC8",
  "#E55B00",
  "#FF6600",
  "#FF8433",
  "#FFA366",
  "#FFC199",
  "#FDCECE",
  "#DC0909",
  "#7A0505",
  "#490303",
  "#C9E1B2",
  "#93C466",
  "#4C9E00",
  "#356E00",
  "#A5EDCC",
  "#81E5B8",
  "#43C58A",
  "#2D835C",
  "#414678",
  "#6368A0",
  "#979AC0",
  "#B9BCD5",
];
