import { GET_VERSION_NUMBER } from "../types";

export const getVersion = () => {
  const url = "/versioninfo";
  const regex = /((\d+\.)(\d+\.)(\d+))|(\?\?\?)/;
  return async (dispatch) => {
    try {
      const response = await fetch(url);
      const data = await response.text();
      if (data.match(regex)) {
        dispatch({
          type: GET_VERSION_NUMBER,
          payload: data.match(regex)[0],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const resetState = () => ({});
