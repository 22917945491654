import React from "react";
import { Link } from "react-router-dom";
import {
  fieldIsUndefined,
  validateForm,
  setOptions,
  textIsEmptyString,
  renderValidationErrorMessage,
} from "../../common/utils";
import { FIELD_TYPES } from "../../common/types";
import {
  userFields,
  passwordConfirmField,
  validatePassword,
} from "../../common/form/forms/user/UserValidation";
import { institutionDivisionFields } from "../../common/form/forms/institutionDivision/InstitutionDivisionValidation";

/* ~~~~~~~~~~~~~~~~~~~~~~~~ Handle backend erros ~~~~~~~~~~~~~~~~~~~~~~~~~~ */
const parseUpdateProfileErrors = (backendErrors, options) => {
  backendErrors.validationErrors.forEach(({ field }) => {
    if (field.indexOf("title") > -1) {
      setOptions(options, "Title", 1);
    } else if (field.indexOf("firstName") > -1) {
      setOptions(options, "First name", 2);
    } else if (field.indexOf("lastName") > -1) {
      setOptions(options, "Last Name", 3);
    } else if (field.indexOf("middleInitial") > -1) {
      setOptions(options, "Middle Initial", 4);
    } else if (field.indexOf("occupation") > -1) {
      setOptions(options, "Occupation", 5);
    } else if (field.indexOf("phone") > -1) {
      setOptions(options, "Phone", 6);
    } else if (field.indexOf("division") > -1) {
      setOptions(options, "Division", 7);
    }
  });
};

const parseChangePasswordErrors = (backendErrors, options) => {
  backendErrors.validationErrors.forEach(({ field }) => {
    if (field === "currentPassword") {
      setOptions(options, "Current Password", 0);
    } else if (field === "password") {
      setOptions(options, "New Password", 1);
    } else if (field === "passwordConfirm") {
      setOptions(options, "Confirm New Password", 2);
    }
  });
};

export const parseBackendErrors = (step, backendErrors) => {
  const message = "Please correct the errors in the below field(s):";
  const options = [];
  if (backendErrors.validationErrors) {
    switch (step) {
      case "profile":
        parseUpdateProfileErrors(backendErrors, options);
        break;
      case "password":
        parseChangePasswordErrors(backendErrors, options);
        break;
      default:
        break;
    }
    return renderValidationErrorMessage(message, options);
  }
  if (backendErrors.message) {
    return renderValidationErrorMessage(backendErrors.message);
  }
  return (
    <p>
      {" "}
      There was an error updating your user info. If the problem persists please
      contact the site administrator using the
      <Link to='/feedback'>feedback</Link> feature or email us at
      <a href='mailto:supportdash@mail.nih.gov'>SupportDASH@mail.nih.gov</a>
    </p>
  );
};

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~   PROFILE VALIDATION ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
const profileFields = {
  ...userFields,
  division: {
    fieldType: FIELD_TYPES.FORM_SECTION,
    shouldValidate: () => true,
    fields: {
      ...institutionDivisionFields("division"),
    },
  },
};

export const validateProfileForm = (values) =>
  validateForm(values, profileFields);

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ PASSWORD VALIDATION ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
const passwordFields = {
  currentPassword: [
    fieldIsUndefined(null, "Please enter your current password"),
    textIsEmptyString(null, "Your current password may not be blank"),
  ],
  ...passwordConfirmField,
};

export const validatePasswordForm = (values) => {
  const errors = validateForm(values, passwordFields);
  const passwordType = "new password";
  const password = values.password || "";
  errors.password = validatePassword(passwordType, password);
  return errors;
};
