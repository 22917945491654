import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import Alert from "../../../../common/alert";
import { scrollToTop } from "../../../../common/utils";
import FormFooter from "../../../../common/form/components/FormFooter";
import CollapseToggle from "../../../../common/other/CollapseToggle";
import OutcomeSelection from "../OutcomeSelection";
import UserPrettyPrint from "../../../../common/form/forms/user/UserPrettyPrint";
import OutcomeData from "./OutcomeData";

const PrepopulatedData = (props) => {
  const { data, displayOutcomes } = props;

  const options = { year: "numeric", month: "long", day: "numeric" };
  const effectiveDate = new Date(data.effectiveDate).toLocaleDateString(
    undefined,
    options,
  );
  const expirationDate = new Date(data.expirationDate).toLocaleDateString(
    undefined,
    options,
  );

  /* =========== DUA INFORMATION =========== */
  const duaInformation = (
    <div id='study-information'>
      <h3 className='form-heading-3'>DUA Information</h3>
      <div className='row'>
        <div className='col-xs-12 col-sm-3 py-2'>
          <b>Request Name</b>
        </div>
        <div className='col-xs-12 col-sm-9 py-2'>{data.general.name}</div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-3 py-2'>
          <b>Project/Study Title</b>
        </div>
        <div className='col-xs-12 col-sm-9 py-2'>
          {data.studyInformation.projectTitle}
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-3 py-2'>
          <b>DASH Requested Study</b>
        </div>
        <div className='col-xs-12 col-sm-9 py-2'>{data.studyName}</div>
      </div>
      <div className='row'>
        <div className='col-sm-3 py-2'>
          <b>DUA Effective Date</b>
        </div>
        <div className='col-sm-3 py-2'>{effectiveDate}</div>
        <div className='col-sm-3 py-2'>
          <b>DUA Expiration Date</b>
        </div>
        <div className='col-sm-3 py-2'>{expirationDate}</div>
      </div>
    </div>
  );
  /* =========== DATA RECIPIENT INFORMATION =========== */
  const dataRecipientInformation = (
    <div id='study-information'>
      <h3 className='form-heading-3'>Data Recipient Information</h3>
      <div className='row'>
        <div className='col-xs-12 col-sm-3 py-2'>
          <b>Name</b>
        </div>
        <div className='col-xs-12 col-sm-9 py-2'>
          {data.studyInformation.principalInvestigator.firstName}{" "}
          {data.studyInformation.principalInvestigator.lastName}
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-3 py-2'>
          <b>Job Position/Job Title</b>
        </div>
        <div className='col-xs-12 col-sm-9 py-2'>
          {data.studyInformation.principalInvestigator.occupation}{" "}
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-3 py-2'>
          <b>Institution</b>
        </div>
        <div className='col-xs-12 col-sm-9 py-2'>
          {
            data.studyInformation.principalInvestigator.institutionDivision
              .institution.name
          }
        </div>
      </div>
      <div className='row'>
        <div
          className='col-xs-12 col-sm-3 py-2'
          style={{ "word-wrap": "break-word" }}
        >
          <b>Division/Center/Branch</b>
        </div>
        <div className='col-xs-12 col-sm-9 py-2'>
          {data.studyInformation.principalInvestigator.institutionDivision.name}
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-3 py-2'>
          <b>Email Address</b>
        </div>
        <div className='col-xs-12 col-sm-9 py-2'>
          {data.studyInformation.principalInvestigator.emailAddress}
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-3 py-2'>
          <b>Phone Number</b>
        </div>
        <div className='col-xs-12 col-sm-9 py-2'>
          {data.studyInformation.principalInvestigator.phone}
        </div>
      </div>
    </div>
  );
  /* =========== REQUESTOR INFORMATION =========== */
  const requestorInformation = (
    <div id='general'>
      <h3 className='form-heading-3'>Requester Information</h3>
      <UserPrettyPrint user={data.general.requestor} />
    </div>
  );
  /* =========== CURRENT DUA RESEARCH PLAN INFORMATION =========== */
  const resarchPlanInformation = (
    <div id='study-information'>
      <h3 className='form-heading-3'>Research Plan Information</h3>
      <div className='row'>
        <div className='col-xs-12 col-sm-3 py-2'>
          <b>Request Project Title</b>
        </div>
        <div className='col-xs-12 col-sm-9 py-2'>
          {data.studyInformation.projectTitle}
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-3 py-2'>
          <b>Research Plan</b>
        </div>
        <div className='col-xs-12 col-sm-9 py-2'>
          {data.studyInformation.projectDescription}
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-3 py-2'>
          <b>Design and Analysis Plan</b>
        </div>
        <div className='col-xs-12 col-sm-9 py-2'>
          {data.studyInformation.designAndAnalysisPlan}
        </div>
      </div>
    </div>
  );
  /* =========== CURRENT DUA RESEARCH TEAM INFORMATION =========== */

  const researchTeam = { affiliates: "", associates: "", collaborators: "" };
  const memberTypes = Object.keys(researchTeam);
  memberTypes.forEach((memberType) => {
    data.researchTeam[memberType].forEach((member) => {
      researchTeam[memberType] += `${member.firstName} ${member.lastName}, `;
    });
    researchTeam[memberType] = researchTeam[memberType].substr(
      0,
      researchTeam[memberType].length - 2,
    );
  });

  const researchTeamInformation = (
    <div id='study-information'>
      <h3 className='form-heading-3'>Current Research Team Information</h3>
      <div className='row'>
        <div className='col-xs-12 col-sm-3 py-2'>
          <b>Affiliates</b>
        </div>
        <div className='col-xs-12 col-sm-9 py-2'>{researchTeam.affiliates}</div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-3 py-2'>
          <b>Associates</b>
        </div>
        <div className='col-xs-12 col-sm-9 py-2'>{researchTeam.associates}</div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-sm-3 py-2'>
          <b>Collaborators</b>
        </div>
        <div className='col-xs-12 col-sm-9 py-2'>
          {researchTeam.collaborators}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div>
        {duaInformation}
        {dataRecipientInformation}
        {requestorInformation}
        {resarchPlanInformation}
        {researchTeamInformation}
      </div>
      <div>
        {displayOutcomes && ( // Prop boolean to display already submitted outcomes for review and submit page and admin page
          <div>
            <h2 className='form-heading-1'>OUTPUTS AND OUTCOMES INFORMATION</h2>
            <hr />
            <OutcomeData data={data} />
            <hr />
          </div>
        )}
      </div>
    </div>
  );
};

PrepopulatedData.propTypes = {
  data: PropTypes.object,
  displayOutcomes: PropTypes.bool.isRequired,
};

export default PrepopulatedData;
