import React from "react";
import PropTypes from "prop-types";
import { ModalHeader, ModalBody, ModalFooter, Modal } from "reactstrap";

export const ConfirmationModal = (props) => {
  const { closeConfirmationModal, studyName, isOpen, onSubmit } = props;

  return (
    <Modal isOpen={isOpen} toggle={closeConfirmationModal}>
      <ModalHeader toggle={closeConfirmationModal} tag='h2'>
        Confirm Modifications for {studyName} Research Team
      </ModalHeader>
      <ModalBody>
        <p>
          Any deleted research team members will no longer have access to the
          study data and added members will be granted access.
        </p>

        <p>
          Are you sure you would like to continue? Confirm modifications made by
          clicking submit below.
        </p>
      </ModalBody>
      <ModalFooter>
        <button
          type='button'
          className='btn modal-btn btn-seconday'
          onClick={closeConfirmationModal}
        >
          Cancel
        </button>
        <button
          type='submit'
          className='btn modal-btn modal-btn-blue'
          onClick={onSubmit}
        >
          Confirm
        </button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  closeConfirmationModal: PropTypes.func.isRequired,
  studyName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ConfirmationModal;
