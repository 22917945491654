import { MODAL_OPEN, MODAL_CLOSE } from "../types";

export const initialState = {
  isOpen: false, // true to open the modal, false to close the modal
  modalType: null, // the type of modal to open
  modalProps: {}, // props to pass to the open modal
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        isOpen: true,
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
      };
    case MODAL_CLOSE:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};
