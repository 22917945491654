import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import CustomInstitutionDivision from "./CustomInstitutionDivision";
import SelectInstitutionDivision from "./SelectInstitutionDivision";

// This component allows the user to select their division or create a new one
const SelectDivision = (props) => {
  const {
    change,
    formName,
    countries,
    states,
    divisions,
    institutions,
    institution,
    institutionDivision,
    initialDivisionId,
    institutionTypes,
    isNIH,
    isRAS,
    disable,
    disableCreateNew,
  } = props;

  if (institution && institution.createNew) {
    // if the user is creating a new institution, then they must create a new division
    return (
      <div className='row mt-30'>
        <CustomInstitutionDivision
          fieldName='division'
          fieldLabel='Division'
          formName={formName}
          change={change}
          countries={countries}
          states={states}
          selectedInstitution={
            formName === "registration" ? institution : institutionDivision
          }
          institutionTypes={institutionTypes}
        />
      </div>
    );
  } else if (institution && institution.id && divisions) {
    // if the user has selected an institution from the dropdown, then allow them to select a division or create a new division
    const selectedInstitution = !Array.isArray(institutions)
      ? institution
      : institutions.find((inst) => inst.id === parseInt(institution.id, 10));
    return (
      <SelectInstitutionDivision
        fieldName='division'
        fieldLabel='Division'
        formName={formName}
        change={change}
        countries={countries}
        states={states}
        isRAS={isRAS}
        options={divisions.map(({ id, name }) => ({ value: id, item: name }))}
        selectedInstitution={
          formName === "registration"
            ? selectedInstitution
            : institutionDivision
        }
        initialId={initialDivisionId}
        institutionTypes={institutionTypes}
        isNIH={isNIH}
        disable={disable}
        rasInstitution={props.rasInstitution}
        disableCreateNew={disableCreateNew}
      />
    );
  }
  return null;
};

SelectDivision.propTypes = {
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.string).isRequired,
  states: PropTypes.arrayOf(PropTypes.string).isRequired,
  divisions: PropTypes.array,
  institutions: PropTypes.array.isRequired,
  institution: PropTypes.object,
  initialDivisionId: PropTypes.number, // the initial division id
  institutionTypes: PropTypes.array,
  isNIH: PropTypes.string,
  disable: PropTypes.bool,
  disableCreateNew: PropTypes.bool,
  isRAS: PropTypes.bool,
};

const mapStateToProps = (state, { formName }) => {
  const selector = formValueSelector(formName);
  return {
    institution: selector(state, "division.institution"),
    institutionDivision: selector(state, "institutionDivision"),
  };
};

export default connect(mapStateToProps)(SelectDivision);
