import {
  fieldIsUndefined,
  textIsEmptyString,
  textExceedsMaxCount,
} from "../../../utils/validation";

export const nameMaxLength = 32;
export const occupationMaxLength = 256;
export const phoneMaxLength = 20;
export const passwordMinLength = 8;

export const emailFields = {
  emailAddress: [
    fieldIsUndefined(null, "Please enter your email address"),
    textIsEmptyString(null, "Your email address may not be blank"),
    {
      error: ({ emailAddress }) => emailAddress.endsWith("nih.gov"),
      message:
        'NIH users should use their NIH credentials to register or access the site using the "Sign On Using NIH Credentials" button on the "Sign Up/In page"',
    },
    {
      error: ({ emailAddress }) => {
        const emailRegExp = /^[^@]+@[^@]+\.[^@]+$/;
        return !emailRegExp.test(emailAddress);
      },
      message: "Please enter a correct email format",
    },
  ],
  emailConfirm: [
    fieldIsUndefined(null, "Please confirm your email address"),
    {
      error: ({ emailAddress, emailConfirm }) => emailAddress !== emailConfirm,
      message: "Confirmation email does not match the entered email address",
    },
  ],
};

export const userFields = {
  firstName: [
    fieldIsUndefined(null, "Please enter your first name"),
    textIsEmptyString(null, "Your first name may not be blank"),
    textExceedsMaxCount(
      null,
      nameMaxLength,
      `Your first name may not be longer than ${nameMaxLength} characters`,
    ),
  ],
  lastName: [
    fieldIsUndefined(null, "Please enter your last name"),
    textIsEmptyString(null, "Your last name may not be blank"),
    textExceedsMaxCount(
      null,
      nameMaxLength,
      `Your last name may not be longer than ${nameMaxLength} characters`,
    ),
  ],
  occupation: [
    fieldIsUndefined(null, "Please enter your job title/position"),
    textIsEmptyString(null, "Your job title/position may not be blank"),
    textExceedsMaxCount(
      null,
      occupationMaxLength,
      `Your job title/position may not be longer than ${occupationMaxLength} characters`,
    ),
  ],
  middleInitial: [
    {
      error: (value) =>
        value.middleInitial && value.middleInitial.trim().length > 1,
      message: "Middle initial may only be one character",
    },
  ],
  phone: [
    {
      error: (value) =>
        value.phone && value.phone.trim().length > phoneMaxLength,
      message: `Phone number may not be longer than ${phoneMaxLength} digits`,
    },
  ],
};

export const passwordConfirmField = {
  passwordConfirm: [
    fieldIsUndefined(null, "Please confirm your password"),
    {
      error: ({ password, passwordConfirm }) => password !== passwordConfirm,
      message: "Confirmation password does not match the entered password",
    },
  ],
};

export const validatePassword = (passwordLabel, password) => {
  let error = "";
  if (password.length < passwordMinLength) {
    error += `, must be at least ${passwordMinLength} characters`;
  }
  if (!/[A-Z]/.test(password)) {
    error += ", must contain one uppercase letter";
  }
  if (!/[a-z]/.test(password)) {
    error += ", must contain one lowercase letter";
  }
  if (!/[0-9]/.test(password)) {
    error += ", must contain one number";
  }
  if (!/[_\W]/.test(password)) {
    error += ", must contain one special character";
  }
  if (/^[0-9]/.test(password)) {
    error += ", may not start with a number";
  }
  if (/[\s]/.test(password)) {
    error += ", may not contain spaces";
  }
  const message = error && `Your ${passwordLabel} ${error.substring(2)}`;
  return message;
};
