import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as LeftArrow } from "../../../img/icons/arrows/Left.svg";
import { ReactComponent as RightArrow } from "../../../img/icons/arrows/Right.svg";
import AccountNavbar from "./AccountNavbar";

// renders sidebar containing the account info
const AccountSidebar = (props) => {
  const { className } = props;

  // width in px of the sidebar
  const width = 240;

  const [isOpen, setIsOpen] = useState(false);

  const toggleSideNav = () => {
    setIsOpen(!isOpen);
  };

  const Icon = isOpen ? RightArrow : LeftArrow;
  const xPosition = isOpen ? 0 : width;
  return (
    <>
      <button
        type='button'
        className={className}
        onClick={toggleSideNav}
        aria-controls='account-sidebar'
        aria-expanded={isOpen}
        aria-label='Toggle My Account navigation bar'
      >
        <Icon height='13px' stroke='white' viewBox='20 0 50 50' />
        My Account
      </button>
      <div
        id='account-sidebar'
        className='account-sidebar'
        style={{
          transform: `translatex(${xPosition}px)`,
        }}
      >
        <AccountNavbar />
      </div>
    </>
  );
};

AccountSidebar.propTypes = {
  className: PropTypes.string,
};

AccountSidebar.defaultProps = {
  className: "",
};

export default AccountSidebar;
