import React from "react";
import "babel-polyfill"; // support for ES6 and ES7 features in IE
import "react-app-polyfill/ie9"; // For IE 9-11 support
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Routes from "./components/Routes";
import TimerWarningContainer from "../features/auth";
import HeaderContainer from "../common/header";
import FooterContainer from "../common/footer";
import Loader from "../common/loader";
import ModalContainer from "../common/modal";
import "./App.scss";
import AlertBanner from "../common/header/components/AlertBanner";

let isMaintenance = false;

const App = () => (
  <TimerWarningContainer>
    <HeaderContainer isMaintenance={isMaintenance} />
    <main className='dash-main'>
      <AlertBanner />
      <Routes isMaintenance={isMaintenance} />
      <ModalContainer />
      <Loader />
    </main>
    <FooterContainer isMaintenance={isMaintenance} />
  </TimerWarningContainer>
);

export default App;
