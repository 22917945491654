import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import { ReactComponent as Hamburger } from "../../../img/icons/Hamburger.svg";
import AccountSidebar from "./AccountSidebar";
import Dropdown from "./Dropdown";

// renders the white navigation bar
class MainNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.toggleMainMenu = this.toggleMainMenu.bind(this);
    this.closeMainMenu = this.closeMainMenu.bind(this);
  }

  toggleMainMenu() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  closeMainMenu() {
    if (this.state.isOpen) {
      this.toggleMainMenu();
    }
  }

  render() {
    const { pathName } = this.props;
    const resourceDropdownStyle =
      !this.props.isAdmin && !this.props.isCurator ? { right: "50px" } : {};
    const adminDropdownStyle =
      this.props.isAdmin && !this.props.isCurator ? { right: "50px" } : {};
    return (
      <div
        className='dash-navbar-container container-fluid padding-sides'
        role='navigation'
        aria-label='Navigate to DASH web pages'
      >
        <nav
          aria-label='dash-main-navbar'
          className='dash-navbar navbar-expand-xl'
        >
          <button
            type='button'
            className='dash-navbar-toggler navbar-toggler'
            onClick={this.toggleMainMenu}
            aria-controls='main-navigation-menu'
            aria-expanded={this.state.isOpen}
            aria-label='Toggle main menu buttons'
          >
            <span className='sr-only'>Toggle main menu buttons</span>
            <Hamburger height='30px' stroke='white' />
          </button>
          {!this.props.isLoggedIn ? (
            <Link to='/signin' className='lower-login-btn'>
              Sign Up/ In
            </Link>
          ) : (
            <AccountSidebar className='lower-login-btn' />
          )}
          <Collapse id='main-navigation-menu' isOpen={this.state.isOpen} navbar>
            <ul className='navbar-nav ml-auto'>
              <li>
                <Dropdown
                  name='Explore Data'
                  links={[
                    { href: "/explore/study", label: "Studies" },
                    {
                      label: "Study Components",
                      children: [
                        { href: "/explore/dataset", label: "Datasets" },
                        { href: "/explore/document", label: "Documents" },
                      ],
                    },
                  ]}
                  selected={
                    pathName === "/explore/dataset" ||
                    pathName === "/explore/document" ||
                    pathName === "/explore/study"
                  }
                  closeMainMenu={this.closeMainMenu}
                />
              </li>
              <li>
                <Link
                  to='/explore/biospecimen'
                  className={
                    pathName === "/explore/biospecimen"
                      ? "dash-nav-link selected-path"
                      : "dash-nav-link"
                  }
                  onClick={this.closeMainMenu}
                  onKeyPress={this.closeMainMenu}
                >
                  Explore Biospecimens
                </Link>
              </li>
              <li>
                <Link
                  to='/explore/externalResource'
                  className={
                    pathName === "/explore/externalResource"
                      ? "dash-nav-link selected-path"
                      : "dash-nav-link"
                  }
                  onClick={this.closeMainMenu}
                  onKeyPress={this.closeMainMenu}
                >
                  Explore External Resources
                </Link>
              </li>
              <li>
                <Link
                  to='/submission/dashboard'
                  className={
                    pathName.indexOf("/submission") === 0
                      ? "dash-nav-link selected-path"
                      : "dash-nav-link"
                  }
                  onClick={this.closeMainMenu}
                  onKeyPress={this.closeMainMenu}
                >
                  Submit Study
                </Link>
              </li>
              <li>
                <Dropdown
                  name='Helpful Information'
                  links={[
                    { href: "/resource/policies", label: "NICHD DASH Policy" },
                    {
                      href: "/resource/DASHUserAgreement",
                      label: "DASH User Agreement",
                    },
                    {
                      href: "/resource/FAQs",
                      label: "Frequently Asked Questions (FAQs)",
                    },
                    { href: "/resource/tutorial", label: "DASH Tutorial" },
                    { href: "/resource/sitemap", label: "DASH Site Map" },
                    { href: "/resource/glossary", label: "DASH Glossary" },
                    {
                      href: "/resource/submission",
                      label: "Submission Resources",
                    },
                    { href: "/resource/request", label: "Request Resources" },
                    {
                      href: "/resource/publications",
                      label: "Publications from DASH Data Reuse",
                    },
                    {
                      href: "/resource/LinksToOtherArchives",
                      label: "Links to Other Archives",
                    },
                    {
                      href: "/resource/LinksToEducationalDatasets",
                      label: "Links to Educational Datasets",
                    },
                  ]}
                  dropdownStyle={resourceDropdownStyle}
                  selected={pathName.indexOf("/resource") === 0}
                  closeMainMenu={this.closeMainMenu}
                />
              </li>
              <li>
                <Link
                  to='/feedback'
                  className={
                    pathName === "/feedback"
                      ? "dash-nav-link selected-path"
                      : "dash-nav-link"
                  }
                  onClick={this.closeMainMenu}
                  onKeyPress={this.closeMainMenu}
                >
                  Feedback
                </Link>
              </li>
              {this.props.isLoggedIn && this.props.isAdmin && (
                <li>
                  <Dropdown
                    name='Admin'
                    links={[
                      { href: "/admin/users", label: "Manage Users" },
                      {
                        href: "/admin/institutions",
                        label: "Manage Institutions",
                      },
                      { href: "/admin/requests", label: "Manage Requests" },
                      {
                        href: "/admin/annualReports",
                        label: "Manage Annual Reports",
                      },
                      { href: "/admin/outcomes", label: "Manage Outcomes" },
                      { href: "/admin/feedback", label: "Manage Feedback" },
                      {
                        href: "/admin/reports/user/aggregates",
                        label: "Manage Reports",
                      },
                    ]}
                    dropdownStyle={adminDropdownStyle}
                    selected={pathName.indexOf("/admin") === 0}
                    closeMainMenu={this.closeMainMenu}
                  />
                </li>
              )}
              {this.props.isLoggedIn && this.props.isCurator && (
                <li>
                  <Link
                    to='/curate/manageSubmissions'
                    className={
                      pathName === "/curate/manageSubmissions"
                        ? "dash-nav-link selected-path"
                        : "dash-nav-link"
                    }
                    onClick={this.closeMainMenu}
                    onKeyPress={this.closeMainMenu}
                  >
                    Curator
                  </Link>
                </li>
              )}
              {this.props.isLoggedIn &&
                localStorage.getItem("isOfficer") === "true" && (
                  <li>
                    <Link
                      to='/officer/reports/user/aggregates'
                      className={
                        pathName.indexOf("/officer") === 0
                          ? "dash-nav-link selected-path"
                          : "dash-nav-link"
                      }
                      onClick={this.closeMainMenu}
                      onKeyPress={this.closeMainMenu}
                    >
                      Officer
                    </Link>
                  </li>
                )}
            </ul>
          </Collapse>
        </nav>
      </div>
    );
  }
}

MainNavigation.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isCurator: PropTypes.bool.isRequired,
  pathName: PropTypes.string.isRequired,
};

export default MainNavigation;
