import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as RightArrowIcon } from "../../../img/icons/arrows/Right.svg";
import "./style.scss";

const ArrowButton = (props) => {
  if (props.onClick) {
    return (
      <p>
        <button
          type='button'
          aria-label={props.ariaLabel}
          className='btn-slider'
          {...props}
        >
          {props.label}
          <span className='right-arrow-icon'>
            <RightArrowIcon height='13px' stroke='white' />
          </span>
        </button>
      </p>
    );
  }
  return (
    <p>
      <Link
        aria-label={props.ariaLabel}
        role='button'
        className='btn-slider'
        {...props}
      >
        {props.label}
        <span className='right-arrow-icon'>
          <RightArrowIcon height='13px' stroke='white' />
        </span>
      </Link>
    </p>
  );
};

ArrowButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
};

export default ArrowButton;
