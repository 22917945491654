import {
  RENEWAL_SET_STATUS,
  RENEWAL_SET_STEP_DATA,
  RENEWAL_RESET_STATE,
  RENEWAL_SET_FORM_SUPPORT,
  RENEWAL_DISABLE_AUTH_REP_FIELDS,
  RENEW_REQUEST_CREATED_NEW_DIVISION,
} from "../../common/types";

const INITIAL_STATE = {
  studyName: null,
  requestType: null,
  biospecimenRepository: null,
  status: null, // status is either 'Request Initiated', 'In Progress', 'Rejected', or 'Pending Approval'
  hasUnresolvedConstraints: false, // only true for biospecimen requests for which the user has not complied with constraints, false otherwise
  steps: null, // array of all the forms the user has to go through; array order is the order of the forms to complete
  currStepIndex: null, // index of the current form the user is on
  nextStepToCompleteIndex: null, // index of the next uncompleted form, the user cannot advance to any forms with index greated than nextStepToCompleteIndex
  disableAuthRepFields: null, // used by study info form to enable/disable the auth rep fields
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RENEWAL_SET_STATUS: // sets the studyName, requestType, status, hasUnresolvedConstraints, steps, currStepIndex, and nextStepToCompleteIndex fields
    case RENEWAL_SET_STEP_DATA: // sets the currStepIndex and the step data and form support
    case RENEWAL_SET_FORM_SUPPORT:
      return {
        ...state,
        ...action.payload,
      };
    case RENEWAL_DISABLE_AUTH_REP_FIELDS:
      return {
        ...state,
        disableAuthRepFields: action.payload,
      };
    case RENEWAL_RESET_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
    case RENEW_REQUEST_CREATED_NEW_DIVISION: {
      const { division } = action.payload;
      return {
        ...state,
        customDivision: division,
        formSupport: {
          ...state.formSupport,
          divisions: [...state.formSupport.divisions, division],
        },
      };
    }
  }
};
