import React from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";

const DatePicker = (props) => {
  const {
    id,
    label,
    required,
    ariaLabel,
    input,
    meta,
    popperClassName,
    errorPopperClassName,
    ...rest
  } = props;

  let inputStyle = {};
  let errorMessage = null;
  if (meta && meta.touched && (meta.error || meta.warning)) {
    inputStyle = {
      border: "1px solid rgb(213, 0, 0)",
      boxShadow: "0 0 5px rgb(213, 0, 0)",
      marginBottom: "0px",
    };
    errorMessage = meta.error || meta.warning;
  }

  const customInput = (
    <input type='text' aria-label={ariaLabel} style={inputStyle} />
  );
  return (
    <>
      {label && (
        <label htmlFor={id} className='w-100'>
          {label}
          {required && <span className='requiredField'> *</span>}
        </label>
      )}
      <ReactDatePicker
        {...input}
        {...rest}
        id={id}
        customInput={customInput}
        popperClassName={errorMessage ? errorPopperClassName : popperClassName}
      />
      <span className='c-validation_error'>{errorMessage}</span>
    </>
  );
};

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  ariaLabel: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
  selected: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  required: PropTypes.bool,
  popperClassName: PropTypes.string,
  errorPopperClassName: PropTypes.string,
};

DatePicker.defaultProps = {
  label: null,
  required: false,
  popperClassName: "",
  errorPopperClassName: "",
};

export default DatePicker;
