import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { KEY_CODE } from "../../utils";

const ClassicCheckbox = (props) => {
  const {
    id,
    label,
    input,
    className,
    meta,
    required,
    disabled,
    style,
    tooltip,
    field,
    ...rest
  } = props;

  const handleKeyDown = (e) => {
    if (e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
    }
  };

  const errorClassNames =
    !disabled && meta && meta.touched && (meta.error || meta.warning)
      ? "validation-error mb-0"
      : "";
  const checkedClass = input.value ? "bckg-gray-darker" : "";
  return (
    <div id='classic-checkbox' className={className} style={style}>
      <input
        {...input}
        {...rest}
        checked={input.value}
        id={id}
        type='checkbox'
        className='classic-checkbox-input'
        aria-checked={input.value}
        aria-label={
          input.value ? `${field} - checked` : `${field} - not checked`
        }
        disabled={disabled}
        onKeyDown={(e) => handleKeyDown(e)}
      />
      <label style={{ display: "none" }} htmlFor={id}>
        {label}
      </label>
      <span
        className={`facet-filter-checkmark ${checkedClass} ${errorClassNames}`}
      />
      <span id='label'> {label}&nbsp;</span>{" "}
      {required && <span className='requiredField m-0'>&nbsp;*</span>}
    </div>
  );
};

ClassicCheckbox.propTypes = {
  id: PropTypes.string.isRequired, // this must be a unique id for the component
  input: PropTypes.shape({
    value: PropTypes.bool.isRequired, // must be true if the checkbox is selected, false if checkbox is not selected
    onChange: PropTypes.func.isRequired, // function to change the value of the checkbox
  }).isRequired,
  className: PropTypes.string,
  meta: PropTypes.shape({
    // these meta props are used by redux-form to pass validation error props
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
  label: PropTypes.string, // if label is not provided then pass an aria-label to the component for 508 accessibility
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  tooltip: PropTypes.object,
};

ClassicCheckbox.defaultProps = {
  className: "",
  required: false,
  disabled: false,
  style: {},
};

export default ClassicCheckbox;
