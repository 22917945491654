import React, { Component } from "react";
import PropTypes from "prop-types";
import filesize from "filesize";
import { saveAs } from "file-saver";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Line } from "rc-progress";
import { MODAL_TYPES } from "../../types";
import LoaderContainer from "../../loader/LoaderContainer";

let multiFileDownloadAjaxRequest = null;

class DownloadDuaModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interval: null,
      filesReady: false,
      session: "",
      isLoading: false,
      compressedFileSize: null,
      rawBytesProcessed: 0,
      filesProcessed: 0,
      filesCompressed: 0,
      downloading: false,
    };
    this.cancelDownload = this.cancelDownload.bind(this);
    this.downloadFiles = this.downloadFiles.bind(this);
    this.processDownload = this.processDownload.bind(this);
    this.check = this.check.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.downloadOnLoad = this.downloadOnLoad.bind(this);
    this.onError = this.onError.bind(this);
    this.checkOnLoad = this.checkOnLoad.bind(this);
    this.processOnLoad = this.processOnLoad.bind(this);
  }

  componentWillUnmount() {
    if (this.state.interval) clearInterval(this.state.interval);
  }

  onError() {
    if (this.state.interval) clearInterval(this.state.interval); // will stop the polling
    this.closeModal();
    const modalProps = {
      modalHeader: "Error",
      BodyComponent: () => (
        <>A download error has occurred. Please try again.</>
      ),
      cancelButtonText: "Close",
    };
    this.props.openModal(MODAL_TYPES.BASIC_MODAL, modalProps);
    return false;
  }

  closeModal() {
    if (this.state.interval) clearInterval(this.state.interval);
    this.setState({
      filesReady: false,
      session: "",
      isLoading: false,
      rawBytesProcessed: 0,
      filesProcessed: 0,
      downloading: false,
      filesCompressed: 0,
    });
    this.props.closeModal();
  }

  processOnLoad(xhr, email, token) {
    if (xhr.readyState === 4 && xhr.status === 200) {
      this.setState({ isLoading: true });
      const data = xhr.response;
      this.check(data, email, token);
    } else if (xhr.status !== 0) {
      this.onError();
    }
  }

  checkOnLoad(xhr, session) {
    if (xhr.readyState === 4 && xhr.status === 200) {
      const progress = JSON.parse(xhr.response);
      this.setState({
        filesReady: true,
        session,
        isLoading: false,
        compressedFileSize: progress.zipSize,
        filesProcessed: progress.filesProcessed,
      });
      if (this.state.interval) clearInterval(this.state.interval); // will stop the polling
    } else if (xhr.readyState === 4 && xhr.status === 206) {
      const progress = JSON.parse(xhr.response);
      this.setState({
        rawBytesProcessed: progress.bytesProcessed,
        filesProcessed: progress.filesProcessed,
        filesCompressed: progress.filesZipped,
      });
    } else if (xhr.status >= 400) {
      this.onError();
    }
  }

  downloadOnLoad(xhr) {
    if (xhr.readyState === 4 && xhr.status === 200) {
      // //Get zip file name for download from response header
      const header = xhr.getResponseHeader("content-disposition");
      const zipFile = header.match(/filename="(.+)"/)[1];
      this.closeModal();
      this.setState({ filesReady: false, session: "", isLoading: false });
      // Initiate client download for zip file
      const blob = xhr.response;
      saveAs(blob, zipFile);
      this.setState({ downloading: false });
    }
  }

  processDownload(token, itemIds, email) {
    const xhr = new XMLHttpRequest();
    const downloadURL = "/download/process";

    xhr.open("POST", downloadURL, true);
    xhr.timeout = 1200000; // timeout 20mins
    xhr.setRequestHeader("Authorization", `Bearer ${token}`);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.withCredentials = true;

    xhr.onload = () => this.processOnLoad(xhr, email, token);
    xhr.onerror = this.onError;

    multiFileDownloadAjaxRequest = xhr;
    const params = `item_id=${itemIds}&email=${email}`;
    xhr.send(params);
  }

  checkStatus(email, token, session) {
    const xhr = new XMLHttpRequest();
    const downloadURL = "/download/status";

    xhr.open("GET", downloadURL);
    xhr.withCredentials = true;
    xhr.setRequestHeader("email", email);
    xhr.setRequestHeader("Authorization", `Bearer ${token}`);
    xhr.setRequestHeader("session_id", session);

    xhr.onload = () => this.checkOnLoad(xhr, session);
    xhr.onerror = this.onError;

    xhr.send();
  }

  check(data, email, token) {
    if (data) {
      const json = JSON.parse(data);
      const session = json.session_id;
      sessionStorage.setItem("session", session);

      const checkStatus = () => this.checkStatus(email, token, session);

      clearInterval(this.state.interval);
      this.setState({ interval: setInterval(checkStatus, 500) }); // ping status every half second
    }
  }

  downloadFiles(token, email, session) {
    const xhr = new XMLHttpRequest();
    const downloadURL = "/download/file";
    xhr.open("GET", downloadURL);
    xhr.timeout = 600000; // timeout 10 mins
    xhr.withCredentials = true;
    xhr.setRequestHeader("email", email);
    xhr.setRequestHeader("Authorization", `Bearer ${token}`);
    xhr.setRequestHeader("session_id", session);
    xhr.responseType = "blob";

    xhr.onload = () => this.downloadOnLoad(xhr);
    xhr.onerror = this.onError;
    this.setState({ downloading: true });
    xhr.send();
  }

  cancelDownload() {
    multiFileDownloadAjaxRequest.abort();
    multiFileDownloadAjaxRequest = null;
    this.closeModal();
  }

  render() {
    const { isOpen, itemsString, email, token, totFilesize, numItems } =
      this.props;

    const prepareDownload = {
      footer: (
        <button
          type='button'
          className='btn modal-btn modal-btn-blue'
          onClick={() => this.processDownload(token, itemsString, email)}
        >
          Begin Download [{filesize(totFilesize)}]
        </button>
      ),
      body: <h3>Click the download button to begin your download</h3>,
    };

    let downloadLoadingText = "Preparing download...";
    if (this.state.rawBytesProcessed > 0) {
      downloadLoadingText = `Copying ${this.state.filesProcessed} of ${numItems} files...`;
    }
    if (filesize(this.state.rawBytesProcessed) === filesize(totFilesize)) {
      downloadLoadingText = `Compressing ${this.state.filesCompressed} of ${numItems} files...`;
    }
    const loader =
      this.state.rawBytesProcessed > 0 &&
      filesize(this.state.rawBytesProcessed) !== filesize(totFilesize) ? (
        <>
          <div>{downloadLoadingText}</div>
          <div
            role='progressbar'
            aria-atomic='addtions'
            aria-label='Progress bar for downloading files.'
            aria-live='assertive'
            aria-valuemin='0'
            aria-valuemax='100'
          >
            <Line
              percent={(this.state.rawBytesProcessed / totFilesize) * 100}
              strokeWidth='4'
              strokeColor='#48763a'
            />
          </div>
          <h3>
            {Math.round((this.state.rawBytesProcessed / totFilesize) * 100, 2)}%
          </h3>
          <br />
        </>
      ) : (
        <LoaderContainer
          active
          animate
          spinner
          text={downloadLoadingText}
          style={{ display: "block", height: "120px" }}
          background='white'
          color='grey'
        />
      );
    const downloadLoading = {
      footer: (
        <button
          type='button'
          style={{ backgroundColor: "#b02b40", color: "white" }}
          className='btn modal-btn'
          onClick={this.cancelDownload}
        >
          Cancel Download
        </button>
      ),
      body: <div key='loading'>{loader}</div>,
    };

    const filesReady = {
      footer: (
        <button
          type='button'
          className='btn modal-btn modal-btn-blue'
          onClick={() => this.downloadFiles(token, email, this.state.session)}
        >
          Click to Download [{filesize(this.state.compressedFileSize)}]
        </button>
      ),
      body: (
        <div>
          <h3
            key='completed'
            style={{ paddingBottom: "50px", paddingTop: "50px" }}
          >
            Your files have been compressed.
          </h3>
        </div>
      ),
    };

    const fileDownloading = {
      body: (
        <div>
          <LoaderContainer
            active
            animate
            spinner
            text='Downloading ZIP'
            style={{ display: "block", height: "120px" }}
            background='white'
            color='grey'
          />
        </div>
      ),
      footer: null,
    };

    return (
      <Modal isOpen={isOpen} toggle={this.closeModal} backdrop='static'>
        <ModalHeader toggle={this.closeModal} tag='h2'>
          Download Selected Items
        </ModalHeader>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            {!this.state.isLoading &&
              !this.state.filesReady &&
              prepareDownload.body}
            {this.state.isLoading && downloadLoading.body}
            {this.state.filesReady &&
              !this.state.downloading &&
              filesReady.body}
            {this.state.downloading && fileDownloading.body}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='download-dua-footer'>
            {!this.state.isLoading &&
              !this.state.filesReady &&
              prepareDownload.footer}
            {this.state.isLoading && downloadLoading.footer}
            {this.state.filesReady && filesReady.footer}
            {this.state.downloading && fileDownloading.footer}
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

DownloadDuaModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  token: PropTypes.string,
  itemsString: PropTypes.string,
  email: PropTypes.string,
  totFilesize: PropTypes.number,
  numItems: PropTypes.number,
};

export default DownloadDuaModal;
