import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import * as actions from "./authActions";
import TimeoutWarningModal from "../../common/modal/modals/TimeoutWarningModal";

const timeMidWayPoint = 450 * 1000; // Midway point - 7.5 Mins updated 2/7/22
const timeoutWarning = 900 * 1000; // Display warning in 15 mins updated 2/7/22
const timeoutNow = 1600 * 1000; // Timeout in 26 mins (few minutes less than server-side setting, to avoid any timing issues)
const serverTimeout = 1800 * 1000; // server timeout in 30 min -- updated with new reqs 2/7/22
const intervalCheck = 150 * 1000; // check timer every 2.5 min
const stage = "https://testdash.nichd.nih.gov";

class TimerWarningContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warningModal: false,
    };

    this.checkTimer = this.checkTimer.bind(this);
    this.midwayPointActivityReset = this.midwayPointActivityReset.bind(this);
    this.idleWarning = this.idleWarning.bind(this);
    this.idleTimeout = this.idleTimeout.bind(this);
    this.logActivity = this.logActivity.bind(this);
    this.toggleWarningModal = this.toggleWarningModal.bind(this);
    this.resetTimers = this.resetTimers.bind(this);
  }

  componentWillUnmount() {
    if (this.props.timer) {
      clearInterval(this.props.timer);
      document.removeEventListener("scroll", this.logActivity);
      document.removeEventListener("keydown", this.logActivity);
      document.removeEventListener("mousedown", this.logActivity);
    }
  }

  UNSAFE_componentWillMount() {
    const lsItems = {
      jwt: localStorage.getItem("JWT"),
      id: localStorage.getItem("id"),
      refreshToken: localStorage.getItem("refresh_token"),
      email: localStorage.getItem("email"),
      startTime: parseInt(localStorage.getItem("start_time"), 10),
      authenticated: !_.isNil(localStorage.getItem("JWT")),
      admin:
        !_.isNil(localStorage.getItem("isAdmin")) &&
        localStorage.getItem("isAdmin") === "true",
      curator:
        !_.isNil(localStorage.getItem("isCurator")) &&
        localStorage.getItem("isCurator") === "true",
      isNIHSSO:
        !_.isNil(localStorage.getItem("is_NIH_SSO")) &&
        localStorage.getItem("is_NIH_SSO") === "true",
    };

    const pathOk =
      _.includes(window.location.pathname, "signin") ||
      _.includes(window.location.pathname.toLowerCase(), "sso");
    const isStage = window.location.origin === stage;
    if (isStage && !pathOk && !lsItems.authenticated) {
      window.location.href = "/signin";
    }

    if (this.props.authenticated) {
      document.addEventListener("scroll", this.logActivity);
      document.addEventListener("keydown", this.logActivity);
      document.addEventListener("mousedown", this.logActivity);
      const elapsedTime = Date.now() - this.props.startTime;
      if (elapsedTime >= serverTimeout) {
        this.idleTimeout(true);
      }
      if (!this.props.timer) {
        this.props.startTimer(setInterval(this.checkTimer, intervalCheck));
      }
    } else if (lsItems.authenticated) {
      this.props.setAuthFlags(lsItems);
      document.addEventListener("scroll", this.logActivity);
      document.addEventListener("keydown", this.logActivity);
      document.addEventListener("mousedown", this.logActivity);
      const elapsedTime = Date.now() - lsItems.startTime;
      if (elapsedTime >= serverTimeout) {
        this.idleTimeout(true);
      }
      if (!this.props.timer) {
        this.props.startTimer(setInterval(this.checkTimer, intervalCheck));
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const authenticated = !_.isNil(localStorage.getItem("JWT"));
    const pathOk =
      _.includes(window.location.pathname, "signin") ||
      _.includes(window.location.pathname.toLowerCase(), "sso");
    const isStage = window.location.origin === stage;
    if (isStage && !pathOk && !authenticated) {
      window.location.pathname = "/signin";
    }
    if (this.props.timer !== nextProps.timer) {
      clearInterval(this.props.timer);
    }
    if (this.props.authenticated && !nextProps.authenticated) {
      clearInterval(this.props.timer);
      document.removeEventListener("scroll", this.logActivity);
      document.removeEventListener("keydown", this.logActivity);
      document.removeEventListener("mousedown", this.logActivity);
    }
    if (!this.props.authenticated && nextProps.authenticated) {
      document.addEventListener("scroll", this.logActivity);
      document.addEventListener("keydown", this.logActivity);
      document.addEventListener("mousedown", this.logActivity);
      if (!this.props.timer) {
        this.props.startTimer(setInterval(this.checkTimer, intervalCheck));
      }
    }
  }

  midwayPointActivityReset() {
    this.props.logActivity(false);
  }

  idleWarning() {
    if (!this.props.active) {
      if (!this.state.warningModal) {
        this.toggleWarningModal();
      }
    }
  }

  idleTimeout(serverTimedout) {
    if (serverTimedout || !this.props.active) {
      if (this.state.warningModal) this.toggleWarningModal();
      this.props.signout();
    } else {
      this.resetTimers();
    }
  }

  logActivity() {
    if (!this.props.active) {
      this.props.logActivity(true);
    }
  }

  checkTimer() {
    // time is checked every 5 minutes, if inactive between 35-40 min, idle warning is called
    const st = this.props.startTime;
    if (st) {
      const elapsedTime = Date.now() - st;
      if (elapsedTime >= timeMidWayPoint && elapsedTime < timeoutWarning) {
        // at 20, 25, 30, 35 set activity to false
        this.midwayPointActivityReset();
      } else if (elapsedTime >= timeoutWarning && elapsedTime < timeoutNow) {
        // after 40 min if activity is false, toggle idle warning modal
        this.idleWarning();
      } else if (elapsedTime >= timeoutNow && elapsedTime < serverTimeout) {
        // after 45 min if timer isn't reset by hitting warning modal button, log user out -- unless activity has been logged, then reset timer
        this.idleTimeout(false);
      } else if (elapsedTime >= serverTimeout) {
        // after 60 min log user out if not logged out already
        this.idleTimeout(true);
      }
    }
  }

  toggleWarningModal() {
    this.setState((prevState) => ({ warningModal: !prevState.warningModal }));
  }

  resetTimers() {
    if (this.state.warningModal) {
      this.toggleWarningModal();
    }
    const elapsedTime = Date.now() - this.props.startTime;
    if (elapsedTime < serverTimeout) {
      clearInterval(this.props.timer);
      const data = {
        refresh_token: this.props.refreshToken,
        timer: setInterval(this.checkTimer, intervalCheck),
      };
      this.props.resetTimers(data);
    }
  }

  render() {
    return (
      <>
        {this.props.children}
        <TimeoutWarningModal
          isOpen={this.state.warningModal}
          toggle={this.toggleWarningModal}
          resetTimers={this.resetTimers}
        />
      </>
    );
  }
}

TimerWarningContainer.propTypes = {
  children: PropTypes.node.isRequired,
  authenticated: PropTypes.bool.isRequired,
  resetTimers: PropTypes.func,
  startTimer: PropTypes.func,
  refreshToken: PropTypes.string,
  startTime: PropTypes.number,
  logActivity: PropTypes.func.isRequired,
  setAuthFlags: PropTypes.func.isRequired,
  signout: PropTypes.func.isRequired,
  timer: PropTypes.number,
  active: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  ...auth,
});

export default withRouter(
  connect(mapStateToProps, actions)(TimerWarningContainer),
);
