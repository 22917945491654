import {
  ADMIN_SET_FORM_SUPPORT,
  ADMIN_SET_INITIAL_DATA,
  ADMIN_SET_ITEMS,
  ADMIN_RESET_STATE,
} from "../../common/types";

export const initialState = {
  user: {
    roles: [],
    statuses: [],
    pagination: {},
  },
  institution: {
    statuses: [],
    pagination: {},
  },
  request: {
    statuses: [],
    pagination: {},
  },
  feedback: {
    pagination: {},
    statuses: [],
  },
  outcome: {
    pagination: {},
    statuses: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_SET_FORM_SUPPORT: {
      const { pageType, formType, data } = action.payload;
      return {
        ...state,
        [pageType]: {
          ...state[pageType],
          [formType]: data,
        },
      };
    }
    case ADMIN_SET_INITIAL_DATA: {
      const { pageType, items } = action.payload;
      return {
        ...state,
        [pageType]: {
          ...state[pageType],
          pagination: items,
        },
      };
    }
    case ADMIN_SET_ITEMS: {
      const { status, items, page, pageType } = action.payload;
      return {
        ...state,
        [pageType]: {
          ...state[pageType],
          pagination: {
            ...state[pageType].pagination,
            [status]: {
              ...state[pageType].pagination[status],
              items: items || [],
              page,
            },
          },
        },
      };
    }
    case ADMIN_RESET_STATE:
      return {
        ...state,
        [action.payload.pageType]: initialState[action.payload.pageType],
      };
    default:
      return state;
  }
};
