import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link } from "react-router-dom";
import "./style.scss";

const NavButton = (props) => {
  const uppernavIcon = props.icon ? (
    <span className='uppernav-icons'>{props.icon}</span>
  ) : null;
  const classes = props.inverse
    ? `nav-link navbar-btn inverse ${props.classes}`
    : `nav-link navbar-btn ${props.classes}`;
  const others = _.omit(props, [
    "label",
    "children",
    "inverse",
    "classes",
    "icon",
    "outside",
  ]);
  if (props.href && !props.outside) {
    return (
      <Link className={classes} to={others.href} {...others}>
        <span className='uppernav-icons'>{uppernavIcon}</span>
        <span>{props.label}</span>
        {props.children}
      </Link>
    );
  } else if (props.href && props.outside) {
    // TODO: After everything is in react (admin, curator, login, etc) then remove this if statement
    // Instead use the above <Link /> to navigate to different pages since it is much more efficient.
    return (
      <a className={classes} {...others}>
        <span className='uppernav-icons'>{uppernavIcon}</span>
        <span>{props.label}</span>
        {props.children}
      </a>
    );
  }
  return (
    <button
      className={classes}
      type='button'
      onClick={props.onClick}
      {...others}
    >
      {uppernavIcon}
      <span>{props.label}</span>
      {props.children}
    </button>
  );
};

NavButton.propTypes = {
  label: PropTypes.node.isRequired,
  href: PropTypes.string,
  icon: PropTypes.node,
  id: PropTypes.string,
  classes: PropTypes.string,
  style: PropTypes.object,
  inverse: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  outside: PropTypes.bool, // true if navigating to c# page
};

NavButton.defaultProps = {
  icon: null,
  style: {},
  inverse: false,
  children: null,
  classes: "",
  id: "",
  outside: false,
};

export default NavButton;
