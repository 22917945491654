import React, { useState } from "react";
import { reduxForm } from "redux-form";
import PropTypes from "prop-types";
import Alert from "../../../alert";
import ResearcherSectionWorkspace from "../ResearcherSectionWorkspace";
import ManageAffiliates from "../ManageAffiliates";
import CollapseSection from "../CollapseSection";
import ConfirmationModal from "../../modals/ConfirmationModal";
import { ALERT_TYPES } from "../../../types";

// this component renders the researcher info for the Associates and Collaborator sections
export const ModifyResearchTeamForm = (props) => {
  const {
    duaId,
    users,
    setLoading,
    changeResearchTeam,
    initialAffiliates,
    initialAssociates,
    initialCollaborators,
    institutions,
    titles,
    modalProps,
    closeModifyResearchTeamModal,
    closeConfirmationModal,
    isOpen,
    studyName,
  } = props;

  const [alert, setAlert] = useState({ alertType: null, alertMessage: null });

  const [affiliateUsers, setAffiliateUsers] = useState(initialAffiliates);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [associates, setAssociates] = useState(initialAssociates);

  const [collaborators, setCollaborators] = useState(initialCollaborators);

  const handleSubmit = () => {
    closeConfirmationModal();
    setLoading(true);
    const data = {
      affiliates: affiliateUsers,
      associates,
      collaborators,
      hasAffiliates: affiliateUsers.length > 0,
      hasAssociates: associates.length > 0,
      hasCollaborators: collaborators.length > 0,
    };
    changeResearchTeam(duaId, data)
      .then(() => {
        setLoading(false);
        closeModifyResearchTeamModal();
      })
      .catch((error) => {
        setAlert({
          alertType: ALERT_TYPES.ERROR,
          alertMessage: error.message,
        });
        setLoading(false);
      });
  };

  const renderAffiliateForm = () => (
    <>
      <CollapseSection
        note='All listed affiliates must be registered DASH users.'
        introText='Affiliates are individuals within your institution, for whom access to Data is required to carry out the Research Plan. Affiliates are permitted to access and download data directly from NICHD DASH.'
        researcher='Affiliates'
        Component={ManageAffiliates}
        users={users}
        affiliateUsers={affiliateUsers}
        selectedUsers={selectedUsers}
        setAffiliateUsers={setAffiliateUsers}
        setSelectedUsers={setSelectedUsers}
      />
    </>
  );

  const renderAssocciateForm = () => (
    <>
      <CollapseSection
        introText="Associates are individuals employed by other institutions that will be allowed to access data and will be covered under your institution's Data Use Agreement. They will not be permitted by the DASH system to access or download data directly; instead, they must access data only within your data platform and must not download data from your data platform to their own local data platform or devices. "
        researcher='Associate'
        Component={ResearcherSectionWorkspace}
        instructions='To remove an Associate, click the "x" button to the right of their contact information.'
        institutions={institutions}
        titles={titles}
        modalProps={modalProps}
        researchers={associates}
        setResearchers={setAssociates}
      />
    </>
  );

  const renderCollaboratorForm = () => (
    <>
      <CollapseSection
        introText='Collaborators are individuals at other institutions under the supervision of other Principal Investigators working collaboratively on the same research plan.'
        note='Collaborators must submit a separate Data Request Form and sign a separate DUA with NICHD.'
        researcher='Collaborator'
        Component={ResearcherSectionWorkspace}
        instructions='To remove a Collaborator, click the "x" button to the right of their contact information.'
        institutions={institutions}
        titles={titles}
        modalProps={modalProps}
        researchers={collaborators}
        setResearchers={setCollaborators}
      />
    </>
  );

  return (
    <>
      <Alert alertType={alert.alertType} message={alert.alertMessage} />
      <div className='form-group'>
        {renderAffiliateForm()}
        {renderAssocciateForm()}
        {renderCollaboratorForm()}
        <ConfirmationModal
          closeConfirmationModal={closeConfirmationModal}
          onSubmit={handleSubmit}
          isOpen={isOpen}
          studyName={studyName}
        />
      </div>
    </>
  );
};

ModifyResearchTeamForm.propTypes = {
  duaId: PropTypes.number.isRequired,
  users: PropTypes.array.isRequired,
  initialAffiliates: PropTypes.array.isRequired,
  initialAssociates: PropTypes.array.isRequired,
  initialCollaborators: PropTypes.array.isRequired,
  institutions: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  changeResearchTeam: PropTypes.func.isRequired,
  closeModifyResearchTeamModal: PropTypes.func.isRequired,
  closeConfirmationModal: PropTypes.func.isRequired,
  titles: PropTypes.array.isRequired,
  modalProps: PropTypes.object,
  isOpen: PropTypes.bool,
  studyName: PropTypes.string,
};

export default reduxForm({
  form: "modify-research-team",
})(ModifyResearchTeamForm);
