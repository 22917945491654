import _ from "lodash";
import {
  fieldIsUndefined,
  textValidationArray,
  validateForm,
  zipCodeValidation,
} from "../../../utils";

const addressMaxLength = 256;
const cityMaxLength = 128;
const zipMaxLength = 128;

const countryIsNotUSA = {
  error: (values, fieldPath) => {
    const sectionPath =
      fieldPath.match(/^(.*?)\.state/) || fieldPath.match(/^(.*?)\.zip/);
    return (
      _.get(values, `${sectionPath[1]}.country`) !== "United States of America"
    );
  },
  message: null,
};

export const addressErrors = {
  country: [fieldIsUndefined("Country")],
  address1: textValidationArray("Address", addressMaxLength),
  city: textValidationArray("City", cityMaxLength),
  state: [{ ...countryIsNotUSA }, fieldIsUndefined("State")],
  zip: [
    { ...countryIsNotUSA },
    ...textValidationArray("Zip code", zipMaxLength),
    zipCodeValidation(),
  ],
};

export const validateAddress = (values) => validateForm(values, addressErrors);
