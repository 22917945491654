import {
  REQUEST_SET_STATUS,
  REQUEST_RESET_STATE,
  REQUEST_SET_STEP_DATA,
  REQUEST_RESET_STEP_DATA,
  REQUEST_SET_NEXT_STEP,
  REQUEST_DISABLE_AUTH_REP_FIELDS,
  REQUEST_CREATED_NEW_INSTITUTION,
  REQUEST_CREATED_NEW_DIVISION,
} from "../../common/types";

const INITIAL_STATE = {
  studyName: null,
  requestType: null,
  biospecimenRepository: null,
  status: null, // status is either 'Request Initiated', 'In Progress', 'Rejected', or 'Pending Approval'
  hasUnresolvedConstraints: false, // only true for biospecimen requests for which the user has not complied with constraints, false otherwise
  steps: null, // array of all the forms the user has to go through; array order is the order of the forms to complete
  currStepIndex: null, // index of the current form the user is on
  nextStepToCompleteIndex: null, // index of the next uncompleted form, the user cannot advance to any forms with index greated than nextStepToCompleteIndex
  disableAuthRepFields: null, // used by study info form to enable/disable the auth rep fields
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_SET_STATUS: // sets the studyName, requestType, status, hasUnresolvedConstraints, steps, currStepIndex, and nextStepToCompleteIndex fields
    case REQUEST_SET_STEP_DATA: // sets the currStepIndex and the step data and form support
      return {
        ...state,
        ...action.payload,
      };
    case REQUEST_RESET_STATE:
      return { ...INITIAL_STATE };
    case REQUEST_RESET_STEP_DATA: {
      const { [action.payload]: step, ...rest } = state; // remove the step data from the state
      return { ...rest };
    }
    case REQUEST_SET_NEXT_STEP:
      return { ...state, nextStepToCompleteIndex: action.payload };
    case REQUEST_DISABLE_AUTH_REP_FIELDS:
      return {
        ...state,
        disableAuthRepFields: action.payload,
      };
    case REQUEST_CREATED_NEW_INSTITUTION: {
      const { formType, institution } = action.payload;
      return {
        ...state,
        [formType]: {
          ...state[formType],
          formSupport: {
            ...state[formType].formSupport,
            institutions: {
              ...state[formType].formSupport.institutions,
              Other: [
                ...state[formType].formSupport.institutions.Other,
                institution,
              ],
            },
          },
        },
      };
    }
    case REQUEST_CREATED_NEW_DIVISION: {
      const { formType, division } = action.payload;
      return {
        ...state,
        [formType]: {
          ...state[formType],
          formSupport: {
            ...state[formType].formSupport,
            divisions: [...state[formType].formSupport.divisions, division],
          },
        },
      };
    }
    default:
      return state;
  }
};
