import React from "react";
import PropTypes from "prop-types";
import { RequiredTag } from "../../other/RequiredFields";
import "./style.scss";

const DropdownSelect = (props) => {
  const {
    input,
    id,
    className,
    label,
    labelClasses,
    placeholder,
    disabled,
    required,
    options,
    optGroupLabels,
    ariaLabel,
    meta,
    ...rest
  } = props;

  // setup redux-form validation
  let errorClassName = "";
  let errorMessageClassName = "c-validation_error";
  let errorMessage = null;
  if (!disabled && meta.touched && (meta.error || meta.warning)) {
    errorClassName = "select-validation-error mb-0";
    errorMessageClassName = "c-validation_error mb-9";
    errorMessage = `${meta.error || meta.warning}`;
  }

  let selectOptions = null;
  if (optGroupLabels) {
    selectOptions = options.map((optionSection, index) => (
      <optgroup label={optGroupLabels[index]} key={optGroupLabels[index]}>
        {optionSection.map((option) => (
          <option key={`${option.value}`} value={option.value}>
            {option.item}
          </option>
        ))}
      </optgroup>
    ));
  } else {
    selectOptions = options.map((option) => (
      <option key={`${option.value}`} value={option.value}>
        {option.item}
      </option>
    ));
  }

  return (
    <div>
      {label && (
        <label htmlFor={id} className={`dash-select-label ${labelClasses}`}>
          {label}
          &nbsp;
          {required && <RequiredTag />}
        </label>
      )}
      <select
        {...input}
        {...rest}
        id={id}
        className={`dash-select ${className} ${errorClassName}`}
        disabled={disabled}
        aria-label={ariaLabel || placeholder}
      >
        {placeholder && (
          <option value='' disabled hidden>
            {placeholder}
          </option>
        )}
        {selectOptions}
      </select>
      <div className={errorMessageClassName}>{errorMessage}</div>
    </div>
  );
};

DropdownSelect.propTypes = {
  input: PropTypes.object,
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  ariaLabel: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  labelClasses: PropTypes.string,
  optGroupLabels: PropTypes.array,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
};

DropdownSelect.defaultProps = {
  className: "",
  labelClasses: "",
  placeholder: "",
  label: "",
  required: false,
  disabled: false,
  multiple: false,
};

export default DropdownSelect;
