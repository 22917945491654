import { submit } from "redux-form";
import { MODAL_OPEN, MODAL_CLOSE } from "../types";

// opens the given modal and passes it the modalProps
export const openModal = (modalType, modalProps) => ({
  type: MODAL_OPEN,
  payload: {
    modalType,
    modalProps: !modalProps ? {} : modalProps,
  },
});

// closes the modal
export const closeModal = () => ({ type: MODAL_CLOSE });

export const submitFormSync = (form) => (dispatch) => dispatch(submit(form));
