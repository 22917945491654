import React from "react";
import { Field } from "redux-form";
import _ from "lodash";
import PropTypes from "prop-types";
import { Checkbox } from "../../../../../common/inputs";

const RequestUsersSelect = (props) => {
  const { users } = props;
  const uniqueUsers = _.uniqBy(users, "id");
  const requestUsers = uniqueUsers.map((user) => (
    <div key={user.id} style={{ padding: "5px 0" }} className='col-md-6'>
      <p style={{ fontSize: "13px", fontWeight: "600" }}>{user.userType}:</p>
      <Field
        name={`recipients.${user.id}`}
        id={user.emailAddress}
        component={Checkbox}
        label={user.emailAddress}
      />
    </div>
  ));

  return (
    <div style={{ marginTop: "30px" }} className='form-group p-15'>
      <div className='label-class'>Termination Email Recipients: </div>
      <div className='row'>{requestUsers}</div>
    </div>
  );
};

RequestUsersSelect.propTypes = {
  users: PropTypes.array,
};

export default RequestUsersSelect;
