import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, formValueSelector } from "redux-form";
import { DropdownSelect } from "../../../inputs";
import CustomInstitutionDivision from "./CustomInstitutionDivision";

// This component allows the user to select their institution/division or create a new one
const SelectInstitutionDivision = (props) => {
  const {
    fieldName,
    fieldLabel,
    change,
    formName,
    countries,
    institutionTypes,
    states,
    options,
    createNew,
    dropdownOnChange,
    selectedInstitution,
    initialId,
    isNIH,
    disable,
    disableCreateNew,
  } = props;

  // toggle between displaying the institution/division dropdown or create new institution/division dialog
  const handleCreateNewOnClick = () => {
    if (createNew) {
      change(`${fieldName}.id`, initialId);
      change(`${fieldName}.name`, null);
      change(`${fieldName}.address`, null);
      change("division.useInstName", false);
      change("division.useInstAddress", false);
    } else {
      change(`${fieldName}.id`, null);
    }
    change(`${fieldName}.createNew`, !createNew);
    // if user toggles createNew institution, then toggle the createNew division
    if (fieldName.includes("institution")) {
      change("division.createNew", !createNew);
    }
  };

  return (
    <div className='row'>
      <div className='col-12 p-15'>
        {!createNew && (
          <Field
            component={DropdownSelect}
            id={`${fieldName}.id`}
            name={`${fieldName}.id`}
            label={formName !== "registration" ? fieldLabel : ""}
            options={options.sort((a, b) => a.item.localeCompare(b.item))} // sort the dropdown options in alphabetical order
            placeholder={`Please select your ${fieldLabel}`}
            onChange={dropdownOnChange}
            disabled={props.rasInstitution || disable}
            required
          />
        )}
        {isNIH !== "true" && !disable && !disableCreateNew && (
          <p className='mt-1rem'>
            {!createNew &&
              `Unable to find your ${fieldLabel} in the dropdown list? `}
            <button
              type='button'
              className='open-modal-btn'
              onClick={handleCreateNewOnClick}
              aria-label='Unable to find your Institution in the dropdown list? Click here to create your Institution. Clicking this button will open a new section.'
            >
              {!createNew
                ? `Click here to create your ${fieldLabel}.`
                : `Click here to select your ${fieldLabel} from the dropdown list.`}
            </button>
          </p>
        )}
      </div>
      {createNew && (
        <CustomInstitutionDivision
          fieldName={fieldName}
          fieldLabel={fieldLabel}
          formName={formName}
          change={change}
          countries={countries}
          states={states}
          selectedInstitution={selectedInstitution}
          institutionTypes={institutionTypes}
          disabled={false}
        />
      )}
    </div>
  );
};

SelectInstitutionDivision.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.string).isRequired,
  institutionTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
    }),
  ).isRequired,
  states: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  dropdownOnChange: PropTypes.func,
  createNew: PropTypes.bool,
  selectedInstitution: PropTypes.object,
  initialId: PropTypes.number, // the institution/division id that's initially selected
  isNIH: PropTypes.string,
  disable: PropTypes.bool,
  disableCreateNew: PropTypes.bool,
  isRAS: PropTypes.bool,
};

SelectInstitutionDivision.defaultProps = {
  dropdownOnChange: () => {}, // by default don't change any of the other fields when the dropdown value changes
};

const mapStateToProps = (state, { formName, fieldName }) => {
  const selector = formValueSelector(formName);
  return {
    createNew: selector(state, `${fieldName}.createNew`),
  };
};

export default connect(mapStateToProps)(SelectInstitutionDivision);
