import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

/**
 * HOC that ensures the user is not logged in. If the user is logged in then they get redirected
 * to the home page.
 */
const UnauthRoute = ({ component: C, authenticated, ...rest }) => {
  if (authenticated) {
    return <Redirect to='/' />;
  }
  return <Route {...rest} render={(routeProps) => <C {...routeProps} />} />;
};

UnauthRoute.propTypes = {
  component: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  authenticated: auth.authenticated,
});

export default connect(mapStateToProps)(UnauthRoute);
