import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { KEY_CODE } from "../../utils";
import "./style.scss";

const DropdownSearch = (props) => {
  const { id, input, items, className, ariaLabel, itemOnClick, ...rest } =
    props;

  const node = useRef();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleInputOnClick = () => {
    if (!dropdownOpen) {
      setDropdownOpen(true);
    }
  };

  const handleInputOnKeyDown = (e) => {
    if (!dropdownOpen) {
      setDropdownOpen(true);
    }
    if (items.length > 0) {
      switch (e.keyCode) {
        case KEY_CODE.TAB: {
          // if user enters SHIFT + TAB, then close the dropdown
          if (e.shiftKey) {
            setDropdownOpen(false);
          }
          break;
        }
        case KEY_CODE.ENTER:
          setDropdownOpen(false);
          break;
        case KEY_CODE.UP:
          // open the dropdown and move the focus to the last menu item
          e.preventDefault();
          setDropdownOpen(true);
          node.current.childNodes[1].childNodes[items.length - 1].focus();
          break;
        case KEY_CODE.DOWN:
          // open the dropdown and move the focus to the first menu item
          e.preventDefault();
          setDropdownOpen(true);
          node.current.childNodes[1].childNodes[0].focus();
          break;
        default:
      }
    }

    // if an onKeyDown prop is passed to the input then call the onKeyDown function
    if (input.onKeyDown) {
      input.onKeyDown(e);
    }
  };

  const handleItemOnClick = (e) => {
    itemOnClick(e.target.getAttribute("value"));
    setDropdownOpen(false);
  };

  const handleItemOnKeyDown = (e) => {
    switch (e.keyCode) {
      case KEY_CODE.TAB: {
        // if focus is on the last item, then close the dropdown menu
        const nodeIndex = parseInt(e.target.getAttribute("index"), 10);
        if (nodeIndex === items.length - 1) {
          setDropdownOpen(false);
        }
        break;
      }
      case KEY_CODE.ENTER:
        handleItemOnClick(e);
        break;
      case KEY_CODE.ESCAPE:
        // close the dropdown and move the focus to the input button
        setDropdownOpen(false);
        node.current.firstChild.focus();
        break;
      case KEY_CODE.HOME:
        // move the focus to the first menu item
        e.preventDefault();
        node.current.childNodes[1].firstChild.focus();
        break;
      case KEY_CODE.END:
        // move the focus to the last menu item
        e.preventDefault();
        node.current.childNodes[1].lastChild.focus();
        break;
      case KEY_CODE.UP: {
        // move the focus to the previous menu item; if focus is on the first menu item, then move focus to the last menu item
        e.preventDefault();
        const nodeIndex = parseInt(e.target.getAttribute("index"), 10);
        const nextIndex = nodeIndex === 0 ? items.length - 1 : nodeIndex - 1;
        node.current.childNodes[1].childNodes[nextIndex].focus();
        break;
      }
      case KEY_CODE.DOWN: {
        // move the focus to the next menu item; if focus is on the last menu item, then move the focus to the first menu item
        e.preventDefault();
        const nodeIndex = parseInt(e.target.getAttribute("index"), 10);
        const nextIndex = nodeIndex === items.length - 1 ? 0 : nodeIndex + 1;
        node.current.childNodes[1].childNodes[nextIndex].focus();
        break;
      }
      default:
    }
  };

  // close the dropdown if the user clicks outside the dropdown
  const handleClickOutside = (e) => {
    if (!node.current.contains(e.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  const dropdownMenuId = `${id}-dropdown-menu`;
  const show = dropdownOpen && items.length > 0 ? "show" : "";
  return (
    <div className={`dash-dropdown-search ${className}`} ref={node}>
      <input
        {...input}
        {...rest}
        id={id}
        type='text'
        onClick={handleInputOnClick}
        onKeyDown={handleInputOnKeyDown}
        aria-haspopup='true'
        aria-label={ariaLabel}
        aria-controls={dropdownMenuId}
      />
      <ul
        id={dropdownMenuId}
        className={`dropdown-menu ${show}`}
        role='menu'
        aria-hidden={!dropdownOpen && items.length === 0}
        aria-labelledby={id}
      >
        {items.map((item, index) => (
          <li
            key={item}
            role='menuitem'
            tabIndex='0'
            value={item}
            index={index}
            onClick={handleItemOnClick}
            onKeyDown={handleItemOnKeyDown}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

DropdownSearch.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
  }),
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemOnClick: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
};

DropdownSearch.defaultProps = {
  className: "",
};

export default DropdownSearch;
