import {
  GET_STUDY_OVERVIEW,
  CLEAR_STUDY_OVERVIEW,
  ADD_TO_CART_STUDY_OVERVIEW,
  STUDY_OVERVIEW_SET_SEARCH_RESULTS,
} from "../../common/types";

const INITIAL_STATE = {
  studyData: {},
  numItmesAdded: 0,
  searchData: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_STUDY_OVERVIEW:
      return {
        ...state,
        studyData: action.payload,
      };
    case STUDY_OVERVIEW_SET_SEARCH_RESULTS: {
      const { data, type } = action.payload;
      return {
        ...state,
        searchData: {
          ...state.searchData,
          [type]: {
            ...state.searchData[type],
            count: data.hits.total,
            aggregations: data.aggregations,
          },
        },
      };
    }
    case "STUDY_OVERVIEW_SET_UNIQUE_DATASET_COUNT": {
      const { data, type } = action.payload;
      return {
        ...state,
        searchData: {
          ...state.searchData,
          [type]: {
            ...state.searchData[type],
            uniqueCountAggregations: data.aggregations,
          },
        },
      };
    }
    case CLEAR_STUDY_OVERVIEW:
      return {
        ...INITIAL_STATE,
      };
    case ADD_TO_CART_STUDY_OVERVIEW:
      return {
        ...state,
        studyData: {
          ...state.studyData,
          canAddStudy: "false",
        },
      };
    default:
      return state;
  }
};
