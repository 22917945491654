import React, { Component } from "react";
import PropTypes from "prop-types";
import SkipToMainContent from "./components/SkipToMainContent";
import SocialMediaNavbar from "./components/SocialMediaNavbar";
import UpperNavigation from "./components/UpperNavigation";
import MainNavigation from "./components/MainNavigation";
import "./header.scss";

class HeaderContainer extends Component {
  componentDidMount() {
    if (this.props.auth.authenticated) {
      this.props.getItemsInCart();
    }
  }

  render() {
    return (
      <header aria-label='Site header'>
        <SkipToMainContent />
        <SocialMediaNavbar />
        <UpperNavigation
          isLoggedIn={this.props.auth.authenticated}
          isNIH={this.props.auth.isNIHSSO}
          itemsInCart={this.props.numCartItems}
          isMaintenance={this.props.isMaintenance}
        />
        {this.props.isMaintenance ? null : (
          <MainNavigation
            isLoggedIn={this.props.auth.authenticated}
            isAdmin={this.props.auth.admin}
            isCurator={this.props.auth.curator}
            pathName={this.props.pathName}
          />
        )}
      </header>
    );
  }
}

HeaderContainer.propTypes = {
  auth: PropTypes.object.isRequired,
  numCartItems: PropTypes.number,
  getItemsInCart: PropTypes.func.isRequired,
  pathName: PropTypes.string.isRequired,
};

export default HeaderContainer;
