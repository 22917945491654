import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Select from "react-select";
import { YellowButton } from "../../buttons";
import AffiliateUser from "../../../features/request/components/forms/researchTeam/components/AffiliateUser";

export const ManageAffiliates = (props) => {
  const {
    users,
    affiliateUsers,
    selectedUsers,
    setAffiliateUsers,
    setSelectedUsers,
  } = props;

  const changeSelectedUser = (usersToBeAdded) => {
    setSelectedUsers(usersToBeAdded);
  };

  const addAffiliate = () => {
    if (selectedUsers) {
      const newAffiliates = [];
      selectedUsers.forEach((selectedUser) => {
        const selectedUserId = parseInt(selectedUser.id, 10);
        if (
          !_.find(
            affiliateUsers,
            (affiliate) => affiliate.archiveUserId === selectedUserId,
          )
        ) {
          const affiliate = _.find(users, (user) => user.id === selectedUserId);
          const newAffiliate = {
            archiveUserId: affiliate.id,
            emailAddress: affiliate.emailAddress,
            name: affiliate.name,
          };
          newAffiliates.push(newAffiliate);
        }
      });
      if (newAffiliates.length > 0) {
        setAffiliateUsers([...affiliateUsers, ...newAffiliates]);
      }
    }
  };

  const removeAffiliate = (affiliate) => {
    setAffiliateUsers(
      affiliateUsers.filter(
        (user) => user.archiveUserId !== affiliate.archiveUserId,
      ),
    );
  };

  const renderSelectedAffiliates = () => {
    if (affiliateUsers.length > 0) {
      return (
        <div>
          <h3 className='form-heading-3'>Added Affiliate(s)</h3>
          <div className='list-group'>
            {affiliateUsers.map((affiliate) => (
              <AffiliateUser
                key={affiliate.archiveUserId}
                remove={() => removeAffiliate(affiliate)}
                user={affiliate}
              />
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderAffiliateForm = () => {
    const sortedUsers = users.sort((a, b) => a.name.localeCompare(b.name)); // sort users in alphabetical order
    const affiliateOptions = sortedUsers.map((user) => (
      <option value={user.id} key={_.uniqueId()}>
        {user.name}
      </option>
    ));
    affiliateOptions.unshift(
      <option key={_.uniqueId()}>Select from your institution...</option>,
    );
    const selector =
      sortedUsers.length > 0 ? (
        <>
          <p>
            To add your affiliates, please select a user from your institution
            from the dropdown list below. If you do not see your affiliates in
            the list, please ask them to register and activate an account in
            DASH. Once their accounts have been activated, you will be able to
            add users as an affiliate to your request. Please do not proceed to
            the next step until you have added all of your affiliates who will
            have access to the requested data. To remove an affiliate, click the
            &quot;x&quot; button to the right of their contact information.
          </p>

          <br />
          <div className='row form-group'>
            <div className='col-7 col-sm-6'>
              <Select
                options={sortedUsers}
                isMulti
                closeMenuOnSelect={false}
                aria-label={"Select Affiliate"}
                onChange={changeSelectedUser}
                aria-live={"assertive"}
              />
            </div>
            <div className='offset-md-3 col-2'>
              <YellowButton
                type='button'
                onClick={addAffiliate}
                label='Add Affiliate'
                align='right'
              />
            </div>
          </div>
        </>
      ) : (
        <p>
          There are no additional users from your institution registered in
          DASH. You may remove affiliates you already have, or if you need to
          add affiliates, please ask your affiliates to register and activate
          their accounts. When their accounts are active, you will be able to
          add them to your request.
        </p>
      );
    return (
      <div className='form-group'>
        {selector}
        {renderSelectedAffiliates()}
      </div>
    );
  };

  return renderAffiliateForm();
};

ManageAffiliates.propTypes = {
  affiliates: PropTypes.array,
  users: PropTypes.array,
};

export default ManageAffiliates;
