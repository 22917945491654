import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Checkbox from "../../inputs/Checkbox";
import { RequiredFieldsWarning } from "../../other/RequiredFields/index";

// renders a reusable modal with two buttons: one to cancel/close the modal and another
// to perform some action

const ModificationWarningModal = (props) => {
  const {
    isOpen,
    closeModal,
    modalSize,
    modalHeaderTag,
    modalHeader,
    modalBodyTag,
    BodyComponent,
    cancelButtonText,
    actionButtonOnClick,
    actionButtonText,
    disableActionButton,
    initialState,
    ...rest
  } = props;

  const [actionButtonIsDisabled, setActionButtonDisabled] =
    useState(disableActionButton);
  const [modalState, setModalState] = useState(initialState);
  const [checkboxValue, setCheckboxValue] = useState(false);

  let checkboxLabel;
  let note;
  if (modalHeader === "Submit Annual Report") {
    checkboxLabel = "I understand.";
    note = (
      <div>
        Note: If other members of the research team have changed (e.g.,
        Affiliates, Associates, and/or Collaborators), please make these changes
        through the DASH Workspace for the corresponding Data Request. If AOR
        has changed, please make this change through the DASH Workspace for the
        corresponding Data Request.
      </div>
    );
  } else {
    checkboxLabel = "I confirm the above statements are true";
    note = (
      <div>
        Note: If other members of the research team have changed (e.g.,
        Affiliates, Associates, and/or Collaborators), please make these changes
        through the DASH Workspace for the corresponding Data Request.
      </div>
    );
  }

  // Reset the ModificationWarningModal states whenever the modal opens or closes. This fixes a bug when you open the ModificationWarningModal,
  // modify one of the state variables, close the ModificationWarningModal, and then open it again. On the second open, the values
  // of the two state variables (actionButtonIsDisabled, modalState) will remain the previous values
  useEffect(() => {
    setActionButtonDisabled(disableActionButton);
    setModalState(initialState);
  }, [isOpen, disableActionButton, initialState]);

  const ariaId = modalHeader.split(" ").join("-");

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      size={modalSize}
      backdrop='static'
      labelledBy={`${ariaId}-modal`}
    >
      <ModalHeader
        toggle={closeModal}
        tag={modalHeaderTag}
        id={`${ariaId}-modal`}
      >
        {modalHeader}
      </ModalHeader>
      <ModalBody tag={modalBodyTag}>
        <BodyComponent
          closeModal={closeModal}
          actionButtonIsDisabled={actionButtonIsDisabled}
          setActionButtonDisabled={setActionButtonDisabled}
          modalState={modalState}
          setModalState={setModalState}
          {...rest}
        />
        <div>
          <RequiredFieldsWarning />
          <Checkbox
            id='constraints-checkbox'
            label={checkboxLabel}
            input={{
              value: checkboxValue,
              onChange: () => {
                setCheckboxValue(!checkboxValue);
              },
            }}
            className='constraints-modal-checkbox'
            required
          />
        </div>
        {note}
      </ModalBody>
      <ModalFooter>
        <button
          type='button'
          className='btn modal-btn btn-secondary'
          onClick={closeModal}
        >
          {cancelButtonText}
        </button>
        {actionButtonText && (
          <button
            type='button'
            className='btn modal-btn modal-btn-blue'
            onClick={() => {
              actionButtonOnClick(modalState);
              setCheckboxValue(false);
            }}
            aria-label={
              !checkboxValue
                ? "Complete required fields above to continue"
                : actionButtonText
            }
            disabled={!checkboxValue}
          >
            {actionButtonText}
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

ModificationWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalHeader: PropTypes.string.isRequired,
  BodyComponent: PropTypes.func.isRequired,
  modalSize: PropTypes.string, // sets the modal size, value is either 'sm', 'md', 'lg, 'or 'xl'
  modalHeaderTag: PropTypes.string, // sets the html element of the ModalHeader section, i.e. 'h2', 'div', 'p', etc.
  modalBodyTag: PropTypes.string, // sets the html element of the ModalBody section, i.e. 'h2', 'div', 'p', etc.
  cancelButtonText: PropTypes.string,
  actionButtonText: PropTypes.string,
  actionButtonOnClick: PropTypes.func, // must be provided if you provide the actionButtonText
  disableActionButton: PropTypes.bool,
  initialState: PropTypes.any,
};

ModificationWarningModal.defaultProps = {
  modalSize: "md",
  modalHeaderTag: "h2",
  modalBodyTag: "div",
  cancelButtonText: "Cancel",
  disableActionButton: false,
  initialState: {},
};

export default ModificationWarningModal;
