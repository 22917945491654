import jwtDecode from "jwt-decode";
import _ from "lodash";
import {
  AUTH_SUCCESS,
  RAS_REGISTER,
  SET_AUTH_FLAGS,
  SET_ACTIVITY,
  SET_IGNORE_ACTIVITY,
  RESET_TIMERS,
  SIGN_OUT,
  START_TIMER,
  REGISTRATION_SET_INACTIVE_USER_FORM,
} from "../../common/types";

const ROOT_URL = "/login/oauth/token?grant_type=password";

export const startTimer = (timerId) => (dispatch) =>
  dispatch({ type: START_TIMER, payload: timerId });

const setLocalStorage = (data) => {
  const jwt = jwtDecode(data.access_token);
  const startTime = Math.floor(Date.now());
  const email = jwt.emailAddress;
  const curator = _.includes(jwt.authorities, "Curator");
  const admin = _.includes(jwt.authorities, "Archive Administrator");
  const officer = jwt.authorities?.indexOf("Officer") > -1;
  localStorage.setItem("JWT", data.access_token);
  localStorage.setItem("refresh_token", data.refresh_token);
  localStorage.setItem("email", email);
  localStorage.setItem("start_time", startTime);
  localStorage.setItem("isCurator", curator);
  localStorage.setItem("isAdmin", admin);
  localStorage.setItem("isOfficer", officer);
  localStorage.setItem("is_NIH_SSO", data.is_NIH_SSO);
  localStorage.setItem("id", data.ID);
  localStorage.setItem("institution_id", data.institution.id);
  localStorage.setItem("institution_status", data.institution.status);
  localStorage.setItem("institution_name", data.institution.name);
  localStorage.setItem("termsOfService", data.termsOfService);
  localStorage.setItem("isDataChallenger", data.isDataChallenger);
  localStorage.setItem("institutionType", data.institutionType);
  return { email, startTime, curator, admin, officer };
};

const setLocalStorageJWT = (data) => {
  const jwt = jwtDecode(data.access_token);
  const startTime = Math.floor(Date.now());
  const email = jwt.emailAddress;
  const curator = _.includes(jwt.authorities, "Curator");
  const admin = _.includes(jwt.authorities, "Archive Administrator");
  const officer = jwt.authorities?.indexOf("Officer") > -1;
  localStorage.setItem("JWT", data.access_token);
  localStorage.setItem("refresh_token", data.refresh_token);
  return { email, startTime, curator, admin, officer };
};

const setRASLocalStorage = (data) => {
  const startTime = Math.floor(Date.now());
  const email = data.emailAddress;
  const curator = _.includes(data.authorities, "Curator");
  const admin = _.includes(data.authorities, "Archive Administrator");
  const officer = data.authorities?.indexOf("Officer") > -1;
  localStorage.setItem("email", email);
  localStorage.setItem("start_time", startTime);
  localStorage.setItem("isCurator", curator);
  localStorage.setItem("isAdmin", admin);
  localStorage.setItem("isOfficer", officer);
  localStorage.setItem("id", data.id);
  localStorage.setItem("is_NIH_SSO", data.is_NIH_SSO);
  localStorage.setItem("institution_id", data.institution.id);
  localStorage.setItem("institution_status", data.institution.status);
  localStorage.setItem("institution_name", data.institution.name);
  localStorage.setItem("termsOfService", data.termsOfService);
  localStorage.setItem("isDataChallenger", data.isDataChallenger);
  localStorage.setItem("institutionType", data.institutionType);
  return { email, startTime, curator, admin, officer };
};

export const getUserInfo = (jwt) => {
  const url = "/login/user-profile/getUserInfo";
  const init = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };
  return async (dispatch) => {
    try {
      const resp = await fetch(url, init);
      const data = await resp.json();
      if (resp.ok) {
        const { email, startTime, curator, admin, officer } =
          setRASLocalStorage(data);
        dispatch({
          type: AUTH_SUCCESS,
          email,
          startTime,
          curator,
          admin,
          officer,
          isNIH: data.is_NIH_SSO,
          id: data.ID,
        });
      } else {
        throw data.error_description;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const signinUser = (email, password) => {
  const init = {
    method: "POST",
    body: `username=${encodeURIComponent(email)}&password=${encodeURIComponent(
      password,
    )}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${btoa("dash-client:Dash123!")}`,
    },
  };
  return async (dispatch) => {
    try {
      const resp = await fetch(ROOT_URL, init);
      // Local dev error: catches disconnect from VPN
      if (resp.status === 500) {
        const error = await resp.text();
        throw error;
      }
      const data = await resp.json();
      if (resp.ok) {
        setLocalStorageJWT(data);
        dispatch({
          type: AUTH_SUCCESS,
          jwt: data.access_token,
          refreshToken: data.refresh_token,
        });
        return data;
      }
      throw data.error_description;
    } catch (error) {
      throw error;
    }
  };
};

export const acceptTerms = (relayState, termsOfService, inputData) => {
  let url;
  let body;
  let headers;

  if (inputData) {
    url = "/login/user-profile/acceptTerms";
    body = termsOfService;
    headers = {
      Authorization: `Bearer ${inputData.access_token}`,
      "Content-Type": "application/json",
    };
  } else {
    url = "/login/sso/acceptTerms";
    body = `RelayState=${relayState}&termsOfService=${termsOfService}`;
    headers = { "Content-Type": "application/x-www-form-urlencoded" };
  }

  const init = {
    method: "POST",
    body,
    headers,
  };

  return async (dispatch) => {
    try {
      const resp = await fetch(url, init);
      const data = inputData || (await resp.json());
      if (resp.ok) {
        const { email, startTime, curator, admin } = setLocalStorage(data);
        dispatch({
          type: AUTH_SUCCESS,
          jwt: data.access_token,
          refreshToken: data.refresh_token,
          email,
          startTime,
          curator,
          admin,
          isNIH: data.is_NIH_SSO,
          id: data.ID,
        });
      } else {
        throw inputData ? "error accepting terms" : data.error_description;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const getSSOToken = (relayState, history) => {
  const init = {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const url = `/login/sso/token?RelayState=${relayState}`;
  return async (dispatch) => {
    try {
      const resp = await fetch(url, init);
      const data = await resp.json();
      if (resp.ok) {
        if (data.termsOfService) {
          const { email, startTime, curator, admin } = setLocalStorage(data);
          dispatch({
            type: AUTH_SUCCESS,
            jwt: data.access_token,
            refreshToken: data.refresh_token,
            email,
            startTime,
            curator,
            admin,
            isNIH: data.is_NIH_SSO,
            id: data.ID,
          });
        } else {
          history.push(`/SSO/SSOPolicy?RelayState=${relayState}`);
        }
      } else {
        throw data.error_description;
      }
    } catch (error) {
      throw error;
    }
  };
};

const translateRasData = (data) => {
  const rasDataTranslation = {
    first_name: "firstName",
    last_name: "lastName",
    department: "institution",
  };
  const formattedData = {};
  Object.keys(data).forEach((item) => {
    if (rasDataTranslation[item] !== undefined) {
      const formattedItemKey = rasDataTranslation[item];
      formattedData[formattedItemKey] = data[item];
    }
  });
  // add either email or preferred_username and based on email add isNIH field
  formattedData.emailAddress =
    data.email != null ? data.email : data.preferred_username;
  formattedData.emailConfirm =
    data.email != null ? data.email : data.preferred_username;
  if (formattedData.emailAddress) {
    switch (formattedData.emailAddress.split("@")[1]) {
      case "nih.gov":
        formattedData.isNIH = "true";
        break;
      case "era.nih.gov":
        formattedData.isNIH = "true";
        break;
      default:
    }
  }
  return formattedData;
};

export const getRASStatus = (
  code,
  correlationId,
  clientId,
  redirectURI,
  scope,
) => {
  const body = {
    grantType: "authorization_code",
    scope,
    redirectURI,
    clientId,
    correlationId,
    code,
  };
  const init = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  };
  const url = "/login/user/ras";
  return async (dispatch) => {
    try {
      const resp = await fetch(url, init);
      const data = await resp.json();
      if (resp.ok) {
        setLocalStorageJWT(data);
        dispatch({
          type: AUTH_SUCCESS,
          jwt: data.access_token,
          refreshToken: data.refresh_token,
        });
        return data;
      }
      const errorData = { error: resp, data }; // if not in system, give me whatever data you have (email first last etc. )
      throw errorData;
    } catch (error) {
      const formattedData = translateRasData(error.data);
      dispatch({
        type: RAS_REGISTER,
        data: formattedData,
      });
      throw error;
    }
  };
};

export const logActivity = (active) => (dispatch) =>
  dispatch({ type: SET_ACTIVITY, payload: active });

export const setIgnoreActivity = (active) => (dispatch) =>
  dispatch({ type: SET_IGNORE_ACTIVITY, payload: active });

export const signout = () => {
  localStorage.clear();
  return (dispatch) => dispatch({ type: SIGN_OUT });
};

export const setAuthFlags = (flags) => {
  const payload = {
    ...flags,
  };
  return (dispatch) => {
    dispatch({ type: SET_AUTH_FLAGS, payload });
  };
};

export const resetTimers = (data) => {
  const urlLogin = "/login/oauth/token?grant_type=refresh_token";
  const requestData = { refresh_token: data.refresh_token };
  const st = Math.floor(Date.now());
  return async (dispatch) => {
    try {
      await fetch(urlLogin, {
        method: "POST",
        body: `refresh_token=${requestData.refresh_token}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Basic ${btoa("dash-client:Dash123!")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            dispatch(signout());
          }
          return response.json();
        })
        .then((resp) => {
          dispatch(logActivity(true));
          localStorage.setItem("start_time", st);
          localStorage.setItem("JWT", resp.access_token);
          localStorage.setItem("refresh_token", resp.refresh_token);
          dispatch({
            type: RESET_TIMERS,
            token: resp.access_token,
            refresh_token: resp.refresh_token,
            timer: data.timer,
            startTime: st,
          });
        });
    } catch (error) {
      throw error;
    }
  };
};

export const getInactiveUserInfo = (emailAddress) => {
  const init = {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const url = `/login/user/findInactiveUserByEmail?email=${emailAddress}`;
  return async (dispatch) => {
    try {
      const resp = await fetch(url, init);
      const data = await resp.json();
      if (resp.ok) {
        dispatch({
          type: REGISTRATION_SET_INACTIVE_USER_FORM,
          payload: { ...data },
        });
      } else {
        throw data.error_description;
      }
    } catch (error) {
      throw error;
    }
  };
};
