import React from "react";
import PropTypes from "prop-types";
import Table from "../../tables/Table";
import CollapseToggle from "../../other/CollapseToggle";
// import ClearButton from '../../buttons/ClearButton';
// import TrashIcon from '../../icons/TrashIcon';

// renders table of biospecimen samples with the same constraints. For the time
// being I commented out the remove functionality since we're crunched for time
// and Alexandra just wants us to get the bare minimum functionality implemented
// (sorry future developer, I wish I had time to do it)
const ConstraintsTable = (props) => {
  const {
    id,
    restriction,
    items,
    collapseOpen,
    toggleCollapse,
    // removeSamples,
    // removeItemsWithConstraints,
    // studyId,
  } = props;

  const thead = [
    "#",
    "Specimen Type",
    "Biospecimen Unique Identifier",
    "Subject ID",
    "Visit Name",
  ];
  const tbody = items.map((item, index) => [
    index + 1,
    item.specimenType,
    item.sampleId,
    item.subjectId,
    item.visitName,
  ]);

  // temporarily removing the Remove Samples button since we don't have time
  // to implement all the functionaltiy by November deadline
  // const removeSamplesBtn = (
  //   <ClearButton
  //     label="Remove Samples"
  //     textColor="#b52b4a"
  //     icon={
  //       <TrashIcon
  //         color="#b52b4a"
  //       />
  //     }
  //     onClick={(e) => {
  //       e.preventDefault();
  //       removeItemsWithConstraints(studyId, id)
  //         .then(() => removeSamples(id, items.length));
  //     }}
  //     align="right"
  //     hoverEffect
  //   />
  // );

  return (
    <>
      <h3>Specimen use restrictions based on consent:</h3>
      <p>{restriction}</p>
      {/* <div className="row justify-content-between">
        <div className="col-xs-12 col-sm-8">
          <h2>{restriction}</h2>
        </div>
        <div className="col-xs-12 col-sm-4">
          {removeSamplesBtn}
        </div>
      </div> */}
      <CollapseToggle
        labelClose={`Show Samples (${items.length})`}
        labelOpen={`Show Samples (${items.length})`}
        className='collapse'
        textColor='#045395'
        hasIcon
        isOpen={collapseOpen}
        onClick={() => toggleCollapse(id)}
      >
        <br />
        <Table
          thead={thead}
          tbody={tbody}
          tableResponsive
          className='table-bordered constraints-table'
        />
      </CollapseToggle>
      <hr />
    </>
  );
};

ConstraintsTable.propTypes = {
  id: PropTypes.number.isRequired,
  restriction: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  collapseOpen: PropTypes.bool.isRequired,
  toggleCollapse: PropTypes.func.isRequired,
  // removeSamples: PropTypes.func.isRequired,
  // removeItemsWithConstraints: PropTypes.func.isRequired,
  // studyId: PropTypes.number.isRequired,
};

export default ConstraintsTable;
