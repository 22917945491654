import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import { DatePicker } from "../../../../common/inputs";
import { getTimezoneOffsetDate } from "../../../../common/utils";

export const DateSelectForm = (props) => {
  const { selectedDate, name, label, ariaLabel, isRequired } = props;

  const formatDate = (date) => {
    // eslint-disable-next-line no-restricted-globals
    const isValidDate = !isNaN(Date.parse(date)); // extra check to make sure the field value is a valid date
    return isValidDate ? getTimezoneOffsetDate(date) : null;
  };

  return (
    <Field
      label={label}
      id={name}
      name={name}
      ariaLabel={ariaLabel}
      component={DatePicker}
      parse={formatDate}
      required={isRequired}
      // below props are used by ReactDatePicker
      selected={selectedDate}
      maxDate={new Date()}
      selectsStart
      placeholderText={ariaLabel}
      dateFormat='MMMM d, yyyy' // the MM has to be capitalized for the ReactDatePicker component
      className='form-control'
      popperClassName='mt_-20'
      errorPopperClassName='mt-25'
    />
  );
};

DateSelectForm.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  isRequired: PropTypes.bool,
};

const mapStateToProps = (state, { name }) => {
  const selector = formValueSelector("approveRequestForm");
  return {
    selectedDate: selector(state, name),
  };
};

export default connect(mapStateToProps)(DateSelectForm);
