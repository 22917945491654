import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { formValueSelector, SubmissionError } from "redux-form";
import InstitutionForm from "../../form/forms/institution/InstitutionForm";
import DivisionForm from "../../form/forms/division/DivisionForm";
import { getInstitutionInfo } from "../../../features/submission/SubmissionActions";
import { parseInstitutionBackendErrors } from "../../form/forms/institution/InstitutionValidation";
import { LOCATION_TYPES } from "../../types";
import "../../form/form.scss";

// This modal is used for creating a new institution or a new division based on the locationType
const CreateLocationModal = (props) => {
  const {
    isOpen,
    closeModal,
    submitReduxForm,
    createLocation,
    locationType,
    formType,
    institutionId,
    selectedCountry,
    institutionTypes,
    modalSubmit,
    fieldName,
    id,
  } = props;

  // submit the form and create the new location
  const handleSubmit = (values) => {
    if (institutionId) {
      values = { ...values, institutionId };
    }
    return createLocation(values, locationType, formType)
      .then((payload) => {
        // if we match to certain forms, we autopopulate the created institution in the form
        // customized changes are made for the individual forms ie: arraylist etc
        // backend returns insitution vs newDivision when you create a new one, so we need logic to grab the correct object
        if (formType === "research-team") {
          props.change(
            `${fieldName}[0]`,
            payload[
              payload.institution ? "institution" : "newDivision"
            ].name.toString(),
          );
        } else if (
          formType === "investigator-information" ||
          formType === "study-details" ||
          formType === "study-information"
        ) {
          props.change(
            `${fieldName}${id ? `[${id}]` : ""}`,
            payload[
              payload.institution ? "institution" : "newDivision"
            ].id.toString(),
          );
        }
        closeModal();
      })
      .catch((error) => {
        const submitError = parseInstitutionBackendErrors(error);
        throw new SubmissionError(submitError);
      });
  };

  let header = "Create New Institution";
  let Form = InstitutionForm;
  let modalForm = "institutionForm";
  if (locationType === LOCATION_TYPES.DIVISION) {
    header = "Create New Institution Division";
    Form = DivisionForm;
    modalForm = "divisionForm";
  }

  return (
    <Modal isOpen={isOpen} toggle={closeModal} backdrop='static' size='lg'>
      <ModalHeader tag='h2' toggle={closeModal}>
        {header}
      </ModalHeader>
      <ModalBody>
        <p style={{ fontSize: "13px" }}>
          All fields marked with an asterisk ( 
          <span className='requiredField'>*</span> ) are required.
        </p>
        <Form
          country={selectedCountry}
          onSubmit={modalSubmit || handleSubmit}
          institutionTypes={institutionTypes}
          institutionId={institutionId}
          getInstitutionInfo={props.getInstitutionInfo}
        />
      </ModalBody>
      <ModalFooter>
        <button
          type='button'
          label='Cancel'
          className='btn modal-btn btn-secondary'
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type='submit'
          className='btn modal-btn modal-btn-blue'
          onClick={() => submitReduxForm(modalForm)}
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};

CreateLocationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submitReduxForm: PropTypes.func,
  createLocation: PropTypes.func,
  locationType: PropTypes.string.isRequired,
  formType: PropTypes.string,
  selectedCountry: PropTypes.string,
  institutionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  institutionTypes: PropTypes.array,
  getInstitutionInfo: PropTypes.func,
  modalSubmit: PropTypes.func,
  change: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  id: PropTypes.number,
};

const mapStateToProps = (state, props) => {
  const formType =
    props.locationType === LOCATION_TYPES.INSTITUTION
      ? "institutionForm"
      : "divisionForm";
  const selector = formValueSelector(formType);
  return {
    selectedCountry: selector(state, "address.country"),
  };
};

const actions = {
  getInstitutionInfo,
};

export default connect(mapStateToProps, actions)(CreateLocationModal);
