import React from "react";

const SocialMediaNavbar = () => (
  <div
    className='social-media-nav-container'
    role='navigation'
    aria-label='Links to external NIH websites'
  >
    <div className='container-fluid padding-sides'>
      <nav aria-label='dash-social-media-navbar' className='social-media-nav'>
        <a
          href='https://www.hhs.gov/'
          target='_blank'
          rel='noopener noreferrer'
        >
          US Department of Health and Human Services
        </a>
        <span className='pipe' />
        <a
          href='https://www.nih.gov/'
          target='_blank'
          rel='noopener noreferrer'
        >
          National Institutes of Health
        </a>
        <span className='pipe social-divider' />
        <a
          href='https://ned.nih.gov/search/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Directory
        </a>
        <span className='pipe' />
        <span style={{ marginRight: "4px" }}>Follow</span>
        <a
          href='https://www.facebook.com/nichdgov/'
          className='social-media-icon fb'
          target='_blank'
          rel='noopener noreferrer'
        >
          <span className='sr-only'>Follow us on Facebook</span>
        </a>
        <a
          href='https://twitter.com/nichd_nih'
          className='social-media-icon twtr'
          target='_blank'
          rel='noopener noreferrer'
        >
          <span className='sr-only'>Follow us on Twitter</span>
        </a>
        <a
          href='https://www.linkedin.com/company/eunice-kennedy-shriver-national-institute-of-child-health-and-human-development-nichd/'
          className='social-media-icon linkedin'
          target='_blank'
          rel='noopener noreferrer'
        >
          <span className='sr-only'>Follow us on LinkedIn</span>
        </a>
        <a
          href='https://www.pinterest.com/NICHD_NIH/'
          className='social-media-icon pntrst'
          target='_blank'
          rel='noopener noreferrer'
        >
          <span className='sr-only'>Follow us on Pinterest</span>
        </a>
        <a
          href='https://www.youtube.com/user/nichdvideos?feature=results_main'
          className='social-media-icon yt'
          target='_blank'
          rel='noopener noreferrer'
        >
          <span className='sr-only'>Follow us on YouTube</span>
        </a>
        <a
          href='https://www.flickr.com/people/nichd'
          className='social-media-icon flckr'
          target='_blank'
          rel='noopener noreferrer'
        >
          <span className='sr-only'>Follow us on Flickr</span>
        </a>
        <a
          href='https://www.instagram.com/nichd_nih/'
          className='social-media-icon instagram'
          target='_blank'
          rel='noopener noreferrer'
        >
          <span className='sr-only'>Follow us on Instagram</span>
        </a>
      </nav>
    </div>
  </div>
);

export default SocialMediaNavbar;
