import { connect } from "react-redux";
import HeaderContainer from "./HeaderContainer";
import * as actions from "./HeaderActions";

const mapStateToProps = ({ auth, router, header }) => ({
  auth,
  pathName: router.location.pathname,
  ...header,
});

export default connect(mapStateToProps, actions)(HeaderContainer);
