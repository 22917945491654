import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "./AsyncComponent";
import AuthRoute from "./AuthRoute";
import UnauthRoute from "./UnauthRoute";
import DAPRoute from "./DAPRoute";

const AsyncHome = asyncComponent(() => import("../../features/home"));
const AsyncLogin = asyncComponent(() => import("../../features/login"));
const AsyncRas = asyncComponent(() =>
  import("../../features/login/components/RasPage"),
);
const AsyncLogout = asyncComponent(() =>
  import("../../features/login/components/LogoutPage"),
);
const AsyncReregister = asyncComponent(() =>
  import("../../features/login/components/RedirectToRegister"),
);
const AsyncFeedback = asyncComponent(() => import("../../features/feedback"));
const AsyncRegister = asyncComponent(() =>
  import("../../features/register/RegistrationContainer"),
);
const AsyncRegistrationConfirm = asyncComponent(() =>
  import("../../features/register/components/RegistrationConfirm"),
);
const AsyncResources = asyncComponent(() => import("../../features/resources"));
const AsyncStudyOverview = asyncComponent(() =>
  import("../../features/studyOverview"),
);
const AsyncCart = asyncComponent(() => import("../../features/cart"));
const AsyncCurator = asyncComponent(() => import("../../features/curator"));
const AsyncRequest = asyncComponent(() => import("../../features/request"));
const AsyncRenewal = asyncComponent(() => import("../../features/renewal"));
const AsyncModifyAOR = asyncComponent(() => import("../../features/modifyAOR"));
const AsyncSubmission = asyncComponent(() =>
  import("../../features/submission"),
);
const AsyncProfile = asyncComponent(() => import("../../features/profile"));
const AsyncWorkspace = asyncComponent(() => import("../../features/workspace"));
const AsyncAdmin = asyncComponent(() => import("../../features/admin"));
const AsyncOfficer = asyncComponent(() => import("../../features/officer"));
const AsyncNotFound = asyncComponent(() =>
  import("../../common/error/components/404"),
);
const AsyncMaintenance = asyncComponent(() =>
  import("../../common/error/components/Maintenance"),
);
const AsyncSearch = asyncComponent(() => import("../../features/search"));
const AsyncForgotPassword = asyncComponent(() =>
  import("../../features/forgotPassword/ForgotPassword"),
);
const AsyncResetPassword = asyncComponent(() =>
  import("../../features/forgotPassword/ResetPassword"),
);
const AsyncOptOut = asyncComponent(() =>
  import("../../features/optOut/OptOutContainer"),
);
const AsyncDatasetOverview = asyncComponent(() =>
  import("../../features/datasetOverview"),
);
const AsyncOutcomes = asyncComponent(() => import("../../features/outcomes"));
const AsyncAnnualReport = asyncComponent(() =>
  import("../../features/annualReport"),
);
const AsyncAcceptTOS = asyncComponent(() =>
  import("../../features/login/components/AcceptTOSContainer"),
);

export default ({ isMaintenance }) =>
  isMaintenance ? (
    <Switch>
      <Route component={AsyncMaintenance} />
    </Switch>
  ) : (
    <Switch>
      <DAPRoute path='/' exact component={AsyncHome} privileged={false} />
      <DAPRoute path='/signin' exact component={AsyncLogin} privileged />
      <DAPRoute path='/ras' component={AsyncRas} privileged />

      <DAPRoute path='/logout' exact component={AsyncLogout} privileged />
      <DAPRoute
        path='/reregister'
        exact
        component={AsyncReregister}
        privileged
      />
      <DAPRoute
        path='/resource/:name'
        component={AsyncResources}
        privileged={false}
      />
      <DAPRoute
        path='/study/:id'
        component={AsyncStudyOverview}
        privileged={false}
      />
      <DAPRoute
        path='/dataset/:id'
        component={AsyncDatasetOverview}
        privileged={false}
      />
      <DAPRoute
        path='/explore/:name'
        component={AsyncSearch}
        privileged={false}
      />

      {/* These routes require the user to be logged in */}
      <AuthRoute path='/cart' component={AsyncCart} />
      <AuthRoute path='/profile' component={AsyncProfile} />
      <AuthRoute path='/workspace' component={AsyncWorkspace} />
      <AuthRoute path='/feedback' exact component={AsyncFeedback} />
      <AuthRoute path='/curate/:name' component={AsyncCurator} />
      <AuthRoute path='/request/:id' component={AsyncRequest} />
      <AuthRoute path='/renewal/:id' component={AsyncRenewal} />
      <AuthRoute path='/modifyAOR/:id' component={AsyncModifyAOR} />
      <AuthRoute path='/submission/:name' component={AsyncSubmission} />
      <AuthRoute path='/admin/:name' component={AsyncAdmin} />
      <AuthRoute path='/officer/:name' component={AsyncOfficer} />
      <AuthRoute path='/outcomes/:name' component={AsyncOutcomes} />
      <AuthRoute path='/annualreport/:id' component={AsyncAnnualReport} />
      <AuthRoute path='/AcceptTOS' component={AsyncAcceptTOS} />

      {/* These routes require the user to NOT be logged in */}
      <UnauthRoute path='/register' component={AsyncRegister} />
      <UnauthRoute
        path='/confirmAccount/:hash'
        exact
        component={AsyncRegistrationConfirm}
      />
      <UnauthRoute
        path='/resetPassword/:token'
        component={AsyncResetPassword}
      />
      <UnauthRoute path='/forgotPassword' component={AsyncForgotPassword} />
      <UnauthRoute path='/unsubscribe/:token' component={AsyncOptOut} />
      <UnauthRoute path='/AcceptTOS' component={AsyncAcceptTOS} />

      {/* 404 page. nothing should be added below this route */}
      <Route component={AsyncNotFound} />
    </Switch>
  );
