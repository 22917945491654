import {
  CURATOR_SET_SUBMISSIONS,
  CURATOR_SET_SINGLE_SUBMISSION,
  CURATOR_SET_SUBMISSION_STATUS,
  CURATOR_RESET_STATE,
} from "../../common/types";

export const initialState = {
  submissions: null, // array of all the submiss
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CURATOR_SET_SUBMISSIONS:
      return {
        ...state,
        submissions: action.payload,
      };
    case CURATOR_SET_SINGLE_SUBMISSION:
      return {
        ...state,
        singleSubmission: action.payload,
      };
    case CURATOR_SET_SUBMISSION_STATUS: {
      const { status, index } = action.payload;
      const submissions = [...state.submissions];
      submissions[index].workflow.status = status;
      return {
        ...state,
        submissions,
      };
    }
    case CURATOR_RESET_STATE:
      return { ...initialState };
    default:
      return { ...state };
  }
};
